import { Tab, Tabs } from '@nextui-org/react'
import { useDispatch } from 'react-redux'

import { type InvoiceState } from '@/shared'

import { invoiceModel, useInvoiceState } from '../model'

export const InvoicesTabs = () => {
    const dispatch = useDispatch()
    const state = useInvoiceState()

    const variants: Array<{ value: InvoiceState; label: string }> = [
        { value: 'ALL', label: 'All invoices' },
        { value: 'PENDING', label: 'Pending' },
        { value: 'PAID', label: 'Paid' },
        { value: 'RECURRING', label: 'Recurring' },
    ]

    const handleUpdateState = (value: InvoiceState) => {
        dispatch(invoiceModel.actions.setInvoiceState(value))
    }

    return (
        <Tabs
            variant="light"
            aria-label="Active"
            radius="sm"
            selectedKey={state}
            onSelectionChange={(value) => handleUpdateState(value as InvoiceState)}
        >
            {variants.map((variant) => (
                <Tab key={variant.value} title={variant.label} className="text-xs md:text-sm" />
            ))}
        </Tabs>
    )
}

import { type Control } from 'react-hook-form'

import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/shared'

import { type TransactionFormValues } from './transaction-form'

interface Props {
    control: Control<TransactionFormValues>
}

export const TransactionFormRecurring = ({ control }: Props) => {
    return (
        <FormField
            control={control}
            name="recurring"
            render={({ field }) => (
                <FormItem className="w-full">
                    <FormLabel>Recurring</FormLabel>
                    <FormControl>
                        <Select value={field.value || ''} onValueChange={field.onChange}>
                            <SelectTrigger>
                                <SelectValue placeholder={field.value} />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem value="disabled">Disabled</SelectItem>
                                    <SelectItem value="month">Monthly</SelectItem>
                                    <SelectItem value="year">Yearly</SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </FormControl>
                </FormItem>
            )}
        />
    )
}

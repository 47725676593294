import {
    BeakerIcon,
    BriefcaseIcon,
    BuildingLibraryIcon,
    CircleStackIcon,
    ComputerDesktopIcon,
    HeartIcon,
    HomeModernIcon,
    LanguageIcon,
    ScaleIcon,
    ShoppingBagIcon,
    TagIcon,
    TruckIcon,
} from '@heroicons/react/24/outline'
import { createElement } from 'react'

export const getCategoryIcon = (category?: string) => {
    const className = 'h-4 w-4'

    switch (category) {
        case 'work_software_services_tools_subscriptions':
            return createElement(ComputerDesktopIcon, { className })
        case 'office_supplies_and_equipment':
            return createElement(BriefcaseIcon, { className })
        case 'marketing_and_advertising':
            return createElement(BeakerIcon, { className })
        case 'education_and_training':
            return createElement(ScaleIcon, { className })
        case 'travel_and_accommodation':
            return createElement(LanguageIcon, { className })
        case 'meals_and_entertainment':
            return createElement(ShoppingBagIcon, { className })
        case 'health_and_life_insurance':
            return createElement(HeartIcon, { className })
        case 'business_insurance':
            return createElement(BuildingLibraryIcon, { className })
        case 'car_and_truck_expenses':
            return createElement(TruckIcon, { className })
        case 'rent_and_lease_property':
            return createElement(HomeModernIcon, { className })
        case 'utilities':
            return createElement(TagIcon, { className })
        default:
            return createElement(CircleStackIcon, { className })
    }
}

import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import { type RootState } from '@/app/middleware/reducers'
import { type Customer } from '@/shared'

interface State {
    count: number
    customers: Customer[]
}

const initialState: State = {
    count: 0,
    customers: [],
}

export const customerModel = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload
        },
        setCustomers: (state, action: PayloadAction<Customer[]>) => {
            state.customers = action.payload
        },
        addCustomer: (state, action: PayloadAction<Customer>) => {
            state.customers.unshift(action.payload)
            state.count += 1
        },
        updateCustomer: (state, action: PayloadAction<Partial<Customer>>) => {
            const index = state.customers.findIndex((customer) => customer._id === action.payload._id)

            if (index !== -1) {
                state.customers[index] = { ...state.customers[index], ...action.payload }
            }
        },
    },
})

export const useCustomersData = () => useSelector((state: RootState) => state.customer.customers)
export const useCustomersCount = () => useSelector((state: RootState) => state.customer.count)

export const customerReducer = customerModel.reducer

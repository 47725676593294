import { captureException } from '@sentry/react'
import axios, { type Method } from 'axios'
import _ from 'lodash'

import { APP_CONFIG, captureEvent } from '@/shared'

export interface TypedResponse<T> {
    statusCode?: number
    message?: string
    data?: T
    error?: string
}

const apiUrl = APP_CONFIG.API_URL

export const request = async <T>(
    method: Method,
    url: string,
    data?: any,
    headers: Record<string, string> = {},
): Promise<TypedResponse<T>> => {
    try {
        const response = await axios({
            url: apiUrl + url,
            method,
            data,
            headers: {
                ...headers,
            },
            timeout: 0,
            maxContentLength: 0,
            withCredentials: true,
        })

        captureEvent('api_request', {
            method,
            url,
            data,
            headers,
            response: response.data,
        })

        return response.data as TypedResponse<T>
    } catch (err) {
        captureException(_.get(err, 'response.data.error') as string | undefined)

        if (axios.isCancel(err)) {
            return { error: 'cancelled' }
        }
        return {
            error: _.get(err, 'response.data.error') as string | undefined,
            statusCode: _.get(err, 'response.statusCode', 500) as number | undefined,
        }
    }
}

import { useQuery } from 'react-query'

import { captureEvent, getDashboard } from '@/shared'

export const useDashboard = (year: number) =>
    useQuery({
        queryKey: ['dashboard', year],
        queryFn: () => getDashboard(year),
        onSuccess: () => {
            captureEvent('dashboard_data_fetch', { year })
        },
    })

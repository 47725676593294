import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { type ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { cn } from '@/shared'

interface Props {
    children: ReactNode
    href: string
    className?: string
}

export const BackBtn = ({ children, href, className }: Props) => {
    const navigate = useNavigate()

    return (
        <div
            className={cn('text-default-400 space-x-2 flex items-center cursor-pointer hover:opacity-60 w-fit', className)}
            onClick={() => navigate(href)}
        >
            <ArrowLeftIcon className="w-3 h-3" />
            <div className="text-sm">{children}</div>
        </div>
    )
}

import { BanknotesIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import _ from 'lodash'

import { getInvoiceCalculations, useUserData } from '@/features'
import { type Customer, type Invoice, Label, Separator, Typography } from '@/shared'

interface Props {
    invoice: Invoice | undefined
}

export const InvoicePdf = ({ invoice }: Props) => {
    const user = useUserData()

    const customers = (invoice?.customers as Customer[]) || []
    const customer = customers[0] || undefined

    const customerLocation = [customer?.country, customer?.city]

    const { subtotal, total, taxes, discounts } = getInvoiceCalculations(invoice)

    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: invoice?.currency || 'USD' })

    return (
        <div className="bg-gray-100 rounded-xl shadow-sm md:p-5">
            <div className="bg-white rounded-xl pb-24">
                <div className="bg-gray-50 border-gray-100 p-6 space-y-6">
                    {invoice?.settings?.applyBusinessLogo ? (
                        <img src={user?.business?.image} className="w-16 h-16 rounded-lg" alt="business logo" />
                    ) : (
                        <div className="w-16 h-16 bg-gray-200 flex justify-center items-center rounded-xl">
                            <BanknotesIcon className="w-7 h-7 text-heading" />
                        </div>
                    )}

                    <Typography variant="h3">Invoice #{invoice?.prefix}</Typography>

                    <div className="flex gap-4">
                        <div className="space-y-1 w-64">
                            <Typography variant="p" className="font-medium !text-sm">
                                Issued date
                            </Typography>
                            <Typography variant="subtitle" className="font-light !text-sm">
                                {dayjs(invoice?.issuedDate).format('DD MMM, YYYY')}
                            </Typography>
                        </div>
                        {invoice?.dueDate && (
                            <div className="space-y-1 w-64">
                                <Typography variant="p" className="font-medium !text-sm">
                                    Due date
                                </Typography>
                                <Typography variant="subtitle" className="font-light !text-sm">
                                    {dayjs(invoice?.dueDate).format('DD MMM, YYYY')}
                                </Typography>
                            </div>
                        )}
                    </div>

                    <div className="flex flex-col md:flex-row gap-4">
                        <div className="space-y-1 w-64">
                            <Typography variant="p" className="font-medium !text-sm">
                                Bill to
                            </Typography>
                            {customer?.name && (
                                <Typography variant="subtitle" className="font-light !text-sm">
                                    {_.truncate(customer.name, { length: 25 })}
                                </Typography>
                            )}

                            {customer?.email && (
                                <Typography variant="subtitle" className="font-light !text-sm">
                                    {_.truncate(customer.email, { length: 25 })}
                                </Typography>
                            )}

                            <Typography variant="subtitle" className="font-light !text-sm">
                                {customerLocation.filter((item) => item).join(', ')}
                            </Typography>

                            {customer?.address && (
                                <Typography variant="subtitle" className="font-light !text-sm">
                                    {_.truncate(customer.address, { length: 25 })}
                                </Typography>
                            )}
                        </div>

                        <div className="space-y-1 w-64">
                            <Typography variant="p" className="font-medium !text-sm">
                                Bill from
                            </Typography>
                            <Typography variant="subtitle" className="font-light !text-sm">
                                {_.truncate(user?.fullName || '', { length: 25 })}
                            </Typography>
                            <Typography variant="subtitle" className="font-light !text-sm">
                                {_.truncate(user?.email || '', { length: 25 })}
                            </Typography>
                        </div>
                    </div>
                </div>

                <div className="space-y-6 p-6">
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="text-left text-sm font-medium w-[160px]">Description</th>
                                <th className="text-left text-sm font-medium w-24">Quantity</th>
                                <th className="text-sm font-medium w-24 text-right">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoice?.items?.map((item, index) => (
                                <tr key={index}>
                                    <td className="text-sm font-light">{_.truncate(item.description || '-', { length: 25 })}</td>
                                    <td className="text-sm font-light">{item.quantity || 0}</td>
                                    <td className="text-sm font-light text-right">{formatter.format(item.amount)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="w-full flex flex-row-reverse mt-6 space-y-6">
                        <div className="flex flex-col space-y-4 w-full max-w-sm">
                            <Separator />

                            <div className="flex items-center gap-12 justify-between">
                                <Typography variant="p" className="font-light !text-sm">
                                    Subtotal
                                </Typography>
                                <Typography variant="p" className="font-light !text-sm">
                                    {formatter.format(subtotal)}
                                </Typography>
                            </div>

                            <div className="flex items-center gap-12 justify-between">
                                <Typography variant="p" className="font-light !text-sm">
                                    {_.truncate(invoice?.tax?.name || 'Tax', { length: 10 })}{' '}
                                    <span className="text-gray-400">
                                        ({invoice?.tax?.mode === 'EXCLUSIVE' ? invoice?.tax?.percentage : 0}%)
                                    </span>
                                </Typography>

                                <Typography variant="p" className="font-light !text-sm text-gray-400">
                                    {formatter.format(invoice?.tax?.mode === 'EXCLUSIVE' ? taxes : 0)}
                                </Typography>
                            </div>

                            {!!invoice?.discount?.percentage && (
                                <div className="flex items-center gap-12 justify-between">
                                    <Typography variant="p" className="font-light !text-sm">
                                        {_.truncate(invoice?.discount?.name || 'Discount', { length: 10 })}{' '}
                                        <span className="text-gray-400">({invoice?.discount?.percentage}%)</span>
                                    </Typography>

                                    <Typography variant="p" className="font-light !text-sm text-gray-400">
                                        {formatter.format(discounts)}
                                    </Typography>
                                </div>
                            )}

                            <Separator />

                            <div className="flex items-center justify-between gap-12">
                                <Typography variant="p" className="font-semibold">
                                    Total due
                                </Typography>

                                <Typography variant="p" className="font-semibold">
                                    {formatter.format(total)}
                                </Typography>
                            </div>
                        </div>
                    </div>

                    {invoice?.payment?.paymentDetails && (
                        <div className="space-y-1">
                            <Label>Payment details</Label>
                            <Typography variant="subtitle" className="whitespace-pre-line">
                                {invoice.payment.paymentDetails}
                            </Typography>
                        </div>
                    )}

                    {invoice?.notes && (
                        <div className="space-y-1">
                            <Label>Notes</Label>
                            <Typography variant="subtitle" className="whitespace-pre-line">
                                {invoice.notes}
                            </Typography>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

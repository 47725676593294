import { ArrowsUpDownIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { Tooltip, User } from '@nextui-org/react'
import { captureException } from '@sentry/react'
import { type ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, type SortingState, useReactTable } from '@tanstack/react-table'
import dayjs from 'dayjs'
import _ from 'lodash'
import { type MouseEvent, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
    CsvManipulate,
    getInvoiceBadgeClass,
    getInvoiceCalculations,
    InvoiceDeleteModal,
    invoiceModel,
    InvoiceSheet,
    InvoicesTabs,
    useInvoiceState,
} from '@/features'
import {
    Badge,
    Button,
    captureEvent,
    cn,
    type Customer,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    type Invoice,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
    updateInvoiceState,
    useToast,
} from '@/shared'

interface Props {
    invoices: Invoice[]
}

export const InvoicesTable = ({ invoices }: Props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { toast } = useToast()
    const state = useInvoiceState()

    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null)
    const [isInvoiceSheetOpen, setIsInvoiceSheetOpen] = useState(false)
    const [sorting, setSorting] = useState<SortingState>([])
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    const filteredInvoices = useMemo(() => {
        captureEvent('invoice_filter', { state })
        return invoices.filter((invoice) => state === 'ALL' || invoice.state === state)
    }, [invoices, state])

    const handleUpdateState = async (e: MouseEvent<HTMLDivElement>, invoiceId: string, state: Invoice['state']) => {
        e.stopPropagation()

        try {
            const resp = await updateInvoiceState(invoiceId, state)
            if ('error' in resp) throw new Error(resp.error)

            dispatch(invoiceModel.actions.updateInvoice({ state, _id: resp.data?._id }))

            captureEvent('invoice_state_update', { invoiceId, state })
        } catch (error) {
            console.error(error)
            const err = error as Error

            toast({ variant: 'destructive', title: 'Failed to update invoice state', description: err.message })

            captureException(error)
        }
    }

    const handleOpenInvoice = (e: MouseEvent<HTMLDivElement>, invoice: Invoice, mode: 'in-tab' | 'new-tab') => {
        e.stopPropagation()

        if (mode === 'in-tab') {
            captureEvent('invoice_open_in_tab', { invoiceId: invoice._id })
            navigate(`/invoices/${invoice._id}`)
        } else {
            window.open(`/invoices/${invoice._id}`, '_blank')
        }
    }

    const handleEdit = (e: MouseEvent<HTMLDivElement>, invoice: Invoice) => {
        e.stopPropagation()
        setSelectedInvoice(invoice)
        setIsInvoiceSheetOpen(true)

        captureEvent('invoice_edit', { invoiceId: invoice._id })
    }

    const handleDuplicate = (e: MouseEvent<HTMLDivElement>, invoice: Invoice) => {
        e.stopPropagation()

        const invoiceCopy = _.cloneDeep(invoice)
        delete invoiceCopy._id

        setSelectedInvoice(invoiceCopy)
        setIsInvoiceSheetOpen(true)

        captureEvent('invoice_duplicate', { invoiceId: invoice._id })
    }

    const handleDelete = async (e: MouseEvent<HTMLDivElement>, invoice: Invoice) => {
        e.stopPropagation()
        setSelectedInvoice(invoice)
        setIsDeleteModalOpen(true)

        captureEvent('invoice_delete', { invoiceId: invoice._id })
    }

    const columns: Array<ColumnDef<Invoice>> = [
        {
            accessorKey: 'prefix',
            header: 'No.',
            cell: ({ row }) => <div className="capitalize w-[90px]">{row.getValue('prefix')}</div>,
        },
        {
            accessorKey: 'issuedDate',
            header: ({ column }) => {
                return (
                    <div
                        className="flex items-center cursor-pointer w-[120px]"
                        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
                    >
                        Issue date
                        <ArrowsUpDownIcon className="ml-2 h-4 w-4" />
                    </div>
                )
            },
            cell: ({ row }) => <div className="capitalize w-[100px]">{dayjs(row.original.issuedDate).format('DD MMM, YYYY')}</div>,
        },
        {
            accessorKey: 'customers',
            header: ({ column }) => {
                return (
                    <Tooltip content="Sort by customers email">
                        <div
                            className="flex items-center cursor-pointer w-fit"
                            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
                        >
                            Customer
                            <ArrowsUpDownIcon className="ml-2 h-4 w-4" />
                        </div>
                    </Tooltip>
                )
            },
            cell: ({ row }) => {
                const customers = row.original.customers || []
                const customer = customers[0] as Customer | null | undefined

                return (
                    <User
                        avatarProps={{
                            src: customer?.avatar,
                            size: 'sm',
                            classNames: {
                                base: 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white cursor-pointer',
                                icon: 'text-white/80 w-7',
                            },
                        }}
                        name={customer?.name}
                        description={customer?.email}
                    />
                )
            },
        },
        {
            accessorKey: 'status',
            header: 'Status',
            cell: ({ row }) => (
                <Badge variant="outline" className={cn('rounded-sm', getInvoiceBadgeClass(row.original.state))}>
                    {_.upperFirst(_.lowerCase(row.original.state))}
                </Badge>
            ),
        },
        {
            id: 'netAmount',
            header: () => <div className="text-right">Net total</div>,
            cell: ({ row }) => {
                const currency = row.original.currency || 'USD'
                const { netTotal } = getInvoiceCalculations(row.original)

                const formatted = new Intl.NumberFormat('en-US', {
                    currency,
                    style: 'currency',
                }).format(netTotal)

                return <div className="text-right font-medium">{formatted}</div>
            },
        },
        {
            id: 'totalAmount',
            header: () => <div className="text-right">Total invoiced</div>,
            cell: ({ row }) => {
                const currency = row.original.currency || 'USD'
                const { total } = getInvoiceCalculations(row.original)

                const formatted = new Intl.NumberFormat('en-US', {
                    currency,
                    style: 'currency',
                }).format(total)

                return <div className="text-right font-medium">{formatted}</div>
            },
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                const invoice = row.original

                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button data-umami-event="Invoice manipulation button" variant="ghost" className="p-0 w-full">
                                <span className="sr-only">Open menu</span>
                                <EllipsisHorizontalIcon className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            {invoice.state !== 'PAID' && (
                                <DropdownMenuItem onClick={(e) => handleUpdateState(e, invoice._id!, 'PAID')}>
                                    Mark as paid
                                </DropdownMenuItem>
                            )}
                            {invoice.state !== 'PENDING' && (
                                <DropdownMenuItem onClick={(e) => handleUpdateState(e, invoice._id!, 'PENDING')}>
                                    Mark as sent
                                </DropdownMenuItem>
                            )}
                            {invoice.state !== 'DRAFT' && invoice.state !== 'RECURRING' && (
                                <DropdownMenuItem onClick={(e) => handleUpdateState(e, invoice._id!, 'DRAFT')}>
                                    Mark as draft
                                </DropdownMenuItem>
                            )}
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={(e) => handleOpenInvoice(e, invoice, 'new-tab')}>Open in new tab</DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={(e) => handleEdit(e, invoice)}>Edit</DropdownMenuItem>
                            <DropdownMenuItem onClick={(e) => handleDuplicate(e, invoice)}>Duplicate</DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem className="text-destructive" onClick={(e) => handleDelete(e, invoice)}>
                                Delete
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    const table = useReactTable({
        data: filteredInvoices,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
    })

    return (
        <div className="space-y-5">
            <InvoicesTabs />

            <Table>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead key={header.id}>
                                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                    </TableHead>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                            <TableRow
                                key={row.id}
                                className="cursor-pointer hover:bg-gray-50"
                                onClick={() => navigate(`/invoices/${row.original._id}`)}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length} className="h-24 text-center">
                                No results.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            {!!filteredInvoices.length && (
                <div className="flex items-center justify-end space-x-5">
                    <CsvManipulate
                        actions={['export']}
                        fileName={`invoices-${_.lowerCase(state)}.csv`}
                        data={[
                            [
                                'No',
                                'Currency',
                                'State',
                                'Customer name',
                                'Customer email',
                                'Items',
                                'Issue date',
                                'Due date',
                                'Payment details',
                                'Discount details',
                                'Tax details',
                                'Business logo applied',
                                'Paid date',
                                'Sent date',
                                'Creation date',
                            ],
                            ...filteredInvoices.map((invoice) => {
                                const customer = invoice.customers[0] as Customer | undefined | null

                                return [
                                    invoice.prefix || '-',
                                    invoice.currency,
                                    invoice.state || '-',
                                    customer?.name || '-',
                                    customer?.email || '-',
                                    invoice.items
                                        ?.map(
                                            (item) =>
                                                `${item.description} x${item.quantity} - ${new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: invoice.currency,
                                                }).format(item.amount)}`,
                                        )
                                        .join(', ') || '-',
                                    dayjs(invoice.issuedDate).format('MMM D, YYYY'),
                                    dayjs(invoice.dueDate).format('MMM D, YYYY'),
                                    invoice.payment?.paymentDetails || '-',
                                    `${invoice.discount?.name || 'Discount'} - ${invoice.discount?.percentage || 0}%`,
                                    `${invoice.tax?.name || 'Tax'} (${_.lowerCase(invoice.tax?.mode)}) - ${invoice.tax?.percentage || 0}%`,
                                    invoice.settings?.applyBusinessLogo ? 'Yes' : 'No',
                                    invoice.paidAt ? dayjs(invoice.paidAt).format('MMM D, YYYY') : '-',
                                    invoice.sentAt ? dayjs(invoice.sentAt).format('MMM D, YYYY') : '-',
                                    invoice.metadata.createdAt ? dayjs(invoice.metadata.createdAt).format('MMM D, YYYY') : '-',
                                ]
                            }),
                        ]}
                    />
                </div>
            )}

            <InvoiceSheet invoice={selectedInvoice!} open={isInvoiceSheetOpen} onClose={setIsInvoiceSheetOpen} />
            <InvoiceDeleteModal
                invoiceId={selectedInvoice?._id || ''}
                isOpen={isDeleteModalOpen}
                onClose={setIsDeleteModalOpen}
                onSubmit={() => navigate('/invoices')}
            />
        </div>
    )
}

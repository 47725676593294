import { type Control } from 'react-hook-form'

import { FormControl, FormField, FormItem, FormLabel, FormMessage, Textarea } from '@/shared'

import { type InvoiceFormValues } from './invoice-form'

interface Props {
    control: Control<InvoiceFormValues>
}

export const InvoiceFormPayments = ({ control }: Props) => {
    return (
        <FormField
            control={control}
            name="payment.paymentDetails"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>Payment details</FormLabel>
                    <FormControl>
                        <Textarea
                            {...field}
                            className="resize-none"
                            placeholder="Bank name:&#13;&#10;Account number:&#13;&#10;IBAN:&#13;&#10;SWIFT:"
                            rows={7}
                        />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    )
}

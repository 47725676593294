import dayjs from 'dayjs'
import { useState } from 'react'

import { PricingCard, UserDeleteDialog, useUserData } from '@/features'
import {
    APP_CONFIG,
    Badge,
    Button,
    captureEvent,
    Card,
    CardDescription,
    CardHeader,
    CardTitle,
    Input,
    Label,
    Separator,
    Skeleton,
    Typography,
    useStripe,
} from '@/shared'

export const SettingsAccountForm = () => {
    const user = useUserData()
    const { pricingArr, isLoading } = useStripe()

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    const handleNavigateToStripePortal = () => {
        captureEvent('settings_manage_subscription')
        window.location.href = APP_CONFIG.STRIPE_CUSTOMER_PORTAL_URL
    }

    return (
        <div className="space-y-6">
            <div className="flex flex-col md:flex-row gap-6 w-full">
                <div className="space-y-2 md:w-6/12">
                    <Label>Account name</Label>
                    <Input disabled value={user?.fullName} />
                </div>

                <div className="space-y-2 md:w-6/12">
                    <Label>Email</Label>
                    <Input disabled value={user?.email} />
                </div>
            </div>

            <Separator />

            <div className="space-y-6">
                <div className="space-y-1">
                    {user?.billing?.plan === 'TRIAL' && (
                        <Typography variant="p" className="font-medium">
                            Plans
                        </Typography>
                    )}
                </div>

                {isLoading && (
                    <div className="flex items-center space-x-2">
                        {Array.from({ length: 2 }).map((_, index) => (
                            <Skeleton key={index} className="w-full h-96" />
                        ))}
                    </div>
                )}

                {!isLoading && pricingArr?.length === 0 && (
                    <Typography variant="p" className="font-medium">
                        No plans available
                    </Typography>
                )}

                {!isLoading && user?.billing?.plan === 'TRIAL' && (
                    <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3">
                        {pricingArr?.map((pricing, index) => <PricingCard key={index} pricing={pricing} />)}
                    </div>
                )}

                {!isLoading && user?.billing?.plan !== 'TRIAL' && (
                    <Card className="overflow-hidden">
                        <CardHeader className="flex flex-row items-start">
                            <div className="grid gap-0.5">
                                <CardTitle className="group flex items-center gap-2 text-lg">
                                    Plan:
                                    <Badge variant="default" className="rounded-sm">
                                        Pro
                                    </Badge>
                                </CardTitle>
                                <CardDescription className="space-y-3">
                                    <Typography variant="subtitle">
                                        Next billing date: {dayjs(user?.billing?.nextBillingAt).format('DD MMM, YYYY')}
                                    </Typography>

                                    <Button variant="secondary" onClick={handleNavigateToStripePortal}>
                                        Manage subscription
                                    </Button>
                                </CardDescription>
                            </div>
                        </CardHeader>
                    </Card>
                )}
            </div>

            <Separator />

            <div className="space-y-3">
                <Typography variant="p" className="font-medium">
                    Danger zone
                </Typography>
                <Button variant="destructive" onClick={() => setIsDeleteModalOpen(true)}>
                    Delete my account and all related data
                </Button>
            </div>

            <UserDeleteDialog isOpen={isDeleteModalOpen} onClose={setIsDeleteModalOpen} />
        </div>
    )
}

import { useAuth } from '@clerk/clerk-react'
import {
    AdjustmentsVerticalIcon,
    ArrowRightEndOnRectangleIcon,
    ArrowTopRightOnSquareIcon,
    BanknotesIcon,
    Bars3Icon,
    ChartBarIcon,
    DocumentTextIcon,
    UsersIcon,
} from '@heroicons/react/20/solid'
import { Image, Tooltip } from '@nextui-org/react'
import { createElement, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import LogoMobileImage from '@/assets/images/logo.png'
import LogoMobileSheetImage from '@/assets/images/logo-full.svg'
import { captureEvent, cn, ScrollArea, Separator, Sheet, SheetContent, SheetHeader, SheetTrigger } from '@/shared'

import { SidebarProfile } from './sidebar-profile'

export const Sidebar = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { signOut } = useAuth()

    const sidebarItems = [
        {
            title: 'Dashboard',
            icon: ChartBarIcon,
            path: '/dashboard',
            description: 'View your business performance at a glance and track your progress.',
            showInSidebar: true,
        },
        {
            title: 'Invoices',
            icon: DocumentTextIcon,
            path: '/invoices',
            description: 'Create and manage invoices for your customers.',
            showInSidebar: true,
        },
        {
            title: 'Customers',
            icon: UsersIcon,
            path: '/customers',
            description: 'Manage your customers and keep track of their information.',
            showInSidebar: true,
        },
        {
            title: 'Transactions',
            icon: BanknotesIcon,
            path: '/transactions',
            description: 'Monitor your transactions and keep track of your financial activities',
            showInSidebar: true,
        },
        {
            title: 'Settings',
            icon: AdjustmentsVerticalIcon,
            path: '/settings/business',
            description: 'Customize your brand and manage your account settings.',
            showInSidebar: false,
        },
        {
            title: 'Changelog',
            icon: ArrowTopRightOnSquareIcon,
            path: '*changelog*',
            description: 'View the latest updates and changes to the app.',
            showInSidebar: false,
        },
        {
            title: 'Logout',
            icon: ArrowRightEndOnRectangleIcon,
            path: '*logout*',
            description: 'Sign out of your account.',
            showInSidebar: false,
        },
    ]

    const [activeSidebarItem, setActiveSidebarItem] = useState<string | null>(null)
    const [isMobileSheetOpen, setIsMobileSheetOpen] = useState(false)

    const handleSidebarItemClick = (item: (typeof sidebarItems)[number]) => {
        navigate(item.path)
        setActiveSidebarItem(item.path)
        setIsMobileSheetOpen(false)
        captureEvent('sidebar_item_clicked', { item: item.title })
    }

    useEffect(() => {
        const activeItem = sidebarItems.find((item) => location.pathname.includes(item.path))

        if (activeItem) {
            setActiveSidebarItem(activeItem.path)
        } else {
            setActiveSidebarItem(null)
        }
    }, [location.pathname])

    // If width is "md" automatically close the mobile sheet
    useEffect(() => {
        if (window.innerWidth >= 768) {
            setIsMobileSheetOpen(false)
        }
    }, [window.innerWidth])

    return (
        <>
            {/* Desktop sidebar */}
            <div className="fixed bg-gray hidden md:flex flex-col justify-between items-center py-5">
                <div className="space-y-6 flex items-center justify-center flex-col">
                    <SidebarProfile />

                    <Separator />

                    <div className="flex flex-col justify-center items-center gap-4">
                        {sidebarItems
                            .filter((item) => item.showInSidebar)
                            .map((item) => (
                                <Tooltip key={item.path} content={item.title} placement="right" radius="sm">
                                    <div
                                        className={cn(
                                            'bg-transparent p-2.5 rounded-md cursor-pointer hover:bg-white transition-all group',
                                            {
                                                'bg-white text-primary shadow-sm': activeSidebarItem === item.path,
                                            },
                                        )}
                                        onClick={() => handleSidebarItemClick(item)}
                                    >
                                        {createElement(item.icon, {
                                            className: cn('text-gray-500 size-5', 'group-hover:text-primary', {
                                                'text-primary': activeSidebarItem === item.path,
                                            }),
                                        })}
                                    </div>
                                </Tooltip>
                            ))}
                    </div>
                </div>
            </div>

            {/* Mobile sidebar */}
            <div className="flex md:hidden absolute top-0 left-0 h-12 w-full bg-white shadow-sm items-center">
                <div className="container flex justify-between items-center">
                    <Image
                        src={LogoMobileImage}
                        alt="Logo"
                        className="w-7 h-7 cursor-pointer hover:opacity-80"
                        onClick={() => navigate('/')}
                    />

                    <Sheet open={isMobileSheetOpen} onOpenChange={setIsMobileSheetOpen}>
                        <SheetTrigger asChild>
                            <Bars3Icon className="w-5 h-5 cursor-pointer" />
                        </SheetTrigger>
                        <SheetContent className="w-full max-w-full min-w-full">
                            <ScrollArea className="h-screen">
                                <SheetHeader>
                                    <Image
                                        src={LogoMobileSheetImage}
                                        alt="Logo"
                                        className="w-fit max-w-52 h-7 cursor-pointer hover:opacity-80"
                                    />
                                </SheetHeader>

                                <div className="flex flex-col justify-between h-full pb-12">
                                    <div className="space-y-3 mt-6">
                                        {sidebarItems.map((item) => (
                                            <div
                                                key={item.path}
                                                className=" flex items-center space-x-4 rounded-md border p-4 cursor-pointer hover:opacity-80"
                                                onClick={() => {
                                                    if (item.path === '*logout*') {
                                                        setIsMobileSheetOpen(false)
                                                        signOut()
                                                    } else if (item.path === '*changelog*') {
                                                        captureEvent('changelog_viewed')
                                                        window.open('https://insomnee.com/updates')
                                                        setIsMobileSheetOpen(false)
                                                    } else {
                                                        handleSidebarItemClick(item)
                                                    }
                                                }}
                                            >
                                                {createElement(item.icon, {
                                                    className: cn('w-5 h-5 text-primary', {
                                                        'text-destructive': item.path === '*logout*',
                                                    }),
                                                })}
                                                <div className="flex-1 space-y-1">
                                                    <p
                                                        className={cn('text-sm font-medium leading-none', {
                                                            'text-destructive': item.path === '*logout*',
                                                        })}
                                                    >
                                                        {item.title}
                                                    </p>
                                                    <p className="text-sm text-muted-foreground">{item.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </ScrollArea>
                        </SheetContent>
                    </Sheet>
                </div>
            </div>
        </>
    )
}

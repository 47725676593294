import { DashboardCard } from '@/entities'
import { cn, type DashboardOverview, Skeleton } from '@/shared'

interface Props {
    overview: DashboardOverview | undefined
    isLoading: boolean
}

export const DashboardOverviewCards = ({ overview, isLoading }: Props) => {
    const cards = [
        {
            title: 'Revenue',
            value: (overview?.profit ?? 0).toFixed(2),
            description: '-',
        },
        {
            title: 'Expenses',
            value: (overview?.totalExpenses ?? 0).toFixed(2),
            description: '-',
        },
        {
            title: 'Monthly revenue',
            value: (overview?.profitAverageMonthly ?? 0).toFixed(2),
            description: 'Based on the last 6 months',
        },
        {
            title: 'Revenue forecast',
            value: (overview?.profitForecast ?? 0).toFixed(2),
            description: 'At the end of the year',
        },
    ]

    return (
        <div
            className={cn('grid md:gap-4 grid-cols-2 lg:grid-cols-4 mt-0 md:mt-6', {
                'border-b border-t border-default-100 py-4 md:py-0': !isLoading && overview,
            })}
        >
            {isLoading || !overview
                ? Array.from({ length: 4 }, (_, i) => i).map((i) => <Skeleton key={i} className="w-full h-32" />)
                : cards.map((card) => (
                      <DashboardCard key={card.title} title={card.title} description={card.description} value={card.value} />
                  ))}
        </div>
    )
}

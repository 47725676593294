import { type Invoice } from '@/shared'

export const getInvoiceCalculations = (
    invoice?: Invoice,
): {
    subtotal: number
    taxes: number
    discounts: number
    total: number
    netTotal: number
} => {
    if (!invoice)
        return {
            subtotal: 0,
            taxes: 0,
            discounts: 0,
            total: 0,
            netTotal: 0,
        }

    // Subtotal without any tax or discount
    const subtotal = invoice?.items?.reduce((acc, item) => acc + item.amount * item.quantity, 0) || 0
    // Taxes (calculate total amount of taxes based on subtotal and tax percentage)
    const taxes = invoice?.tax?.percentage ? (subtotal * invoice.tax.percentage) / 100 : 0
    // Discounts (calculate total amount of discount based on subtotal and discount percentage)
    const discounts = invoice?.discount?.percentage ? (subtotal * invoice.discount.percentage) / 100 : 0

    // Total
    const total = subtotal - (invoice.tax?.mode === 'INCLUSIVE' ? 0 : taxes) - discounts

    // Net total
    const netTotal = subtotal - taxes - discounts

    return {
        subtotal,
        taxes,
        discounts,
        total,
        netTotal,
    }
}

import dayjs from 'dayjs'
import _ from 'lodash'
import { useMemo } from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, type TooltipProps, XAxis, YAxis } from 'recharts'
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

import { emptyMock, useUserData } from '@/features'
import { type DashboardOverview, Separator, Skeleton, Typography } from '@/shared'

interface Props {
    data: DashboardOverview['yearTotals'] | undefined
    isLoading: boolean
}

export const DashboardProfitChart = ({ data, isLoading }: Props) => {
    const user = useUserData()

    /** If all data profit are 0 */
    const isEmptyData = useMemo(() => {
        return data?.every((item) => item.profit === 0)
    }, [data])

    const domain = useMemo(() => {
        if (isEmptyData) {
            return [100, 1000]
        }

        const max = _.maxBy(data, 'profit')?.profit ?? 15000
        const min = _.minBy(data, 'profit')?.profit ?? 5000

        return [min - 1500, max + 1500]
    }, [data, isEmptyData])

    const formatter = (value: number) =>
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: user?.business?.currency || 'USD',
        }).format(value)

    const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
        const year = payload?.[0]?.payload?.year || dayjs().year()
        const profit = payload?.[0]?.payload?.profit ?? 0
        const expensesTaxes = payload?.[0]?.payload?.expensesTaxes || 0
        const expenses = payload?.[0]?.payload?.expenses || 0
        const discounts = payload?.[0]?.payload?.discount || 0

        if (!profit && !expensesTaxes) {
            return null
        }

        if (active) {
            return (
                <div className="bg-white p-3 w-full max-w-56 rounded-md space-y-2">
                    <p className="text-xs text-default-400">
                        {label} {year}
                    </p>

                    <Separator />

                    <div className="flex items-center gap-1">
                        <div className="text-xs flex items-center gap-1">
                            <div className="w-1.5 h-1.5 bg-[#2E4A75] rounded-full" />
                            <span className="text-xs">Profit</span>
                        </div>

                        <div className="text-xs font-medium">{formatter(Number(profit))}</div>
                    </div>

                    <div className="flex items-center gap-1">
                        <div className="text-xs flex items-center gap-1">
                            <div className="w-1.5 h-1.5 bg-[#FCA5A5] rounded-full" />
                            <span className="text-xs">Tax expenses</span>
                        </div>

                        <div className="text-xs font-medium">{formatter(Number(expensesTaxes))}</div>
                    </div>

                    <div className="flex items-center gap-1">
                        <div className="text-xs flex items-center gap-1">
                            <div className="w-1.5 h-1.5 bg-red-500 rounded-full" />
                            <span className="text-xs">Expenses</span>
                        </div>

                        <div className="text-xs font-medium">{formatter(Number(expenses))}</div>
                    </div>

                    <div className="flex items-center gap-1">
                        <div className="text-xs flex items-center gap-1">
                            <div className="w-1.5 h-1.5 bg-slate-600 rounded-full" />
                            <span className="text-xs">Discounts</span>
                        </div>

                        <div className="text-xs font-medium">{formatter(Number(discounts))}</div>
                    </div>
                </div>
            )
        }

        return null
    }

    if (isLoading) {
        return <Skeleton className="w-full h-[300px]" />
    }

    return (
        <div className="space-y-6">
            <Typography variant="p" className="font-semibold">
                Profit flow
            </Typography>

            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={isEmptyData ? emptyMock : data} margin={{ right: 20, left: 20 }}>
                    <Line
                        isAnimationActive={false}
                        className="chart-line-shadow"
                        type="monotone"
                        dataKey="profit"
                        stroke="#1D6EFF"
                        strokeWidth={2}
                        dot={!isEmptyData}
                    />
                    <CartesianGrid horizontal={false} stroke="#F1F5F9" strokeDasharray="0 0" />
                    <XAxis interval={0} dataKey="monthShortName" stroke="#9BA3AF" className="text-xs" />
                    <YAxis tick={false} axisLine={false} domain={domain} width={0} />
                    {!isEmptyData && <Tooltip content={<CustomTooltip />} cursor={false} />}
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

import { useAuth } from '@clerk/clerk-react'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { AuthHero } from '@/entities'

export const AuthLayout = () => {
    const { signOut } = useAuth()

    useEffect(() => {
        signOut()
    }, [])

    return (
        <main id="auth-layout" className="min-h-screen h-full">
            <div className="w-full h-full md:space-x-12 flex">
                <AuthHero />
                <div className="flex flex-col justify-center w-full min-h-screen px-6 md:px-0 py-6">
                    <Outlet />
                </div>
            </div>
        </main>
    )
}

import { Helmet } from 'react-helmet'

import { AuthLogo } from '@/entities'
import { AuthForgotForm } from '@/features'
import { Page, Separator, Typography } from '@/shared'

export const ForgotPasswordPage = () => {
    return (
        <>
            <Helmet>
                <title>Forgot Password - Insomnee</title>
            </Helmet>

            <Page className="flex flex-col space-y-6 max-w-md w-full mx-auto">
                <AuthLogo type="form" />

                <Typography variant="h3">Forgot Password? 🤔</Typography>
                <Typography variant="p" className="text-gray-400">
                    Don&apos;t worry! Enter your email and we will send you a link to reset your password.
                </Typography>

                <Separator />

                <AuthForgotForm />
            </Page>
        </>
    )
}

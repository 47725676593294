import { BanknotesIcon, CheckCircleIcon, PaperAirplaneIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'
import _ from 'lodash'

import { getInvoiceCalculations } from '@/features/invoice'
import { type Customer, type Invoice, Label, Separator, Typography, type User } from '@/shared'

interface Props {
    invoice: Invoice
    user: User
}

export const PortalInvoiceDetails = ({ invoice, user }: Props) => {
    const { customers, issuedDate, dueDate, prefix, items, tax, discount, state } = invoice
    const { fullName, email } = user
    const customer = customers[0] as Customer | null | undefined

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: invoice.currency || user.business?.currency || 'USD',
    })

    const { subtotal, total, taxes, discounts } = getInvoiceCalculations(invoice)

    return (
        <div className="border bg-white rounded-md shadow-sm p-6 sm:p-12 space-y-6">
            <div className="flex flex-col sm:flex-row sm:items-center gap-6">
                <div className="w-10 h-10 sm:w-16 sm:h-16 rounded-lg bg-gray-100 flex justify-center items-center">
                    <BanknotesIcon className="w-4 h-4 sm:w-7 sm:h-7 text-gray-500" />
                </div>

                <div>
                    <Typography variant="h3">Invoice #{prefix}</Typography>
                    <Typography variant="subtitle">
                        Issued on {dayjs(issuedDate).format('DD MMM, YYYY')}{' '}
                        {dueDate ? `- Due on ${dayjs(dueDate).format('DD MMM, YYYY')}` : null}
                    </Typography>
                </div>
            </div>

            <div className="space-y-3 sm:space-y-0">
                <div className="inline-block sm:flex items-center gap-2 sm:gap-0">
                    <Typography variant="subtitle" className="sm:w-16 underline sm:no-underline">
                        To
                    </Typography>
                    <Typography variant="subtitle">
                        {customer?.name}, {customer?.email}
                    </Typography>
                </div>
                <div className="inline-block sm:flex items-center gap-2 sm:gap-0">
                    <Typography variant="subtitle" className="sm:w-16 underline sm:no-underline">
                        From
                    </Typography>
                    <Typography variant="subtitle">
                        {fullName}, {email}
                    </Typography>
                </div>
            </div>

            {invoice.notes && (
                <>
                    <Separator />
                    <Typography variant="p">{invoice.notes}</Typography>
                </>
            )}

            <Separator />

            {items?.map((item, index) => (
                <div key={index} className="flex justify-between">
                    <div className="flex space-x-2 items-center">
                        <Typography variant="p">{_.truncate(item.description || '-', { length: 30 })}</Typography>
                        <Typography variant="p" className="text-gray-400">
                            x {item.quantity} Qty
                        </Typography>
                    </div>
                    <Typography variant="p">{formatter.format(item.amount)}</Typography>
                </div>
            ))}

            <Separator />

            <div className="space-y-4">
                <div className="flex justify-between">
                    <Typography variant="p">Subtotal</Typography>
                    <Typography variant="p">{formatter.format(subtotal)}</Typography>
                </div>

                <div className="flex justify-between text-gray-400">
                    <div className="flex space-x-2 items-center">
                        <Typography variant="p">{tax?.name || 'Tax'}</Typography>
                        <Typography variant="p" className="text-gray-400">
                            ({tax?.percentage || 0}%)
                        </Typography>
                    </div>
                    <Typography variant="p">{formatter.format(taxes)}</Typography>
                </div>

                {!!discount?.percentage && (
                    <div className="flex justify-between text-gray-400">
                        <div className="flex space-x-2 items-center">
                            <Typography variant="p">{discount?.name || 'Discount'}</Typography>
                            <Typography variant="p" className="text-gray-400">
                                ({discount?.percentage || 0}%)
                            </Typography>
                        </div>
                        <Typography variant="p">{formatter.format(discounts)}</Typography>
                    </div>
                )}

                <div className="flex justify-between">
                    <Typography variant="h3">Total due</Typography>
                    <Typography variant="h3">{formatter.format(total)}</Typography>
                </div>
            </div>

            <Separator />

            {invoice.payment?.paymentDetails && (
                <>
                    <div className="space-y-3">
                        <Label>Payment details</Label>
                        <Typography variant="subtitle" className="whitespace-pre-line">
                            {invoice.payment?.paymentDetails}
                        </Typography>
                    </div>

                    <Separator />
                </>
            )}

            {invoice.notes && (
                <>
                    <div className="space-y-3">
                        <Label>Notes</Label>
                        <Typography variant="subtitle" className="whitespace-pre-line">
                            {invoice.notes}
                        </Typography>
                    </div>

                    <Separator />
                </>
            )}

            {state === 'PAID' && (
                <div className="bg-emerald-100 p-4 flex justify-center items-center shadow-sm rounded-lg">
                    <Typography variant="p" className="text-emerald-500 font-medium flex items-center">
                        <CheckCircleIcon className="w-6 h-6 mr-2" /> Invoice paid
                    </Typography>
                </div>
            )}

            {state === 'PENDING' && (
                <div className="bg-indigo-100 p-4 flex justify-center items-center shadow-sm rounded-lg">
                    <Typography variant="p" className="text-indigo-500 font-medium flex items-center">
                        <PaperAirplaneIcon className="w-6 h-6 mr-2" /> Invoice sent
                    </Typography>
                </div>
            )}

            <div className="text-center">
                <a href={`mailto:${user?.email}`} className="text-primary text-sm">
                    Questions? Contact {user?.fullName}
                </a>
            </div>
        </div>
    )
}

import { Helmet } from 'react-helmet'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { PortalInvoiceDetails, PortalInvoiceLogo, PortalInvoiceManipulations } from '@/features'
import { getInvoiceById, getUserPortal, Page, Skeleton, useToast } from '@/shared'

export const InvoicePortalPage = () => {
    const params = useParams<{ id?: string }>()
    const navigate = useNavigate()
    const { toast } = useToast()

    const handleGetInvoice = async () => {
        try {
            if (!params.id) {
                navigate('/invoices')
                return
            }

            const resp = await getInvoiceById(params.id)
            if ('error' in resp) throw new Error(resp.error)

            return resp.data
        } catch (error) {
            const err = error as Error

            toast({ variant: 'destructive', title: 'Oops! Something went wrong.', description: err.message })
            navigate('/invoices')
        }
    }

    const { data: invoice, isLoading: isInvoiceLoading } = useQuery({
        queryKey: ['invoice', params.id],
        queryFn: handleGetInvoice,
        enabled: !!params.id,
        onError: (error) => {
            const err = error as Error

            toast({ variant: 'destructive', title: 'Oops! Something went wrong.', description: err.message })
            window.location.href = 'www.insomnee.com'
        },
    })

    const { data: user, isLoading: isUserLoading } = useQuery({
        queryKey: ['user', invoice?.metadata.createdBy],
        queryFn: () => getUserPortal(invoice?.metadata.createdBy || ''),
        enabled: !!invoice?.metadata?.createdBy,
        onError: (error) => {
            const err = error as Error

            toast({ variant: 'destructive', title: 'Oops! Something went wrong.', description: err.message })
            window.location.href = 'www.insomnee.com'
        },
        select: (data) => data.data,
    })

    return (
        <Page className="bg-slate-100 min-h-screen py-12">
            <Helmet>
                <title>Invoice - Insomnee</title>
            </Helmet>

            <div className="px-6 md:px-12 max-w-2xl w-full mx-auto">
                {isInvoiceLoading || isUserLoading || !invoice || !user ? (
                    <Skeleton className="w-full h-[350px]" />
                ) : (
                    <div className="space-y-3">
                        <PortalInvoiceManipulations invoice={invoice} />
                        <PortalInvoiceDetails invoice={invoice} user={user} />
                        <PortalInvoiceLogo />
                    </div>
                )}
            </div>
        </Page>
    )
}

import { CircularProgress } from '@nextui-org/react'

import LogoImg from '@/assets/images/logo-full.svg'

export const LoaderScreen = () => {
    return (
        <div className="min-h-screen h-full p-6 flex justify-center items-center flex-col space-y-6">
            <img src={LogoImg} alt="logo" className="w-32" />

            <CircularProgress size="sm" />
        </div>
    )
}

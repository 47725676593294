import { DocumentArrowDownIcon } from '@heroicons/react/20/solid'
import { captureException } from '@sentry/react'
import { useState } from 'react'

import { Button, getInvoicePdfUrl, type Invoice, useToast } from '@/shared'

interface Props {
    invoice: Invoice
}

export const PortalInvoiceManipulations = ({ invoice }: Props) => {
    const { toast } = useToast()
    const [isLoading, setIsLoading] = useState(false)

    const handleDownloadPdf = async () => {
        try {
            if (!invoice?._id) return

            setIsLoading(true)

            const resp = await getInvoicePdfUrl(invoice._id)
            if (resp.error) throw new Error(resp.error)

            // Open the url in a new tab
            window.open(resp.data, '_blank')
        } catch (error) {
            console.error(error)
            const err = error as Error

            toast({ variant: 'destructive', title: 'Oops! Something went wrong.', description: err.message })

            captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="flex flex-row-reverse">
            <Button isLoading={isLoading} variant="outline" onClick={handleDownloadPdf}>
                <DocumentArrowDownIcon className="w-4 h-4 mr-2" />
                Download PDF
            </Button>
        </div>
    )
}

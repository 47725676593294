import { Tooltip, User } from '@nextui-org/react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { getInvoiceBadgeClass, getInvoiceCalculations, getInvoiceNextRecurringDate, InvoiceRecurringDialog } from '@/features'
import { Badge, Button, cn, type Customer, type Invoice, Separator, Typography } from '@/shared'

interface Props {
    invoice: Invoice | undefined
}

export const InvoiceDetails = ({ invoice }: Props) => {
    const { total } = getInvoiceCalculations(invoice)

    const customers = (invoice?.customers as Customer[]) || undefined || []
    const customer = customers[0] || undefined

    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: invoice?.currency || 'USD' })

    const [showRecurringEmailDialog, setShowRecurringEmailDialog] = useState(false)

    return (
        <div className="space-y-6">
            <div className="flex justify-between gap-12 items-center">
                <Typography variant="h3">Total due</Typography>
                <Typography variant="h3">{formatter.format(total)}</Typography>
            </div>

            <Separator />

            <div className="flex justify-between gap-12 items-center">
                <Typography variant="subtitle">Customer</Typography>
                <User
                    name={customer?.name}
                    description={customer?.email}
                    avatarProps={{
                        src: customer?.avatar,
                        size: 'sm',
                        classNames: {
                            base: 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white cursor-pointer',
                            icon: 'text-white/80 w-7',
                        },
                    }}
                />
            </div>

            <div className="flex justify-between gap-12 items-center">
                <Typography variant="subtitle">Status</Typography>
                <Badge variant="outline" className={cn('rounded-sm', getInvoiceBadgeClass(invoice?.state))}>
                    {_.upperFirst(_.lowerCase(invoice?.state))}
                </Badge>
            </div>

            <div className="flex justify-between gap-12 items-center">
                <Typography variant="subtitle">Issued date</Typography>
                <Typography variant="subtitle">{dayjs(invoice?.issuedDate).format('DD MMMM, YYYY')}</Typography>
            </div>

            {invoice?.dueDate && (
                <div className="flex justify-between gap-12 items-center">
                    <Typography variant="subtitle">Due date</Typography>
                    <Typography variant="subtitle">{dayjs(invoice?.dueDate).format('DD MMMM, YYYY')}</Typography>
                </div>
            )}

            <Separator />

            <Typography variant="p" className="font-semibold">
                History
            </Typography>

            <div className="flex justify-between gap-12 items-center">
                <Typography variant="subtitle">Invoice created</Typography>
                <Typography variant="subtitle">{dayjs(invoice?.metadata?.createdAt).format('DD MMMM, YYYY, HH:mm')}</Typography>
            </div>

            <div className="flex justify-between gap-12 items-center">
                <Typography variant="subtitle">Invoice updated</Typography>
                <Typography variant="subtitle">{dayjs(invoice?.metadata?.updatedAt).format('DD MMMM, YYYY, HH:mm')}</Typography>
            </div>

            {invoice?.recurring?.isActive && (
                <>
                    <Separator />

                    <Typography variant="p" className="font-semibold">
                        Recurring
                    </Typography>

                    <div className="flex justify-between gap-12 items-center">
                        <Typography variant="subtitle">Next recurring date</Typography>
                        <Typography variant="subtitle">{getInvoiceNextRecurringDate(invoice.recurring.frequency)}</Typography>
                    </div>

                    <div className="flex justify-between gap-12 items-center">
                        <Typography variant="subtitle">Target email</Typography>
                        <Tooltip content={customer.email}>
                            <div>
                                <Typography variant="subtitle">{_.truncate(customer.email, { length: 25 })}</Typography>
                            </div>
                        </Tooltip>
                    </div>

                    <div className="space-y-2">
                        <div className="flex justify-between gap-12 items-center">
                            <Typography variant="subtitle">Email message</Typography>
                            <Button size="sm" variant="outline" onClick={() => setShowRecurringEmailDialog(true)}>
                                Show preview
                            </Button>
                        </div>

                        <Typography variant="subtitle" className="text-heading">
                            You can change email message in your{' '}
                            <Link to="/settings/invoice" className="text-primary">
                                settings
                            </Link>
                        </Typography>
                    </div>
                </>
            )}

            <InvoiceRecurringDialog open={showRecurringEmailDialog} invoice={invoice!} onClose={setShowRecurringEmailDialog} />
        </div>
    )
}

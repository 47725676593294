import { type Control } from 'react-hook-form'

import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
    useCurrency,
} from '@/shared'

import { type TransactionFormValues } from './transaction-form'

interface Props {
    control: Control<TransactionFormValues>
}

export const TransactionFormCurrency = ({ control }: Props) => {
    const { currency } = useCurrency()

    return (
        <FormField
            control={control}
            name="currency"
            render={({ field }) => (
                <FormItem className="w-full">
                    <FormLabel>Currency</FormLabel>
                    <FormControl>
                        <Select value={field.value} onValueChange={field.onChange}>
                            <SelectTrigger>
                                <SelectValue placeholder="Currency" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    {currency?.data?.map((currency, index) => (
                                        <SelectItem key={index} value={currency.name}>
                                            {currency.name}
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </FormControl>
                </FormItem>
            )}
        />
    )
}

import { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { invoiceModel, useInvoiceById } from '@/features'
import { BackBtn, getInvoiceById, Page, Skeleton, useToast } from '@/shared'
import { InvoiceDetails, InvoiceManipulate, InvoicePdf } from '@/widgets'

export const InvoicePage = () => {
    const dispatch = useDispatch()
    const params = useParams<{ id?: string }>()
    const navigate = useNavigate()
    const { toast } = useToast()
    const invoiceData = useInvoiceById(params.id!)

    const handleGetInvoice = async () => {
        try {
            if (!params.id) {
                navigate('/invoices')
                return
            }

            const resp = await getInvoiceById(params.id)
            if ('error' in resp) throw new Error(resp.error)

            return resp.data
        } catch (error) {
            const err = error as Error

            toast({ variant: 'destructive', title: 'Oops! Something went wrong.', description: err.message })
            navigate('/invoices')
        }
    }

    const { isLoading } = useQuery({
        queryKey: ['invoice', params.id],
        queryFn: handleGetInvoice,
        enabled: !!params.id,
        onSuccess: (data) => data && dispatch(invoiceModel.actions.addInvoice(data)),
        onError: (error) => {
            const err = error as Error

            toast({ variant: 'destructive', title: 'Oops! Something went wrong.', description: err.message })
            navigate('/invoices')
        },
    })

    const invoice = useMemo(() => {
        return invoiceData
    }, [invoiceData])

    return (
        <>
            <Helmet>
                <title>Invoice - Insomnee</title>
            </Helmet>

            <Page className="space-y-6">
                <BackBtn href="/invoices">Invoices</BackBtn>
                <div className="w-full flex flex-col xl:flex-row gap-12">
                    <div className="w-full xl:w-8/12">
                        {isLoading || !invoice ? <Skeleton className="h-[600px] w-full" /> : <InvoicePdf invoice={invoice} />}
                    </div>

                    <div className="xl:w-4/12 space-y-12">
                        {isLoading || !invoice ? <Skeleton className="w-full h-24" /> : <InvoiceManipulate invoice={invoice} />}
                        {isLoading || !invoice ? <Skeleton className="w-full h-[455px]" /> : <InvoiceDetails invoice={invoice} />}
                    </div>
                </div>
            </Page>
        </>
    )
}

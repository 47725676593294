import { captureException } from '@sentry/react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { invoiceModel } from '@/features'
import {
    Button,
    captureEvent,
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    removeInvoice,
    useToast,
} from '@/shared'

interface Props {
    invoiceId: string
    isOpen: boolean
    onClose: (isOpen: boolean) => void
    onSubmit?: () => void
}

export const InvoiceDeleteModal = ({ invoiceId, isOpen, onClose, onSubmit }: Props) => {
    const dispatch = useDispatch()
    const { toast } = useToast()

    const [isLoading, setIsLoading] = useState(false)

    const handleDeleteInvoice = async () => {
        try {
            if (!invoiceId) {
                console.error('Invoice ID is required')
                throw new Error('Try again later or contact support for assistance')
            }

            setIsLoading(true)

            const resp = await removeInvoice(invoiceId)
            if (resp.error) throw new Error(resp.error)

            toast({ title: 'Invoice deleted', description: 'Your invoice has been successfully deleted.' })

            dispatch(invoiceModel.actions.removeInvoice(invoiceId))
            captureEvent('invoice_delete', { invoiceId })

            onClose(false)
            onSubmit?.()
        } catch (error) {
            console.error(error)

            const err = error as Error

            toast({
                variant: 'destructive',
                title: 'Failed to delete invoice',
                description: err.message,
            })

            captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader className="space-y-3">
                    <DialogTitle>Are you absolutely sure?</DialogTitle>
                    <DialogDescription>
                        This action cannot be undone. This will permanently delete your invoice from our servers.
                    </DialogDescription>
                </DialogHeader>

                <div className="flex flex-row-reverse">
                    <Button isLoading={isLoading} variant="destructive" className="w-fit" onClick={handleDeleteInvoice}>
                        Delete invoice
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

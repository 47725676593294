import _ from 'lodash'
import { useMemo } from 'react'
import { type Control, type UseFormWatch } from 'react-hook-form'

import { getInvoiceNextRecurringDate } from '@/features'
import {
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    type InvoiceRecurringFrequency,
    Label,
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/shared'

import { type InvoiceFormValues } from './invoice-form'

interface Props {
    control: Control<InvoiceFormValues>
    watch: UseFormWatch<InvoiceFormValues>
}

export const InvoiceFormRecurring = ({ control, watch }: Props) => {
    const isRecurringActive = watch('recurring.isActive')

    const frequencyOptions: InvoiceRecurringFrequency[] = ['MONTHLY', 'QUARTERLY', 'YEARLY']

    const nextRecurringDate = useMemo(() => {
        const frequency = watch('recurring.frequency')

        return getInvoiceNextRecurringDate(frequency!)
    }, [watch('recurring.frequency')])

    return (
        <Card>
            <CardContent className="p-6">
                <FormField
                    control={control}
                    name="recurring.isActive"
                    render={({ field }) => (
                        <>
                            <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                                <FormControl>
                                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                                </FormControl>
                                <div className="space-y-1 leading-none">
                                    <FormLabel>Recurring invoice</FormLabel>
                                    <FormDescription>
                                        You can set this invoice to recur monthly, quarterly or yearly. Invoice will be at the same date
                                        every month, quarter or year at 12:00 PM.
                                    </FormDescription>
                                </div>
                            </FormItem>
                        </>
                    )}
                />

                {isRecurringActive && (
                    <div className="mt-6 space-y-6">
                        <div className="space-y-3 w-full">
                            <Label>Frequency</Label>

                            <FormField
                                control={control}
                                name="recurring.frequency"
                                render={({ field }) => (
                                    <FormItem className="w-full">
                                        <FormControl>
                                            <Select defaultValue={field.value} onValueChange={field.onChange}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder={field.value} />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        {frequencyOptions.map((opt, index) => (
                                                            <SelectItem key={index} value={opt}>
                                                                {_.startCase(opt)}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </FormControl>
                                    </FormItem>
                                )}
                            />

                            <FormDescription>
                                Next recurring date: <span className="font-semibold">{nextRecurringDate}</span>
                            </FormDescription>
                        </div>

                        <div className="space-y-3 w-full">
                            <FormField
                                control={control}
                                name="recurring.isAutoSendEnabled"
                                render={({ field }) => (
                                    <FormItem className="w-full">
                                        <FormControl>
                                            <div className="flex items-center space-x-2">
                                                <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                                                <label
                                                    htmlFor="terms"
                                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                                >
                                                    Automatically send invoice to customer
                                                </label>
                                            </div>
                                        </FormControl>
                                    </FormItem>
                                )}
                            />

                            <FormDescription>
                                If auto send is enabled, the invoice will be sent to the customer automatically on the recurring date.
                            </FormDescription>
                        </div>
                    </div>
                )}
            </CardContent>
        </Card>
    )
}

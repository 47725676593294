import { PostHogProvider as Provider } from 'posthog-js/react'
import { type ReactNode, useEffect } from 'react'

import { useUserData } from '@/features'
import { APP_CONFIG, identifyUser } from '@/shared'

interface Props {
    children: ReactNode
}

export const PostHogProvider = ({ children }: Props) => {
    const user = useUserData()
    const isDevEnv = import.meta.env.VITE_NODE_ENV !== 'production'

    useEffect(() => {
        if (user) {
            identifyUser(user)
        }
    }, [user])

    if (isDevEnv) {
        return <>{children}</>
    }

    return (
        <Provider
            apiKey={APP_CONFIG.POSTHOG_API_KEY as string}
            options={{
                api_host: 'https://eu.i.posthog.com',
            }}
        >
            {children}
        </Provider>
    )
}

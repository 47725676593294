import dayjs from 'dayjs'
import { useState } from 'react'
import { Helmet } from 'react-helmet'

import { DashboardBoundChart, DashboardProfitChart, DashboardYearSelect, useUserData } from '@/features'
import { Page, Typography, useDashboard } from '@/shared'
import { DashboardOverviewCards, DashboardTotalCards } from '@/widgets'

export const Dashboard = () => {
    const [year, setYear] = useState<string>(String(dayjs().year()))
    const user = useUserData()

    const { data, isLoading } = useDashboard(Number(year))

    return (
        <Page>
            <Helmet>
                <title>Dashboard - Insomnee</title>
            </Helmet>

            <div className="space-y-6">
                <div className="flex justify-between items-center">
                    <div className="space-y-1">
                        <Typography variant="h3">Dashboard</Typography>
                        <Typography variant="subtitle">Based on your currency: {user?.business?.currency}</Typography>
                    </div>
                    <DashboardYearSelect year={year} setYear={setYear} />
                </div>

                <DashboardOverviewCards overview={data?.data} isLoading={isLoading} />

                <DashboardProfitChart data={data?.data?.yearTotals} isLoading={isLoading} />

                <DashboardTotalCards overview={data?.data} isLoading={isLoading} />

                {data?.data?.yearTotals.some((d) => d.profit > 0 || d.profitPending > 0 || d.expenses > 0 || d.expensesPending > 0) && (
                    <DashboardBoundChart data={data?.data?.yearTotals} isLoading={isLoading} />
                )}
            </div>
        </Page>
    )
}

import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { InvoiceDeleteModal, InvoiceSendDialog, InvoiceSheet } from '@/features'
import {
    Button,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
    getInvoicePdfUrl,
    type Invoice,
    useToast,
} from '@/shared'

interface Props {
    invoice: Invoice | undefined
}

export const InvoiceManipulate = ({ invoice }: Props) => {
    const navigate = useNavigate()
    const { toast } = useToast()

    const [invoiceClone, setInvoiceClone] = useState<Invoice | undefined>(undefined)
    const [isEditSheetOpen, setEditSheetOpen] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isDownloadLoading, setIsDownloadLoading] = useState(false)
    const [isSendDialogOpen, setIsSendDialogOpen] = useState(false)

    useEffect(() => {
        setInvoiceClone(_.cloneDeep(invoice))
    }, [invoice])

    const handleEditInvoice = () => {
        setInvoiceClone(_.cloneDeep(invoice))
        setEditSheetOpen(true)
    }

    const handleDuplicateInvoice = () => {
        delete invoiceClone?._id
        setEditSheetOpen(true)
    }

    const handleDeleteInvoice = () => {
        setIsDeleteModalOpen(true)
    }

    const handleOpenPaymentPage = () => {
        navigate(`/invoice/${invoiceClone?._id}`)
    }

    const handleDownloadPdf = async () => {
        try {
            if (!invoiceClone?._id) return

            setIsDownloadLoading(true)

            const resp = await getInvoicePdfUrl(invoiceClone._id)
            if (resp.error) throw new Error(resp.error)

            // Open the url in a new tab
            window.open(resp.data, '_blank')
        } catch (error) {
            console.error(error)
            const err = error as Error

            toast({ variant: 'destructive', title: 'Oops! Something went wrong.', description: err.message })
        } finally {
            setIsDownloadLoading(false)
        }
    }

    const handleOpenSendDialog = () => {
        setInvoiceClone(invoice)
        setIsSendDialogOpen(true)
    }

    return (
        <>
            <div className="w-full flex flex-row-reverse">
                <div className="flex items-center gap-3">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" className="w-fit">
                                <EllipsisHorizontalIcon className="w-5 h-5" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuGroup>
                                <DropdownMenuItem onClick={handleEditInvoice}>Edit invoice</DropdownMenuItem>
                                <DropdownMenuItem onClick={handleDuplicateInvoice}>Duplicate</DropdownMenuItem>
                                <DropdownMenuItem onClick={handleOpenPaymentPage}>Preview client&apos;s payment page</DropdownMenuItem>
                                <DropdownMenuItem className="text-destructive" onClick={handleDeleteInvoice}>
                                    Delete
                                </DropdownMenuItem>
                            </DropdownMenuGroup>
                        </DropdownMenuContent>
                    </DropdownMenu>
                    <Button isLoading={isDownloadLoading} variant="outline" onClick={handleDownloadPdf}>
                        Download
                    </Button>
                    <Button onClick={() => handleOpenSendDialog()}>Send invoice</Button>
                </div>
            </div>

            <InvoiceSheet invoice={invoiceClone} open={isEditSheetOpen} onClose={setEditSheetOpen} />
            <InvoiceDeleteModal
                invoiceId={invoiceClone?._id || ''}
                isOpen={isDeleteModalOpen}
                onClose={setIsDeleteModalOpen}
                onSubmit={() => navigate('/invoices')}
            />
            <InvoiceSendDialog invoice={invoice!} isOpen={isSendDialogOpen} onClose={() => setIsSendDialogOpen(false)} />
        </>
    )
}

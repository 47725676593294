import { zodResolver } from '@hookform/resolvers/zod'
import { captureException } from '@sentry/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { z } from 'zod'

import { invoiceModel, useUserData } from '@/features'
import {
    addInvoice,
    Button,
    captureEvent,
    type Customer,
    Form,
    type Invoice,
    Separator,
    updateInvoice,
    useCustomer,
    useToast,
} from '@/shared'

import { InvoiceFormBusinessLogo } from './invoice-form-businessLogo'
import { InvoiceFormCurrency } from './invoice-form-currency'
import { InvoiceFormCustomer } from './invoice-form-customer'
import { InvoiceFormDueDate } from './invoice-form-dueDate'
import { InvoiceFormIssueDate } from './invoice-form-issueDate'
import { InvoiceFormItems } from './invoice-form-items'
import { InvoiceFormNotes } from './invoice-form-notes'
import { InvoiceFormPayments } from './invoice-form-payments'
import { InvoiceFormPrefix } from './invoice-form-prefix'
import { InvoiceFormRecurring } from './invoice-form-recurring'

interface Props {
    invoice?: Invoice
    onSubmit?: () => void
}

const formSchema = z.object({
    customers: z
        .array(
            z.string({
                required_error: 'Customer is required',
                invalid_type_error: 'Invalid customer',
            }),
        )
        .min(1, { message: 'Customer is required' })
        .max(1, { message: 'Only one customer is allowed' }),
    currency: z.string().min(3, { message: 'Currency is required' }),
    prefix: z.string().min(1, { message: 'Prefix is required' }).optional(),
    issuedDate: z
        .date({
            required_error: 'Issued date is required',
            invalid_type_error: 'Invalid date format',
        })
        .optional(),
    dueDate: z
        .date({
            required_error: 'Due date is required',
            invalid_type_error: 'Invalid date format',
        })
        .optional()
        .or(z.literal(undefined)),
    items: z
        .array(
            z.object({
                description: z.string().optional().or(z.literal('')),
                quantity: z.number().min(1, { message: 'Quantity is required' }).optional().or(z.literal(1)),
                amount: z.number().min(1, { message: 'Amount is required' }).optional().or(z.literal(0)),
            }),
        )
        .optional(),
    payment: z.object({ paymentDetails: z.string().optional().or(z.literal('')) }).optional(),
    discount: z.object({ name: z.string().optional().or(z.literal('')), percentage: z.number().optional() }).optional(),
    tax: z
        .object({
            name: z.string().optional().or(z.literal('')),
            percentage: z.number().optional().or(z.literal(0)),
            mode: z.enum(['INCLUSIVE', 'EXCLUSIVE']).optional().or(z.literal('EXCLUSIVE')),
        })
        .optional(),
    settings: z.object({ applyBusinessLogo: z.boolean().optional().or(z.literal(false)) }).optional(),
    notes: z.string().optional().or(z.literal('')),
    recurring: z.object({
        isActive: z.boolean().optional().or(z.literal(false)),
        frequency: z.enum(['MONTHLY', 'QUARTERLY', 'YEARLY']).optional().or(z.literal('MONTHLY')),
        isAutoSendEnabled: z.boolean().optional().or(z.literal(false)),
    }),
})

export type InvoiceFormValues = z.infer<typeof formSchema>

export const InvoiceForm = ({ invoice, onSubmit }: Props) => {
    const dispatch = useDispatch()
    const { customers } = useCustomer('active')
    const { toast } = useToast()
    const user = useUserData()

    const [isLoading, setIsLoading] = useState(false)

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            customers: invoice?.customers?.length ? [(invoice.customers[0] as Customer)._id!] : [],
            currency: invoice?.currency ?? user?.business?.currency ?? 'USD',
            prefix: invoice?.prefix ?? user?.invoice?.prefix ?? dayjs().format('YYYY-MM'),
            issuedDate: dayjs(invoice?.issuedDate || new Date()).toDate(),
            dueDate: invoice?.dueDate ? dayjs(invoice?.dueDate).toDate() : undefined,
            items: invoice?.items ?? [{ description: '', quantity: 1, amount: 0 }],
            payment: invoice?.payment ?? { paymentDetails: '' },
            discount: invoice?.discount ?? { name: 'Discount', percentage: 0 },
            tax: invoice?.tax ?? { name: 'Tax', percentage: 0, mode: 'EXCLUSIVE' },
            settings: invoice?.settings ?? { applyBusinessLogo: false },
            notes: invoice?.notes ?? user?.invoice?.defaultNotes ?? '',
            recurring: {
                isActive: invoice?.recurring?.isActive ?? false,
                frequency: invoice?.recurring?.frequency ?? 'MONTHLY',
                isAutoSendEnabled: invoice?.recurring?.isAutoSendEnabled ?? false,
            },
        },
    })

    const { watch, control, setValue, handleSubmit, register } = form

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'items',
    })

    const onFormSubmit = async (values: z.infer<typeof formSchema>) => {
        try {
            console.log({ values })

            setIsLoading(true)
            const isUpdate = !!invoice?._id

            let resp

            if (isUpdate) {
                resp = await updateInvoice(invoice._id!, { ...values, _id: invoice._id } as Invoice)
            } else {
                resp = await addInvoice({ ...values } as Invoice)
            }

            if ('error' in resp) throw new Error(resp.error)

            if (isUpdate) {
                dispatch(invoiceModel.actions.updateInvoice(resp.data!))
            } else {
                dispatch(invoiceModel.actions.addInvoice(resp.data!))
            }

            captureEvent(`${isUpdate ? 'update' : 'create'}_invoice`, { invoiceId: resp.data?._id })

            onSubmit && onSubmit()
        } catch (error) {
            console.error(error)
            const err = error as Error

            toast({ variant: 'destructive', title: 'Failed to add invoice', description: err.message })

            captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        /**
         * If invoice is provided - set the customer
         */
        if (invoice) {
            const customers = invoice.customers as Customer[]
            const customer = customers[0] as Customer | undefined | null
            if (customer) {
                setValue('customers', [customer._id!])
            }
        }
    }, [invoice])

    useEffect(() => {
        /**
         * On customer change - update the currency based on customer's currency
         * */
        const customer = customers?.find((customer) => customer._id === watch('customers')[0])
        if (customer) setValue('currency', customer.currency!)
    }, [watch('customers')])

    return (
        <>
            <Form {...form}>
                <form className="space-y-7 md:mr-6 pb-6" onSubmit={handleSubmit(onFormSubmit)}>
                    <InvoiceFormPrefix control={control} />

                    <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3">
                        <InvoiceFormCustomer control={control} />
                        <InvoiceFormCurrency control={control} />
                    </div>

                    <div className="flex space-x-3">
                        <InvoiceFormIssueDate control={control} />
                        <InvoiceFormDueDate control={control} />
                    </div>

                    <Separator />

                    <InvoiceFormItems
                        control={control}
                        fields={fields}
                        append={append}
                        remove={remove}
                        register={register}
                        watch={watch}
                        setValue={setValue}
                    />

                    <InvoiceFormPayments control={control} />
                    <InvoiceFormNotes control={control} />
                    <InvoiceFormBusinessLogo control={control} />
                    <InvoiceFormRecurring control={control} watch={watch} />

                    <Button
                        data-umami-event={`${invoice?._id ? 'Update' : 'Create'} invoice button`}
                        isLoading={isLoading}
                        type="submit"
                        size="lg"
                        className="w-full"
                    >
                        {invoice?._id ? 'Update invoice' : 'Create invoice'}
                    </Button>
                </form>
            </Form>
        </>
    )
}

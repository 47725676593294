import { Helmet } from 'react-helmet'

import { AuthLogo } from '@/entities'
import { AuthSignupForm } from '@/features'
import { Page, Separator, Typography } from '@/shared'

export const SignUpPage = () => {
    return (
        <>
            <Helmet>
                <title>Sign Up - Insomnee</title>
            </Helmet>

            <Page className="flex flex-col space-y-6 max-w-md w-full mx-auto">
                <AuthLogo type="form" />

                <div className="space-y-2">
                    <Typography variant="h3">Start your free trial today! 🚀</Typography>
                    <Typography variant="p" className="text-gray-400">
                        All features included. No credit card required
                    </Typography>
                </div>

                <Separator />

                <AuthSignupForm />
            </Page>
        </>
    )
}

import { type Control } from 'react-hook-form'

import { FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@/shared'

import { type TransactionFormValues } from './transaction-form'

interface Props {
    control: Control<TransactionFormValues>
}

export const TransactionFormName = ({ control }: Props) => {
    return (
        <FormField
            control={control}
            name="name"
            render={({ field }) => (
                <FormItem>
                    <FormLabel className="required">Name</FormLabel>
                    <FormControl>
                        <Input placeholder="WinRAR subscription" {...field} />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    )
}

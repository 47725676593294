import { BookOpenIcon, TrashIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@nextui-org/react'
import { captureException } from '@sentry/react'
import { useState } from 'react'
import { type Control, type UseFormSetValue } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { transactionModel, useTransactionMerchants } from '@/features'
import {
    Button,
    cn,
    createMerchant,
    deleteTransactionMerchant,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    Input,
    Label,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
    type TransactionMerchant,
    updateTransactionMerchant,
    useToast,
} from '@/shared'

import { type TransactionFormValues } from './transaction-form'

interface Props {
    control: Control<TransactionFormValues>
    setValue: UseFormSetValue<TransactionFormValues>
}

export const TransactionFormMerchant = ({ control, setValue }: Props) => {
    const dispatch = useDispatch()
    const { toast } = useToast()
    const merchants = useTransactionMerchants()
    const [merchantName, setMerchantName] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false)
    const [merchant, setMerchant] = useState<TransactionMerchant | null>(null)
    const [isCreatePopoverOpen, setIsCreatePopoverOpen] = useState(false)
    const [isUpdatePopoverOpen, setIsUpdatePopoverOpen] = useState(false)

    const handleAddMerchant = async () => {
        try {
            setIsLoading(true)

            const resp = await createMerchant({ name: merchantName })
            if ('error' in resp) throw new Error(resp.error)

            dispatch(transactionModel.actions.addMerchant(resp.data!))
            setMerchantName('')

            setIsCreatePopoverOpen(false)
            setValue('merchant', resp.data!._id)
        } catch (error) {
            console.error(error)
            const err = error as Error

            toast({
                variant: 'destructive',
                title: 'Failed to create merchant',
                description: err.message || 'An error occurred while creating merchant',
            })

            captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleDeleteMerchant = async (merchantId: string) => {
        try {
            const resp = await deleteTransactionMerchant(merchantId)
            if (resp.error) throw new Error(resp.error)

            dispatch(transactionModel.actions.deleteMerchantById(merchantId))
            toast({ title: 'Merchant deleted', description: 'Merchant has been successfully deleted' })

            setValue('merchant', '')
        } catch (error) {
            console.error(error)
            const err = error as Error

            toast({
                variant: 'destructive',
                title: 'Failed to delete merchant',
                description: err.message || 'An error occurred while deleting merchant',
            })

            captureException(error)
        }
    }

    const handleUpdateMerchant = async () => {
        try {
            if (!merchant) return

            setIsLoading(true)

            const resp = await updateTransactionMerchant({ _id: merchant._id, name: merchantName })
            if (resp.error) throw new Error(resp.error)

            setIsUpdatePopoverOpen(false)
            dispatch(transactionModel.actions.updateMerchant(resp.data!))
            toast({ title: 'Merchant deleted', description: 'Merchant has been successfully deleted' })
        } catch (error) {
            console.error(error)
            const err = error as Error

            toast({
                variant: 'destructive',
                title: 'Failed to delete merchant',
                description: err.message || 'An error occurred while deleting merchant',
            })

            captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleMerchantUpdateClick = (merchantId: string) => {
        const merchant = merchants.find((m) => m._id === merchantId)

        if (!merchant) {
            setIsUpdatePopoverOpen(false)
        } else {
            setMerchant(merchant)
            setMerchantName(merchant.name)
        }
    }

    const handleClearMerchant = () => {
        setValue('merchant', '')
    }

    return (
        <FormField
            control={control}
            name="merchant"
            render={({ field }) => (
                <FormItem className="w-full">
                    <div className="flex justify-between">
                        <FormLabel>Merchant</FormLabel>
                        <Popover open={isCreatePopoverOpen} onOpenChange={setIsCreatePopoverOpen}>
                            <PopoverTrigger asChild>
                                <Label className="text-xs text-default-500 cursor-pointer hover:opacity-80">Add merchant</Label>
                            </PopoverTrigger>
                            <PopoverContent className="w-80">
                                <div className="flex flex-col space-y-3">
                                    <Label htmlFor="name">Name</Label>
                                    <Input
                                        className="w-full"
                                        id="name"
                                        placeholder='e.g. "Amazon" or "Walmart"'
                                        value={merchantName}
                                        onChange={(e) => setMerchantName(e.target.value)}
                                    />
                                    <Button isLoading={isLoading} onClick={handleAddMerchant}>
                                        Create
                                    </Button>
                                </div>
                            </PopoverContent>
                        </Popover>
                    </div>
                    <FormControl>
                        <div className="space-y-3">
                            <div className="flex items-center space-x-2">
                                <Select value={field.value || ''} onValueChange={field.onChange}>
                                    <SelectTrigger>
                                        <SelectValue placeholder={field.value} />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {!merchants.length && (
                                                <div className="p-2 text-center text-xs text-heading">Create a merchant to get started</div>
                                            )}

                                            {merchants.map((merchant, index) => (
                                                <SelectItem key={index} value={merchant._id!}>
                                                    {merchant.name}
                                                </SelectItem>
                                            ))}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>

                                <Tooltip content="Clear merchant" isDisabled={!field.value}>
                                    <XCircleIcon
                                        className={cn('h-5 w-5', {
                                            'opacity-80': !!field.value,
                                            'cursor-pointer': !!field.value,
                                        })}
                                        onClick={() => field.value && handleClearMerchant()}
                                    />
                                </Tooltip>
                            </div>

                            {field.value && (
                                <div className="flex items-center justify-between gap-6">
                                    <Popover open={isUpdatePopoverOpen} onOpenChange={setIsUpdatePopoverOpen}>
                                        <PopoverTrigger asChild>
                                            <Label
                                                className="text-xs text-default-500 cursor-pointer hover:opacity-80 space-x-1 flex items-center"
                                                onClick={() => handleMerchantUpdateClick(field.value!)}
                                            >
                                                <BookOpenIcon className="w-4 h-4" />
                                                <span>Update merchant</span>
                                            </Label>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-80">
                                            <div className="flex flex-col space-y-3">
                                                <Label htmlFor="name">Name</Label>
                                                <Input
                                                    className="w-full"
                                                    id="name"
                                                    placeholder='e.g. "Amazon" or "Walmart"'
                                                    value={merchantName}
                                                    onChange={(e) => setMerchantName(e.target.value)}
                                                />
                                                <Button isLoading={isLoading} onClick={handleUpdateMerchant}>
                                                    Update
                                                </Button>
                                            </div>
                                        </PopoverContent>
                                    </Popover>

                                    <Label
                                        className="text-xs text-default-500 cursor-pointer hover:opacity-80 space-x-1 flex items-center"
                                        onClick={() => handleDeleteMerchant(field.value!)}
                                    >
                                        <TrashIcon className="w-4 h-4" />
                                        <span>Delete merchant</span>
                                    </Label>
                                </div>
                            )}
                        </div>
                    </FormControl>
                </FormItem>
            )}
        />
    )
}

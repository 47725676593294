import { type Customer, request } from '@/shared'

export const getCustomerCount = () => request<number>('get', '/customer/count')

export const getCustomers = (status: Customer['status'] | 'all') => request<Customer[]>('get', `/customer?status=${status}`)

export const addCustomer = (customer: Partial<Customer>) => request<Customer>('post', '/customer', customer)

export const updateCustomerStatus = (id: string, status: Customer['status']) =>
    request<Customer>('post', `/customer/${id}/status`, { status })

export const updateCustomer = (id: string, customer: Partial<Customer>) => request<Customer>('put', `/customer/${id}`, customer)

import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { getPricing, type Pricing } from '@/shared'

export const useStripe = () => {
    const { data, isLoading } = useQuery({
        queryKey: 'pricing',
        queryFn: getPricing,
        cacheTime: 1000 * 60 * 60 * 12,
        staleTime: 1000 * 60 * 60 * 12,
        select: (resp) => resp.data,
    })

    const pricingArr: Pricing[] = useMemo(() => {
        return [
            {
                name: 'Monthly',
                pricePerMonth: data?.monthlyPricePerMonth || 0,
                pricePerYear: data?.pricePerYear || 0,
                billingCycle: 'monthly',
                disconnectPercentage: 0,
                discountPrice: 0,
                features: data?.features || [],
                discountPercentage: 0,
            },
            {
                name: 'Yearly',
                pricePerMonth: data?.yearlyPricePerMonth || 0,
                pricePerYear: data?.pricePerYear || 0,
                billingCycle: 'yearly',
                discountPercentage: data?.yearlyDiscountPercentage || 0,
                discountPrice: data?.yearlySavings || 0,
                features: data?.features || [],
            },
        ]
    }, [data])

    return {
        pricing: data,
        pricingArr,
        isLoading,
    }
}

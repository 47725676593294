import { ArrowsUpDownIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { Tab, Tabs, Tooltip, User } from '@nextui-org/react'
import { type ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, type SortingState, useReactTable } from '@tanstack/react-table'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { CsvManipulate, customerModel, CustomerSheet } from '@/features'
import {
    Button,
    captureEvent,
    type Customer,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
    updateCustomerStatus,
} from '@/shared'

import { CustomerPreviewSheet } from './customer-preview-sheet'

type Status = Customer['status']

interface Props {
    customers: Customer[]
}

export const CustomersTable = ({ customers }: Props) => {
    const dispatch = useDispatch()

    const [status, setStatus] = useState<Status>('active')
    const [sorting, setSorting] = useState<SortingState>([])
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null)
    const [isPreviewSheetOpen, setIsPreviewSheetOpen] = useState(false)
    const [isUpdateSheetOpen, setIsUpdateSheetOpen] = useState(false)

    const filteredCustomers = useMemo(() => {
        captureEvent('customers_table_filtered', { status })
        return customers.filter((customer) => customer.status === status)
    }, [customers, status])

    const handleUpdateCustomerStatus = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, customer: Customer) => {
        e.stopPropagation()

        try {
            const status = customer.status === 'active' ? 'archived' : 'active'

            const resp = await updateCustomerStatus(customer._id!, status)
            if ('error' in resp) throw new Error(resp.error)

            dispatch(customerModel.actions.updateCustomer({ _id: customer._id, status }))
        } catch (error) {
            console.error(error)
        }
    }

    const handleRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, customer: Customer) => {
        e.stopPropagation()
        setSelectedCustomer(customer)
        setIsPreviewSheetOpen(true)
    }

    const handleCustomerUpdate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, customer: Customer) => {
        e.stopPropagation()
        setSelectedCustomer(customer)
        setIsUpdateSheetOpen(true)
    }

    const columns: Array<ColumnDef<Customer>> = [
        {
            accessorKey: 'customerCard',
            header: () => <div className="w-[180px]">Customer</div>,
            cell: ({ row }) => {
                const customer = row.original

                return (
                    <User
                        avatarProps={{
                            src: customer.avatar,
                            size: 'sm',
                            classNames: {
                                base: 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white cursor-pointer',
                                icon: 'text-white/80 w-7',
                            },
                        }}
                        name={customer.name}
                        description={customer.businessName}
                    />
                )
            },
        },
        {
            accessorKey: 'email',
            header: ({ column }) => (
                <div
                    className="flex items-center cursor-pointer w-fit"
                    onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
                >
                    Email
                    <ArrowsUpDownIcon className="ml-2 h-4 w-4" />
                </div>
            ),
            cell: ({ row }) => {
                const customer = row.original

                return (
                    <div className="w-fit">
                        <Tooltip content={customer.email}>{_.truncate(customer.email, { length: 25 })}</Tooltip>
                    </div>
                )
            },
        },
        {
            accessorKey: 'profit',
            header: () => <div className="text-right w-[110px]">Profit</div>,
            cell: ({ row }) => {
                const customer = row.original

                return <div className="text-right w-[110px] font-medium">{formatter.format(customer.revenue || 0)}</div>
            },
        },
        {
            accessorKey: 'pendingProfit',
            header: () => <div className="text-right w-[110px]">Pending profit</div>,
            cell: ({ row }) => {
                const customer = row.original

                return <div className="text-right w-[110px] font-medium">{formatter.format(customer.pendingRevenue || 0)}</div>
            },
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                const customer = row.original

                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button data-umami-event="Customer manipulation button" variant="ghost" className="p-0 w-full">
                                <span className="sr-only">Open menu</span>
                                <EllipsisHorizontalIcon className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end" className="min-w-60">
                            <DropdownMenuItem onClick={(e) => handleUpdateCustomerStatus(e, customer)}>
                                {customer.status === 'active' ? 'Archive' : 'Restore'}
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={(e) => handleCustomerUpdate(e, customer)}>Edit</DropdownMenuItem>
                            {customer.status === 'active' && <DropdownMenuItem>Create an invoice</DropdownMenuItem>}
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    const table = useReactTable({
        data: filteredCustomers,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
    })

    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

    return (
        <div className="space-y-5">
            <Tabs
                variant="light"
                aria-label="Active"
                radius="sm"
                selectedKey={status}
                onSelectionChange={(value) => setStatus(value as Status)}
            >
                <Tab key="active" title="Active" />
                <Tab key="archived" title="Archived" />
            </Tabs>

            <Table>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead key={header.id}>
                                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                    </TableHead>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                            <TableRow
                                key={row.id}
                                className="cursor-pointer hover:bg-gray-50"
                                onClick={(e) => handleRowClick(e, row.original)}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length} className="h-24 text-center">
                                No results.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            {!!filteredCustomers.length && (
                <div className="flex items-center justify-end space-x-5">
                    <CsvManipulate
                        actions={['export']}
                        fileName={`customers-${status}.csv`}
                        data={[
                            [
                                'Name',
                                'Email',
                                'Currency',
                                'Profit',
                                'Pending profit',
                                'Business name',
                                'Phone number',
                                'Country',
                                'City',
                                'Address',
                                'Vat ID',
                                'Invoices',
                                'Creation date',
                            ],
                            ...filteredCustomers.map((customer) => [
                                customer.name || '-',
                                customer.email || '-',
                                customer.currency || '-',
                                new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(customer.revenue || 0),
                                new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(customer.pendingRevenue || 0),
                                customer.businessName || '-',
                                customer.phoneNumber || '-',
                                customer.country || '-',
                                customer.city || '-',
                                customer.address || '-',
                                customer.vatId || '-',
                                customer.invoices.map((invoice) => `${window.location.origin}/invoice/${invoice._id}`).join(', ') || '-',
                                customer.metadata.createdAt ? dayjs(customer.metadata.createdAt).format('MMM D, YYYY') : '-',
                            ]),
                        ]}
                    />
                </div>
            )}

            <CustomerPreviewSheet open={isPreviewSheetOpen} customer={selectedCustomer!} onClose={setIsPreviewSheetOpen} />
            <CustomerSheet customer={selectedCustomer!} open={isUpdateSheetOpen} onClose={setIsUpdateSheetOpen} />
        </div>
    )
}

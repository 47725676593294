import { User } from '@nextui-org/react'
import { useState } from 'react'
import { type Control } from 'react-hook-form'

import { CustomerExpressDialog } from '@/features'
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    Label,
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
    useCustomer,
} from '@/shared'

import { type InvoiceFormValues } from './invoice-form'

interface Props {
    control: Control<InvoiceFormValues>
}

export const InvoiceFormCustomer = ({ control }: Props) => {
    const { customers } = useCustomer('active')

    const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false)

    return (
        <>
            <FormField
                control={control}
                name="customers"
                render={({ field }) => (
                    <FormItem className="w-full">
                        <div className="flex justify-between">
                            <FormLabel className="required">Customer</FormLabel>
                            <Label
                                className="text-xs text-default-500 cursor-pointer hover:opacity-80"
                                onClick={() => setIsCustomerDialogOpen(true)}
                            >
                                Add customer
                            </Label>
                        </div>
                        <FormControl>
                            <Select disabled={!customers.length} value={field.value[0]} onValueChange={(value) => field.onChange([value])}>
                                <SelectTrigger>
                                    <SelectValue placeholder={customers.length ? 'Choose customer' : 'Create your first customer'} />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {customers?.map((customer, index) => (
                                            <SelectItem key={index} value={customer._id!} className="customer-select-item">
                                                <User
                                                    avatarProps={{
                                                        src: customer.avatar,
                                                        size: 'sm',
                                                        classNames: {
                                                            base: 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white cursor-pointer',
                                                            icon: 'text-white/80 w-7',
                                                        },
                                                    }}
                                                    name={customer.name}
                                                    description={customer.email}
                                                />
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <CustomerExpressDialog open={isCustomerDialogOpen} onClose={setIsCustomerDialogOpen} />
        </>
    )
}

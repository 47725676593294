import { NextUIProvider } from '@nextui-org/react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { Toaster } from '@/shared'

import { AuthProvider, PostHogProvider, QueryProvider, ReduxProvider, RouterProvider } from './providers'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.guess()

const App = () => {
    return (
        <ReduxProvider>
            <NextUIProvider>
                <QueryProvider>
                    <AuthProvider>
                        <PostHogProvider>
                            <RouterProvider />

                            <Toaster />
                        </PostHogProvider>
                    </AuthProvider>
                </QueryProvider>
            </NextUIProvider>
        </ReduxProvider>
    )
}

export default App

import { request } from '@/shared'

interface StripePricing {
    monthlyPricePerMonth: number
    yearlyPricePerMonth: number
    pricePerYear: number
    yearlyDiscountPercentage: number
    yearlySavings: number
    features: string[]
}

export const getPricing = () => request<StripePricing>('get', '/stripe/pricing')

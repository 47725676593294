import { type DashboardOverview } from '@/shared'

export const emptyMock: DashboardOverview['yearTotals'] = [
    {
        totalExpenses: 550,
        expensesTaxes: 500,
        year: 2021,
        monthNumber: 1,
        monthFullName: 'January',
        monthShortName: 'Jan',
        profit: 550, // Random profit value for January
        profitPending: 100,
        expenses: 500,
        expensesPending: 50,
        taxesPaid: 100,
        taxesPending: 10,
        discount: 0,
        discountPending: 0,
    },
    {
        totalExpenses: 250,
        expensesTaxes: 200,
        year: 2021,
        monthNumber: 2,
        monthFullName: 'February',
        monthShortName: 'Feb',
        profit: 550, // Random profit value for February
        profitPending: 200,
        expenses: 1000,
        expensesPending: 100,
        taxesPaid: 200,
        taxesPending: 20,
        discount: 0,
        discountPending: 0,
    },
    {
        totalExpenses: 1550,
        expensesTaxes: 1500,
        year: 2021,
        monthNumber: 3,
        monthFullName: 'March',
        monthShortName: 'Mar',
        profit: 550, // Random profit value for March
        profitPending: 300,
        expenses: 1500,
        expensesPending: 150,
        taxesPaid: 300,
        taxesPending: 30,
        discount: 0,
        discountPending: 0,
    },
    {
        totalExpenses: 550,
        expensesTaxes: 500,
        year: 2021,
        monthNumber: 4,
        monthFullName: 'April',
        monthShortName: 'Apr',
        profit: 550, // Random profit value for April
        profitPending: 100,
        expenses: 500,
        expensesPending: 50,
        taxesPaid: 100,
        taxesPending: 10,
        discount: 0,
        discountPending: 0,
    },
    {
        totalExpenses: 550,
        expensesTaxes: 500,
        year: 2021,
        monthNumber: 5,
        monthFullName: 'May',
        monthShortName: 'May',
        profit: 550, // Random profit value for May
        profitPending: 100,
        expenses: 500,
        expensesPending: 50,
        taxesPaid: 100,
        taxesPending: 10,
        discount: 0,
        discountPending: 0,
    },
    {
        totalExpenses: 550,
        expensesTaxes: 500,
        year: 2021,
        monthNumber: 6,
        monthFullName: 'June',
        monthShortName: 'Jun',
        profit: 550, // Random profit value for June
        profitPending: 100,
        expenses: 500,
        expensesPending: 50,
        taxesPaid: 100,
        taxesPending: 10,
        discount: 0,
        discountPending: 0,
    },
    {
        totalExpenses: 550,
        expensesTaxes: 500,
        year: 2021,
        monthNumber: 7,
        monthFullName: 'July',
        monthShortName: 'Jul',
        profit: 550, // Random profit value for July
        profitPending: 100,
        expenses: 500,
        expensesPending: 50,
        taxesPaid: 100,
        taxesPending: 10,
        discount: 0,
        discountPending: 0,
    },
    {
        totalExpenses: 550,
        expensesTaxes: 500,
        year: 2021,
        monthNumber: 8,
        monthFullName: 'August',
        monthShortName: 'Aug',
        profit: 550, // Random profit value for August
        profitPending: 100,
        expenses: 500,
        expensesPending: 50,
        taxesPaid: 100,
        taxesPending: 10,
        discount: 0,
        discountPending: 0,
    },
    {
        totalExpenses: 550,
        expensesTaxes: 500,
        year: 2021,
        monthNumber: 9,
        monthFullName: 'September',
        monthShortName: 'Sep',
        profit: 550, // Random profit value for September
        profitPending: 100,
        expenses: 500,
        expensesPending: 50,
        taxesPaid: 100,
        taxesPending: 10,
        discount: 0,
        discountPending: 0,
    },
    {
        totalExpenses: 550,
        expensesTaxes: 500,
        year: 2021,
        monthNumber: 10,
        monthFullName: 'October',
        monthShortName: 'Oct',
        profit: 550, // Random profit value for October
        profitPending: 100,
        expenses: 500,
        expensesPending: 50,
        taxesPaid: 100,
        taxesPending: 10,
        discount: 0,
        discountPending: 0,
    },
    {
        totalExpenses: 550,
        expensesTaxes: 500,
        year: 2021,
        monthNumber: 11,
        monthFullName: 'November',
        monthShortName: 'Nov',
        profit: 550, // Random profit value for November
        profitPending: 100,
        expenses: 500,
        expensesPending: 50,
        taxesPaid: 100,
        taxesPending: 10,
        discount: 0,
        discountPending: 0,
    },
    {
        totalExpenses: 550,
        expensesTaxes: 500,
        year: 2021,
        monthNumber: 12,
        monthFullName: 'December',
        monthShortName: 'Dec',
        profit: 550, // Random profit value for December
        profitPending: 100,
        expenses: 500,
        expensesPending: 50,
        taxesPaid: 100,
        taxesPending: 10,
        discount: 0,
        discountPending: 0,
    },
]

import { createContext, useContext } from 'react'
import { QueryClient } from 'react-query'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 0,
        },
    },
})

const QueryContext = createContext<{ queryClient: QueryClient }>({
    queryClient,
})

export const useQueryContext = () => useContext(QueryContext)

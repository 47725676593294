import { zodResolver } from '@hookform/resolvers/zod'
import { captureException } from '@sentry/react'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { useUserData } from '@/features/user'
import {
    Button,
    type Customer,
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    type Invoice,
    sendInvoiceToEmail,
    Textarea,
    useToast,
} from '@/shared'

interface Props {
    isOpen: boolean
    invoice: Invoice
    onClose: (isOpen: boolean) => void
}

export const InvoiceSendDialog = ({ invoice, isOpen, onClose }: Props) => {
    const user = useUserData()
    const { toast } = useToast()

    const [isLoading, setIsLoading] = useState(false)

    const customer = useMemo(() => {
        return invoice?.customers[0] as Customer | null | undefined
    }, [invoice])

    const defaultMailMessage = useMemo(() => {
        const message =
            user?.invoice?.defaultEmailText ||
            `Hi ${customer?.name}.\n\nHere's a link to view and pay the invoice we discussed.\nPlease email me at ${user?.email} with any questions.\n\nThanks`

        return message.replace(/{{customerName}}/g, customer?.name || '')
    }, [user?.invoice?.defaultEmailText])

    const formSchema = z.object({
        message: z.string().max(255, { message: 'Message is too long' }).optional(),
    })

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            message: defaultMailMessage,
        },
    })

    const onFormSubmit = async (values: z.infer<typeof formSchema>) => {
        try {
            setIsLoading(true)

            const resp = await sendInvoiceToEmail(invoice._id!, values.message)
            if (resp.error) throw new Error(resp.error)

            toast({ title: 'Invoice sent', description: 'The invoice has been sent to the customer' })
            onClose(false)
        } catch (error) {
            console.error(error)
            const err = error as Error

            toast({
                variant: 'destructive',
                title: 'Error sending invoice',
                description: err.message || 'An error occurred while sending the invoice',
            })

            captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-md space-y-5">
                <DialogHeader>
                    <DialogTitle className="text-left">Send invoice to customer</DialogTitle>
                </DialogHeader>
                <div className="flex items-center space-x-2 w-full">
                    <Form {...form}>
                        <form className="space-y-5 w-full" onSubmit={form.handleSubmit(onFormSubmit)}>
                            <FormField
                                control={form.control}
                                name="message"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Mail message</FormLabel>
                                        <FormControl>
                                            <Textarea placeholder="Your message here" rows={8} {...field} className="resize-none" />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormDescription>
                                The link to the invoice will be automatically disabled for the client after{' '}
                                <span className="font-semibold">
                                    36 hours ({dayjs().add(36, 'hour').format('MMM D, YYYY [at] h:mm A')})
                                </span>
                                . To enable the link again, you need to resend the invoice.
                            </FormDescription>

                            <FormDescription>
                                The attached <span className="font-semibold">{`invoice-${invoice.prefix}.pdf`}</span> will be sent to the
                                customer along with the email and will have no expiry.
                            </FormDescription>

                            <Button isLoading={isLoading} type="submit" className="w-full">
                                Send invoice
                            </Button>
                        </form>
                    </Form>
                </div>
                <DialogFooter className="sm:justify-start"></DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

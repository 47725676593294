import LogoImg from '@/assets/images/logo-full.svg'

export const PortalInvoiceLogo = () => {
    return (
        <div
            className="flex space-x-2 text-xs text-subtitle justify-center !mt-6 cursor-pointer"
            onClick={() => window.open('https://insomnee.com', '_blank')}
        >
            <span>Powered by</span>
            <img src={LogoImg} alt="logo" className="w-50 h-4" />
        </div>
    )
}

import { useMemo } from 'react'
import { useQueries } from 'react-query'
import { useDispatch } from 'react-redux'

import { invoiceModel, useInvoicesCount, useInvoicesData } from '@/features'
import { captureEvent, getInvoices, getInvoicesCount, type Invoice, type TypedResponse } from '@/shared'

export const useInvoice = () => {
    const dispatch = useDispatch()
    const invoicesData = useInvoicesData()
    const countData = useInvoicesCount()

    const data = useQueries([
        {
            queryKey: ['invoices'],
            queryFn: getInvoices,
            onSuccess: (resp: TypedResponse<Invoice[]>) => {
                dispatch(invoiceModel.actions.setInvoices(resp.data || []))
                captureEvent('invoices_data_fetch', { count: resp.data?.length })
            },
        },
        {
            queryKey: ['invoices-count'],
            queryFn: getInvoicesCount,
            onSuccess: (resp: TypedResponse<number>) => {
                dispatch(invoiceModel.actions.setInvoicesCount(resp.data || 0))
                captureEvent('invoices_count_fetch', { count: resp.data })
            },
        },
    ])

    const invoices = useMemo(() => invoicesData, [invoicesData, countData])
    const count = useMemo(() => countData, [countData, invoicesData])

    return {
        invoices,
        count,
        isLoading: data.some((query) => query.isLoading),
    }
}

import { type Invoice, request } from '@/shared'

export const getInvoices = () => request<Invoice[]>('get', '/invoice')

export const getInvoicesCount = () => request<number>('get', '/invoice/count')

export const addInvoice = (invoice: Invoice) => request<Invoice>('post', '/invoice', invoice)

export const updateInvoice = (id: string, invoice: Invoice) => request<Invoice>('post', `/invoice/${id}`, invoice)

export const updateInvoiceState = (id: string, state: Invoice['state']) => request<Invoice>('post', `/invoice/${id}/state`, { state })

export const removeInvoice = (id: string) => request<Invoice>('delete', `/invoice/${id}`)

export const getInvoiceById = (id: string) => request<Invoice>('get', `/invoice/${id}`)

export const sendInvoiceToEmail = (invoiceId: string, message?: string) =>
    request<Invoice>('post', `/invoice/${invoiceId}/send`, { message })

import { combineReducers } from '@reduxjs/toolkit'

import { customerReducer, invoiceReducer, transactionReducer, userReducer } from '@/features'

export const rootReducer = combineReducers({
    customer: customerReducer,
    user: userReducer,
    invoice: invoiceReducer,
    transaction: transactionReducer,
})

export type RootState = ReturnType<typeof rootReducer>

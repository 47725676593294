import { zodResolver } from '@hookform/resolvers/zod'
import { captureException } from '@sentry/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import {
    Button,
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    sendFeedback,
    Textarea,
    toast,
    ToggleGroup,
    ToggleGroupItem,
} from '@/shared'

const formSchema = z.object({
    rate: z
        .number({ required_error: 'Rating is required', invalid_type_error: 'Rating must be a number' })
        .int({ message: 'Rating must be an integer' })
        .min(1, { message: 'Rating must be at least 1' })
        .max(3, { message: 'Rating must be at most 3' }),

    comment: z
        .string({ required_error: 'Comment is required', invalid_type_error: 'Comment must be a string' })
        .max(150, { message: 'Comment must be at most 150 characters' })
        .optional(),
})

type FormValues = z.infer<typeof formSchema>

interface Props {
    handleFormSubmit: () => void
}

export const FeedbackForm = ({ handleFormSubmit }: Props) => {
    const [isLoading, setIsLoading] = useState(false)

    const ratingEmojis = ['😠', '😐', '😍']

    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: { rate: 3, comment: '' },
    })

    const onFormSubmit = async (values: FormValues) => {
        try {
            setIsLoading(true)

            const resp = await sendFeedback(values.rate, values.comment)
            if (resp.error) throw new Error(resp.error)

            handleFormSubmit()
        } catch (error) {
            console.error(error)

            const err = (error as Error) || { message: 'Please try again later' }
            toast({ variant: 'destructive', title: 'Failed to leave feedback', description: err.message })

            captureException(err)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Form {...form}>
            <form className="space-y-5" onSubmit={form.handleSubmit(onFormSubmit)}>
                <ToggleGroup type="single" className="justify-start" value={String(form.getValues('rate') - 1)}>
                    {ratingEmojis.map((emoji, index) => (
                        <ToggleGroupItem
                            key={index}
                            value={String(index)}
                            aria-label="toggle emoji"
                            onClick={() => form.setValue('rate', index + 1)}
                        >
                            <span className="text-3xl">{emoji}</span>
                        </ToggleGroupItem>
                    ))}
                </ToggleGroup>

                <FormField
                    control={form.control}
                    name="comment"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Notes</FormLabel>
                            <FormControl>
                                <Textarea {...field} rows={7} placeholder="Some words about your experience..." className="resize-none" />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <Button isLoading={isLoading} variant="outline" type="submit">
                    Send feedback
                </Button>
            </form>
        </Form>
    )
}

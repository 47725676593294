import { createElement } from 'react'

import { cn } from '@/shared'

type TypographyVariant = 'h1' | 'h2' | 'h3' | 'p' | 'subtitle'

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
    variant: TypographyVariant
    children: React.ReactNode
    className?: string
}

const typographyClasses: Record<TypographyVariant, string> = {
    h1: 'text-4xl font-extrabold tracking-tight lg:text-5xl leading-7 transition-colors tracking-normal',
    h2: 'text-3xl font-semibold tracking-tight transition-colors leading-10 tracking-normal',
    h3: 'text-lg lg:text-2xl font-semibold tracking-tight leading-10 transition-colors tracking-normal',
    p: 'text-sm lg:text-base leading-7 leading-normal transition-colors tracking-normal',
    subtitle: 'md:text-sm font-normal text-subtitle tracking-tight md:leading-7 tracking-normal transition-colors text-xs',
}

export const Typography = ({ variant, children, className, ...props }: Props) => {
    const typographyClassName = cn(typographyClasses[variant], className)

    if (variant === 'subtitle') {
        return (
            <p {...props} className={typographyClassName}>
                {children}
            </p>
        )
    }

    return createElement(variant, { ...props, className: typographyClassName }, children)
}

import { useSignIn } from '@clerk/clerk-react'
import { ArrowLongRightIcon } from '@heroicons/react/20/solid'
import { zodResolver } from '@hookform/resolvers/zod'
import { captureException } from '@sentry/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { PatternFormat } from 'react-number-format'
import { Link } from 'react-router-dom'
import { z } from 'zod'

import {
    Button,
    captureEvent,
    Form,
    formatClerkError,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    Input,
    Separator,
    useToast,
} from '@/shared'

export const AuthForgotForm = () => {
    const { toast } = useToast()
    const { signIn, setActive } = useSignIn()

    const [isLoading, setIsLoading] = useState(false)
    const [isVerifyOtp, setIsVerifyOtp] = useState(false)

    const formSchema = z.object({
        email: z.string().email({ message: 'Invalid email address' }).optional().or(z.literal('')),
        otp: z
            .string()
            .min(6, { message: 'OTP must be at least 6 characters long' })
            .max(6, { message: 'OTP must be at most 6 characters long' })
            .optional()
            .or(z.literal('')),
        password: z.string().min(8, { message: 'Password must be at least 8 characters long' }).optional().or(z.literal('')),
    })

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: { email: '', otp: '' },
    })

    const isBtnDisabled = isVerifyOtp
        ? (form.getValues('otp')?.length ?? 0) < 6 || (form.getValues('password')?.length ?? 0) < 8
        : form.getValues('email') === ''

    const onFormSubmit = async (values: z.infer<typeof formSchema>) => {
        const { email, otp, password } = values

        if (!isVerifyOtp && email !== undefined && email.length > 0) {
            handleSendForgotRequest(email)
        }

        if (otp !== undefined && otp?.length === 6 && password !== undefined && password?.length >= 8) {
            handleSubmitOtp(otp, password)
        }
    }

    const handleSendForgotRequest = async (email: string) => {
        try {
            setIsVerifyOtp(false)
            setIsLoading(true)

            await signIn?.create({
                strategy: 'reset_password_email_code',
                identifier: email,
            })

            setIsVerifyOtp(true)
        } catch (error) {
            toast({
                variant: 'destructive',
                title: 'Failed to send forgot password request',
                description: formatClerkError(error),
            })

            captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleSubmitOtp = async (otp: string, password: string) => {
        try {
            setIsLoading(true)

            const resp = await signIn?.attemptFirstFactor({
                strategy: 'reset_password_email_code',
                code: otp,
                password,
            })

            if (resp?.status === 'complete' && setActive) {
                await setActive({ session: resp.createdSessionId })

                captureEvent('password_reset', { email: form.getValues('email') })
                window.location.reload()
            }
        } catch (error) {
            toast({
                variant: 'destructive',
                title: 'Failed to verify OTP',
                description: formatClerkError(error),
            })

            captureException(formatClerkError(error))
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Form {...form}>
            <form className="space-y-5" onSubmit={form.handleSubmit(onFormSubmit)}>
                <FormField
                    disabled={isLoading || isVerifyOtp}
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                                <Input placeholder="your@email.com" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                {isVerifyOtp && (
                    <>
                        <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Password</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Your new password" type="password" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="otp"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>One-Time Password</FormLabel>
                                    <FormControl>
                                        <PatternFormat
                                            valueIsNumericString
                                            format="### ###"
                                            value={field.value}
                                            customInput={Input}
                                            onValueChange={(value) => field.onChange(value.value)}
                                        />
                                    </FormControl>
                                    <FormDescription>Please enter the one-time password sent to your email.</FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </>
                )}

                <Button isLoading={isLoading} disabled={isBtnDisabled} className="space-x-2 w-full" type="submit" size="lg">
                    <ArrowLongRightIcon className="w-3 h-3 text-white" />
                    <span className="text-base">Reset Password</span>
                </Button>

                <Separator />

                <div className="text-center">
                    <Link to="/login" className="text-primary text-sm font-semibold">
                        Back to login
                    </Link>
                </div>
            </form>
        </Form>
    )
}

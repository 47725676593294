import { request, type User } from '@/shared'

export const getMe = () => request<User>('get', '/user/me')

export const getUserById = (id: string) => request<User>('get', `/user/${id}`)

export const updateUserBusiness = (data: User['business']) => request<User>('put', '/user/business', data)

export const updateUserInvoice = (data: User['invoice']) => request<User>('put', '/user/invoice', data)

export const deleteUser = () => request('delete', '/user')

import { useMemo } from 'react'
import { useQueries } from 'react-query'
import { useDispatch } from 'react-redux'

import { customerModel, useCustomersCount, useCustomersData } from '@/features'
import { captureEvent, type Customer, getCustomerCount, getCustomers, type TypedResponse } from '@/shared'

export const useCustomer = (status: Customer['status'] | 'all' = 'all') => {
    const dispatch = useDispatch()
    const customersData = useCustomersData()
    const customersCountData = useCustomersCount()

    const data = useQueries([
        {
            queryKey: ['customers-count'],
            queryFn: getCustomerCount,
            onSuccess: (resp: TypedResponse<number>) => {
                dispatch(customerModel.actions.setCount(resp.data ?? 0))
                captureEvent('customer_count', { count: resp.data })
            },
            staleTime: 1000 * 60 * 60, // 1 hour
        },
        {
            queryKey: ['customers', status],
            queryFn: () => getCustomers(status),
            onSuccess: (resp: TypedResponse<Customer[]>) => {
                dispatch(customerModel.actions.setCustomers(resp.data ?? []))
                captureEvent('customers', { count: resp.data?.length })
            },
            staleTime: 1000 * 60 * 60, // 1 hour
        },
    ])

    const customers = useMemo(() => {
        return customersData.filter((customer) => status === 'all' || customer.status === status)
    }, [customersData, status])

    const count = useMemo(() => {
        return customersCountData
    }, [customersCountData, customersData])

    return {
        count,
        customers,
        isLoading: data.some((query) => query.isLoading),
    }
}

import { useEffect, useState } from 'react'

import { FeedbackForm } from '@/features'
import { Button, Popover, PopoverContent, PopoverTrigger, Typography, useToast } from '@/shared'

export const Feedback = () => {
    const { toast } = useToast()
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const handleFormSubmit = () => {
        setIsSuccess(true)
        setIsPopoverOpen(false)
    }

    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                toast({ title: 'Feedback submitted', description: 'Thank you for sharing your thoughts 😊' })

                setIsSuccess(false)
            }, 200)
        }
    }, [isSuccess])

    return (
        <div className="fixed bottom-6 right-6">
            <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
                <PopoverTrigger asChild>
                    <Button variant="outline">Feedback?</Button>
                </PopoverTrigger>
                <PopoverContent className=" max-w-80 md:max-w-96 w-full space-y-6">
                    <div className="space-y-1">
                        <Typography variant="p" className="font-medium">
                            Share your feedback
                        </Typography>
                        <p className="text-sm text-subtitle leading-normal">
                            Your feedback is important to us. Please take a moment to share your thoughts.
                        </p>
                    </div>

                    <FeedbackForm handleFormSubmit={handleFormSubmit} />
                </PopoverContent>
            </Popover>
        </div>
    )
}

// eslint-disable-next-line import/no-unresolved
import '@/app/sentry'
import '@/assets/styles/global.scss'
import '@/assets/styles/index.scss'
import 'normalize.css'

import { createRoot } from 'react-dom/client'

import App from './app'

createRoot(document.getElementById('root')!).render(<App />)

import { Avatar } from '@nextui-org/react'
import { type Control } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { useUserData } from '@/features'
import { Card, CardContent, Checkbox, FormControl, FormDescription, FormField, FormItem, FormLabel } from '@/shared'

import { type InvoiceFormValues } from './invoice-form'

interface Props {
    control: Control<InvoiceFormValues>
}

export const InvoiceFormBusinessLogo = ({ control }: Props) => {
    const user = useUserData()

    return (
        <Card>
            <CardContent className="p-6">
                <FormField
                    control={control}
                    name="settings.applyBusinessLogo"
                    render={({ field }) => (
                        <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                            <FormControl>
                                <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                            </FormControl>
                            <div className="space-y-1 leading-none flex gap-6 items-center">
                                <div className="space-y-1">
                                    <FormLabel>Apply business logo to invoice</FormLabel>
                                    <FormDescription>
                                        Your business logo will be applied to the invoice. You can upload your logo in the{' '}
                                        <Link to="/settings/business" className="text-primary text-underline">
                                            settings
                                        </Link>
                                        .
                                    </FormDescription>
                                </div>

                                <div>
                                    <Avatar
                                        showFallback
                                        name={user?.fullName || undefined}
                                        size="md"
                                        radius="sm"
                                        src={user?.business?.image}
                                        classNames={{
                                            base: 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white cursor-pointer',
                                            icon: 'text-white/80 w-7',
                                        }}
                                    />
                                </div>
                            </div>
                        </FormItem>
                    )}
                />
            </CardContent>
        </Card>
    )
}

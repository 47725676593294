import { useEffect } from 'react'

import { captureEvent, Dialog, DialogContent, DialogHeader, DialogTitle, ScrollArea } from '@/shared'

import { CustomerForm } from './customer-form'

interface Props {
    open: boolean
    onClose: (open: boolean) => void
}

export const CustomerExpressDialog = ({ open, onClose }: Props) => {
    useEffect(() => {
        captureEvent('customer_express_dialog', { open })
    }, [])

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-lg">
                <ScrollArea className="h-[550px]">
                    <div className="space-y-5">
                        <DialogHeader className="text-left">
                            <DialogTitle>Add customer</DialogTitle>
                        </DialogHeader>

                        <CustomerForm onSubmit={() => onClose(false)} />
                    </div>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    )
}

import { Skeleton } from '@/shared'

type Type = 'table' | 'table-with-tabs' | 'heading'

interface Props {
    type: Type
    className?: string
}

export const SkeletonMask = ({ type, className }: Props) => {
    const classNames = {
        table: 'w-full h-96',
        'table-with-tabs': 'w-full h-96',
        heading: 'w-40 h-12',
        tab: 'w-24 h-8',
    }

    if (type === 'heading') {
        return <Skeleton className={`${classNames[type]} ${className}`} />
    }

    if (type === 'table-with-tabs') {
        return (
            <div className="space-y-6">
                <div className="flex items-center gap-2">
                    {Array.from({ length: 2 }).map((_, index) => (
                        <Skeleton key={index} className={`${classNames.tab}`} />
                    ))}
                </div>

                <Skeleton className={`skeleton ${classNames[type]} ${className}`} />
            </div>
        )
    }

    return <Skeleton className={`skeleton ${classNames[type]} ${className}`} />
}

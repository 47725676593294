import { useEffect } from 'react'

import {
    captureEvent,
    cn,
    ScrollArea,
    Separator,
    Sheet,
    SheetContent,
    SheetHeader,
    type Transaction,
    type TransactionType,
    Typography,
} from '@/shared'

import { TransactionForm } from './transaction-form'

interface Props {
    transaction?: Transaction
    type: TransactionType
    open: boolean
    onClose: (open?: boolean) => void
}

export const TransactionSheet = ({ transaction, open, onClose, type }: Props) => {
    useEffect(() => {
        captureEvent('transaction_sheet_opened', { transaction_id: transaction?._id })
    }, [])

    const classes = {
        income: 'bg-gradient-to-br from-emerald-50 to-slate-50',
        expense: 'bg-gradient-to-br from-rose-50 to-slate-50',
    }

    return (
        <Sheet open={open} onOpenChange={onClose}>
            <SheetContent className={cn('w-full sm:max-w-[450px] md:rounded-tl-xl md:top-3 md:bottom-3 min-h-screen h-fit', classes[type])}>
                <ScrollArea className="h-screen pb-24 md:pb-16">
                    <div className="space-y-5">
                        <SheetHeader className="text-left">
                            <Typography variant="h3">
                                {transaction ? 'Edit' : 'Add'} {type}
                            </Typography>
                        </SheetHeader>

                        <Separator />

                        <TransactionForm type={type} transaction={transaction} onClose={onClose} />
                    </div>
                </ScrollArea>
            </SheetContent>
        </Sheet>
    )
}

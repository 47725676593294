import { type Invoice } from '@/shared'

export const getInvoiceBadgeClass = (state?: Invoice['state']) => {
    switch (state) {
        case 'PAID':
            return 'bg-indigo-100 text-indigo-600'
        case 'PENDING':
            return 'bg-emerald-100 text-emerald-600'
        case 'RECURRING':
            return 'bg-cyan-100 text-cyan-600'
        default:
            return 'bg-gray-100 text-gray-600'
    }
}

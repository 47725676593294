import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import { type RootState } from '@/app/middleware/reducers'
import { type Invoice, type InvoiceState } from '@/shared'

interface State {
    count: number
    invoices: Invoice[]
    state: InvoiceState
}

const initialState: State = {
    count: 0,
    invoices: [],
    state: 'ALL',
}

export const invoiceModel = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        setInvoices(state, action: PayloadAction<Invoice[]>) {
            state.invoices = action.payload
        },
        setInvoicesCount(state, action: PayloadAction<number>) {
            state.count = action.payload
        },
        addInvoice(state, action: PayloadAction<Invoice>) {
            state.invoices.unshift(action.payload)
            state.count++
        },
        removeInvoice(state, action: PayloadAction<string>) {
            state.invoices = state.invoices.filter((invoice) => invoice._id !== action.payload)
            state.count--
        },
        updateInvoice(state, action: PayloadAction<Partial<Invoice>>) {
            const index = state.invoices.findIndex((invoice) => invoice._id === action.payload._id)
            state.invoices[index] = { ...state.invoices[index], ...action.payload }
        },
        setInvoiceState(state, action: PayloadAction<InvoiceState>) {
            state.state = action.payload
        },
    },
})

export const useInvoicesData = () => useSelector((state: RootState) => state.invoice.invoices)
export const useInvoicesCount = () => useSelector((state: RootState) => state.invoice.count)
export const useInvoiceState = () => useSelector((state: RootState) => state.invoice.state)
export const useInvoiceById = (id: string) =>
    useSelector((state: RootState) => state.invoice.invoices.find((invoice) => invoice._id === id))

export const invoiceReducer = invoiceModel.reducer

import { Avatar } from '@nextui-org/react'

import { cn, type Customer, Separator, Sheet, SheetContent, SheetHeader, Typography } from '@/shared'

interface Props {
    customer?: Customer
    open: boolean
    onClose: (open: boolean) => void
}

export const CustomerPreviewSheet = ({ customer, open, onClose }: Props) => {
    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: customer?.currency || 'USD' })

    return (
        <Sheet open={open} onOpenChange={onClose}>
            <SheetContent className="space-y-5 w-full sm:max-w-[450px] rounded-tl-xl top-3 bottom-3 h-screen pb-16">
                <SheetHeader></SheetHeader>
                <div className="space-y-5">
                    <Avatar
                        showFallback
                        size="lg"
                        name={customer?.name}
                        src={customer?.avatar}
                        classNames={{
                            base: 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white',
                            icon: 'text-white/80 w-7',
                        }}
                    />

                    <div className="space-y-1">
                        <Typography variant="h3">{customer?.name}</Typography>
                        <Typography variant="subtitle">{customer?.email}</Typography>
                    </div>

                    <div>
                        <Typography
                            variant="subtitle"
                            className={cn({
                                'text-gray-300': !customer?.businessName,
                            })}
                        >
                            {customer?.businessName || 'No business name'}
                        </Typography>

                        <Typography
                            variant="subtitle"
                            className={cn({
                                'text-gray-300': !customer?.phoneNumber,
                            })}
                        >
                            {customer?.phoneNumber || 'No phone number'}
                        </Typography>

                        <Typography
                            variant="subtitle"
                            className={cn({
                                'text-gray-300': !customer?.vatId,
                            })}
                        >
                            {customer?.vatId || 'No vat ID'}
                        </Typography>

                        <Typography
                            variant="subtitle"
                            className={cn({
                                'text-gray-300': !customer?.country,
                            })}
                        >
                            {customer?.country || 'No country'}
                        </Typography>

                        <Typography
                            variant="subtitle"
                            className={cn({
                                'text-gray-300': !customer?.city,
                            })}
                        >
                            {customer?.city || 'No city'}
                        </Typography>

                        <Typography
                            variant="subtitle"
                            className={cn({
                                'text-gray-300': !customer?.address,
                            })}
                        >
                            {customer?.address || 'No address'}
                        </Typography>
                    </div>

                    <Separator />

                    <div className="flex items-center gap-24">
                        <div className="space-y1">
                            <Typography variant="subtitle">Profit</Typography>
                            <Typography variant="subtitle" className="!text-base font-semibold">
                                {formatter.format(customer?.revenue || 0)}
                            </Typography>
                        </div>

                        <div className="space-y1">
                            <Typography variant="subtitle">Pending profit</Typography>
                            <Typography variant="subtitle" className="!text-base font-semibold">
                                {formatter.format(customer?.pendingRevenue || 0)}
                            </Typography>
                        </div>
                    </div>

                    <Separator />

                    <Typography
                        variant="subtitle"
                        className={cn({
                            'text-gray-300': !customer?.notes,
                        })}
                    >
                        {customer?.notes || 'No notes'}
                    </Typography>
                </div>
            </SheetContent>
        </Sheet>
    )
}

import { Helmet } from 'react-helmet'

import { SkeletonMask, TransactionsHeader } from '@/entities'
import { TransactionsHeaderManipulate, TransactionsTable } from '@/features'
import { Page, useTransaction } from '@/shared'

export const TransactionsPage = () => {
    const { transactions, count, isLoading } = useTransaction()

    return (
        <>
            <Helmet>
                <title>Transactions - Insomnee</title>
            </Helmet>

            <Page className="space-y-6">
                <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 justify-between">
                    {isLoading ? <SkeletonMask type="heading" /> : <TransactionsHeader count={count} />}
                    {isLoading ? <SkeletonMask type="heading" /> : <TransactionsHeaderManipulate />}
                </div>

                {isLoading ? <SkeletonMask type="table-with-tabs" /> : <TransactionsTable transactions={transactions} />}
            </Page>
        </>
    )
}

import dayjs from 'dayjs'
import { type Control } from 'react-hook-form'

import { FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@/shared'

import { type InvoiceFormValues } from './invoice-form'

interface Props {
    control: Control<InvoiceFormValues>
}

export const InvoiceFormPrefix = ({ control }: Props) => {
    const prefixPlaceholder = dayjs().format('YYYY-MM')

    return (
        <FormField
            control={control}
            name="prefix"
            render={({ field }) => (
                <FormItem>
                    <FormLabel className="required">Prefix</FormLabel>
                    <FormControl>
                        <Input {...field} placeholder={prefixPlaceholder} />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    )
}

import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Bar, BarChart, ReferenceLine, ResponsiveContainer, Tooltip, type TooltipProps, XAxis, YAxis } from 'recharts'
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

import { useUserData } from '@/features/user'
import { type DashboardOverview, Separator, Skeleton, Typography } from '@/shared'

interface Props {
    data: DashboardOverview['yearTotals'] | undefined
    isLoading: boolean
}

export const DashboardBoundChart = ({ data, isLoading }: Props) => {
    const user = useUserData()

    const barChartData = useMemo(() => {
        return data?.map((item) => ({
            ...item,
            expenses: -item.expenses,
            expensesPending: -item.expensesPending,
        }))
    }, [data])

    const formatter = (value: number) =>
        new Intl.NumberFormat('en-US', { style: 'currency', currency: user?.business?.currency || 'USD' }).format(value)

    const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
        const year = payload?.[0]?.payload?.year || dayjs().year()
        const expensesTaxes = payload?.[0]?.payload?.expensesTaxes || 0
        const expenses = payload?.[0]?.payload?.expenses || 0
        const expensesPending = payload?.[0]?.payload?.expensesPending || 0
        const profit = payload?.[0]?.payload?.profit || 0
        const profitPending = payload?.[0]?.payload?.profitPending || 0

        if (active) {
            return (
                <div className="bg-white p-3 w-full max-w-56 rounded-md space-y-2">
                    <p className="text-xs text-default-400">
                        {label} {year}
                    </p>

                    <Separator />

                    <div className="flex items-center gap-1">
                        <div className="text-xs flex items-center gap-1">
                            <div className="w-1.5 h-1.5 bg-[#FDE2E1] rounded-full" />
                            <span className="text-xs">Pending tax expenses</span>
                        </div>

                        <div className="text-xs font-medium">{formatter(Math.abs(expensesPending as number) || 0)}</div>
                    </div>

                    <div className="flex items-center gap-1">
                        <div className="text-xs flex items-center gap-1">
                            <div className="w-1.5 h-1.5 bg-[#FCA5A5] rounded-full" />
                            <span className="text-xs">Tax expenses</span>
                        </div>

                        <div className="text-xs font-medium">{formatter(Math.abs(expensesTaxes as number) || 0)}</div>
                    </div>

                    <div className="flex items-center gap-1">
                        <div className="text-xs flex items-center gap-1">
                            <div className="w-1.5 h-1.5 bg-red-500 rounded-full" />
                            <span className="text-xs">Expenses</span>
                        </div>

                        <div className="text-xs font-medium">{formatter(Math.abs(expenses as number) || 0)}</div>
                    </div>

                    <div className="flex items-center gap-1">
                        <div className="text-xs flex items-center gap-1">
                            <div className="w-1.5 h-1.5 bg-[#5FA5F9] rounded-full" />
                            <span className="text-xs">Profit</span>
                        </div>

                        <div className="text-xs font-medium">{formatter(Math.abs(profit as number) || 0)}</div>
                    </div>

                    <div className="flex items-center gap-1">
                        <div className="text-xs flex items-center gap-1">
                            <div className="w-1.5 h-1.5 bg-[#92C5FD] rounded-full" />
                            <span className="text-xs">Pending profit</span>
                        </div>

                        <div className="text-xs font-medium">{formatter(Math.abs(profitPending as number) || 0)}</div>
                    </div>
                </div>
            )
        }

        return null
    }

    if (isLoading) {
        return <Skeleton className="w-full h-[300px]" />
    }

    return (
        <div className="space-y-6">
            <Typography variant="p" className="font-semibold">
                Incomes & Expenses
            </Typography>

            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={barChartData} stackOffset="sign" barSize={40}>
                    <XAxis interval={0} dataKey="monthShortName" stroke="#9BA3AF" className="text-xs" />
                    <YAxis tick={false} stroke="#fff" axisLine={true} width={0} />

                    <Tooltip content={<CustomTooltip />} cursor={false} />
                    <ReferenceLine y={0} stroke="#9BA3AF" />

                    <Bar dataKey="expensesPending" fill="#FDE2E1" stackId="stack" />
                    <Bar dataKey="expenses" fill="#F77171" stackId="stack" />
                    <Bar dataKey="expensesTaxes" fill="#FCA5A5" stackId="stack" />
                    <Bar dataKey="profit" fill="#5FA5F9" stackId="stack" />
                    <Bar dataKey="profitPending" fill="#92C5FD" stackId="stack" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

import { useEffect } from 'react'

import { captureEvent, type Invoice, ScrollArea, Separator, Sheet, SheetContent, SheetHeader, Typography } from '@/shared'

import { InvoiceForm } from './invoice-form'

interface Props {
    invoice?: Invoice
    open: boolean
    onClose: (open: boolean) => void
}

export const InvoiceSheet = ({ invoice, open, onClose }: Props) => {
    useEffect(() => {
        captureEvent('invoice_sheet_opened', { invoice_id: invoice?._id })
    }, [])

    return (
        <Sheet open={open} onOpenChange={onClose}>
            <SheetContent className="w-full sm:max-w-[750px] md:rounded-tl-xl md:top-3 md:bottom-3 h-fit">
                <ScrollArea className="h-screen pb-24 md:pb-16">
                    <div className="space-y-5">
                        <SheetHeader className="text-left">
                            <Typography variant="h3">{invoice?._id ? 'Edit invoice' : 'Add invoice'}</Typography>
                        </SheetHeader>

                        <Separator />

                        <InvoiceForm invoice={invoice} onSubmit={() => onClose(false)} />
                    </div>
                </ScrollArea>
            </SheetContent>
        </Sheet>
    )
}

import { type ClerkError } from '@/shared'

export const formatClerkError = (err: ClerkError | any) => {
    const defaultErrMessage = 'Contact support@insomnee.com for additional help'

    if (!err) return defaultErrMessage

    if (typeof err === 'string') return err

    if (err?.errors?.length === 0) return defaultErrMessage

    if (err?.errors?.length > 1) return err?.errors.map((e: any) => e.longMessage || e.message).join(', ')

    if (err.errors[0].message === 'reset_password_email_code is not allowed') {
        return 'You do not have account with this email'
    }

    return err?.errors[0].longMessage || err?.errors[0].message || defaultErrMessage
}

import { zodResolver } from '@hookform/resolvers/zod'
import { captureException } from '@sentry/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import { useDispatch } from 'react-redux'
import { z } from 'zod'

import { userModel, useUserData } from '@/features/user'
import {
    Button,
    captureEvent,
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    Input,
    Separator,
    Textarea,
    updateUserInvoice,
    useToast,
} from '@/shared'

export const SettingsInvoiceForm = () => {
    const dispatch = useDispatch()
    const { toast } = useToast()
    const user = useUserData()

    const [isLoading, setIsLoading] = useState(false)

    const formSchema = z.object({
        taxLabel: z.string().min(1, { message: 'Tax label is required' }).or(z.literal('Tax')),

        taxRate: z
            .number()
            .min(0, { message: 'Tax rate must be a positive number' })
            .max(100, { message: 'Tax rate must be less than 100' })
            .optional()
            .or(z.literal(0)),

        prefix: z
            .string()
            .min(1, { message: 'Invoice prefix is required' })
            .or(z.literal(dayjs().format('YYYY-MM'))),

        defaultNotes: z.string().max(500, { message: 'Notes must be less than 500 characters' }).optional().or(z.literal('')),

        defaultEmailText: z.string().max(500, { message: 'Email text must be less than 500 characters' }).optional().or(z.literal('')),
    })

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            taxLabel: user?.invoice?.taxLabel || 'Tax',
            taxRate: user?.invoice?.taxRate || 0,
            prefix: user?.invoice?.prefix || dayjs().format('YYYY-MM'),
            defaultNotes: user?.invoice?.defaultNotes || '',
            defaultEmailText: user?.invoice?.defaultEmailText || '',
        },
    })

    const handleFormSubmit = async (data: z.infer<typeof formSchema>) => {
        try {
            setIsLoading(true)

            const resp = await updateUserInvoice(data)
            if ('error' in resp) throw new Error(resp.error)

            dispatch(userModel.actions.updateUser(resp.data!))

            toast({
                title: 'Invoice options updated',
                description: 'Your invoice options have been successfully updated',
            })

            captureEvent('invoice_settings_updated')
        } catch (error) {
            const err = error as Error

            toast({
                variant: 'destructive',
                title: 'Failed to update business details',
                description: err.message || 'An error occurred',
            })

            captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Form {...form}>
            <form className="space-y-5" onSubmit={form.handleSubmit(handleFormSubmit)}>
                <div className="flex gap-6">
                    <FormField
                        control={form.control}
                        name="taxLabel"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Tax label</FormLabel>
                                <FormControl>
                                    <Input placeholder="Tax" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="taxRate"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Tax rate</FormLabel>
                                <FormControl>
                                    <NumericFormat
                                        fixedDecimalScale
                                        suffix="%"
                                        decimalScale={2}
                                        thousandSeparator=","
                                        allowNegative={false}
                                        customInput={Input}
                                        value={field.value}
                                        onValueChange={(values) => {
                                            field.onChange(values.floatValue || 0)
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <FormField
                    control={form.control}
                    name="prefix"
                    render={({ field }) => (
                        <FormItem className="w-full">
                            <FormLabel>Invoice prefix</FormLabel>
                            <FormControl>
                                <Input placeholder={field.value} {...field} />
                            </FormControl>
                            <FormDescription>
                                The default invoice label is &quot;{dayjs().format('YYYY-')}&quot; followed by a number (e.g., &quot;
                                {dayjs().format('YYYY-MM')}&quot;). You can set a different name for your invoices if your local law,
                                language, or occupation requires it.
                            </FormDescription>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="defaultNotes"
                    render={({ field }) => (
                        <FormItem className="w-full">
                            <FormLabel>Invoice notes</FormLabel>
                            <FormControl>
                                <Textarea placeholder="Thank you for the business!" {...field} className="resize-none" rows={7} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <Separator />

                <FormField
                    control={form.control}
                    name="defaultEmailText"
                    render={({ field }) => (
                        <FormItem className="w-full">
                            <FormLabel>Default email text</FormLabel>
                            <FormControl>
                                <Textarea
                                    placeholder="Hi! Please verify my invoice carefully. Thank you!"
                                    {...field}
                                    className="resize-none"
                                    rows={7}
                                />
                            </FormControl>
                            <FormDescription>
                                <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
                                    <span className="text-xs">customerName</span>
                                </kbd>{' '}
                                is a placeholder for your customer&apos;s name. You can use it in your email text to personalize your
                                message.
                            </FormDescription>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <Button isLoading={isLoading} type="submit">
                    Save
                </Button>
            </form>
        </Form>
    )
}

import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { Tab, Tabs, Tooltip } from '@nextui-org/react'
import { captureException } from '@sentry/react'
import { type ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import dayjs from 'dayjs'
import _ from 'lodash'
import { type MouseEvent, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { getCategoryIcon, transactionModel, TransactionSheet } from '@/features'
import { CsvManipulate } from '@/features/csv'
import {
    Badge,
    Button,
    captureEvent,
    cn,
    deleteTransaction,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
    duplicateTransaction,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
    type Transaction,
    useToast,
} from '@/shared'

interface Props {
    transactions: Transaction[]
}

export const TransactionsTable = ({ transactions }: Props) => {
    const dispatch = useDispatch()
    const { toast } = useToast()

    const tabs = useMemo(() => {
        return [
            { name: 'All transactions', value: 'all' },
            { name: 'Expenses', value: 'expense' },
            { name: 'Incomes', value: 'income' },
            { name: 'Recurring', value: 'recurring' },
        ]
    }, [])

    const [activeTab, setActiveTab] = useState<string>('all')
    const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null)

    const filteredTransactions = useMemo(() => {
        captureEvent('transactions_table_filtered', { activeTab })

        return transactions.filter((transaction) => {
            if (activeTab === 'all') return true
            if (activeTab === 'expense') return transaction.type === 'expense'
            if (activeTab === 'income') return transaction.type === 'income'
            if (activeTab === 'recurring') return transaction.recurring !== 'disabled'

            return false
        })
    }, [transactions, activeTab])

    const handleDuplicateTransaction = async (e: MouseEvent<HTMLDivElement>, transaction: Transaction) => {
        e.stopPropagation()

        try {
            const resp = await duplicateTransaction(transaction._id!)
            if ('error' in resp) throw new Error(resp.error)

            dispatch(transactionModel.actions.addTransaction(resp.data!))
            toast({ title: 'Transaction duplicated', description: 'Transaction has been duplicated successfully.' })
        } catch (error) {
            console.error(error)
            const err = error as Error

            toast({
                variant: 'destructive',
                title: 'Failed to duplicate transaction',
                description: err.message || 'An error occurred while duplicating the transaction.',
            })

            captureException(error)
        }
    }

    const handleDeleteTransaction = async (e: MouseEvent<HTMLDivElement>, transaction: Transaction) => {
        e.stopPropagation()

        try {
            const resp = await deleteTransaction(transaction._id!)
            if (resp.error) throw new Error(resp.error)

            dispatch(transactionModel.actions.deleteTransactionById(transaction._id!))
            toast({ title: 'Transaction deleted', description: 'Transaction has been deleted successfully.' })
        } catch (error) {
            console.error(error)
            const err = error as Error

            toast({
                variant: 'destructive',
                title: 'Failed to delete transaction',
                description: err.message || 'An error occurred while deleting the transaction.',
            })

            captureException(error)
        }
    }

    const columns: Array<ColumnDef<Transaction>> = [
        {
            accessorKey: 'name',
            header: () => <div className="w-[180px]">Description</div>,
            cell: ({ row }) => {
                return (
                    <div className="flex space-x-2 items-center">
                        <div className="w-7 h-7 flex justify-center items-center text-xs bg-slate-200 text-heading rounded-full">
                            {getCategoryIcon(row.original.category)}
                        </div>

                        <span>{row.original.name}</span>
                    </div>
                )
            },
        },
        {
            accessorKey: 'merchant',
            header: () => <div className="w-[180px]">Merchant / Vendor</div>,
            cell: ({ row }) => {
                return <span>{row.original.merchant?.name || '-'}</span>
            },
        },
        {
            accessorKey: 'date',
            header: () => <div className="w-[100px]">Bill date</div>,
            cell: ({ row }) => {
                return <span>{dayjs(row.original.billDate).format('MMM D, YYYY')}</span>
            },
        },
        {
            accessorKey: 'amount',
            header: () => <div className="text-right">Amount</div>,
            cell: ({ row }) => {
                return (
                    <div className="text-right font-medium">
                        {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: row.original.currency || 'USD',
                        }).format(row.original.amount || 0)}
                    </div>
                )
            },
        },
        {
            id: 'type',
            cell: ({ row }) => {
                const badgeClasses = {
                    expense: 'bg-rose-100 text-rose-600',
                    income: 'bg-emerald-100 text-emerald-600',
                    recurring: 'bg-indigo-100 text-indigo-600',
                }

                return (
                    <div className="flex items-center space-x-3 text-right">
                        <Badge variant="outline" className={cn(badgeClasses[row.original.type], 'rounded-sm')}>
                            {_.upperFirst(row.original.type)}
                        </Badge>
                        {row.original.recurring !== 'disabled' && (
                            <Tooltip content={`Invoice is recurring every ${row.original.recurring}`}>
                                <div>
                                    <Badge variant="outline" className={cn(badgeClasses.recurring)}>
                                        <ArrowPathIcon className="w-4 h-4" />
                                    </Badge>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                )
            },
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button data-umami-event="Customer manipulation button" variant="ghost" className="p-0 w-full">
                                <span className="sr-only">Open menu</span>
                                <EllipsisHorizontalIcon className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end" className="min-w-60">
                            <DropdownMenuItem onClick={(e) => handleDuplicateTransaction(e, row.original)}>Duplicate</DropdownMenuItem>
                            <DropdownMenuItem className="text-destructive" onClick={(e) => handleDeleteTransaction(e, row.original)}>
                                Delete
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    const table = useReactTable({
        data: filteredTransactions,
        columns,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <div className="space-y-6">
            <Tabs
                variant="light"
                aria-label="Active"
                radius="sm"
                selectedKey={activeTab}
                onSelectionChange={(value) => setActiveTab(value as string)}
            >
                {tabs.map((tab) => (
                    <Tab key={tab.value} title={tab.name} className="text-xs md:text-sm" />
                ))}
            </Tabs>

            <Table>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead key={header.id}>
                                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                    </TableHead>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                            <TableRow
                                key={row.id}
                                className="cursor-pointer hover:bg-gray-50"
                                onClick={() => setSelectedTransaction(row.original)}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length} className="h-24 text-center">
                                No results.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            {!!filteredTransactions.length && (
                <div className="flex items-center justify-end space-x-5">
                    <CsvManipulate
                        actions={['export']}
                        fileName={`transactions-${activeTab}.csv`}
                        data={[
                            ['Description', 'Merchant / Vendor', 'Bill date', 'Amount', 'Type', 'Created', 'Updated'],
                            ...filteredTransactions.map((transaction) => [
                                transaction.name,
                                transaction.merchant?.name || '-',
                                dayjs(transaction.billDate).format('MMM D, YYYY'),
                                new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: transaction.currency || 'USD',
                                }).format(transaction.amount || 0),
                                transaction.type,
                                dayjs(transaction.metadata?.createdAt).format('MMM D, YYYY'),
                                dayjs(transaction.metadata?.updatedAt).format('MMM D, YYYY'),
                            ]),
                        ]}
                    />
                </div>
            )}

            <TransactionSheet
                type={selectedTransaction?.type || 'expense'}
                open={!!selectedTransaction}
                transaction={selectedTransaction!}
                onClose={() => setSelectedTransaction(null)}
            />
        </div>
    )
}

import { request, type Transaction, type TransactionMerchant } from '@/shared'

export const getTransactions = () => request<Transaction[]>('get', '/transaction')

export const getTransactionsCount = () => request<number>('get', '/transaction/count')

export const getTransactionMerchants = () => request<TransactionMerchant[]>('get', '/transaction/merchant')

export const createMerchant = (merchant: TransactionMerchant) => request<TransactionMerchant>('post', '/transaction/merchant', merchant)

export const createTransaction = (transaction: Transaction) => request<Transaction>('post', '/transaction', transaction)

export const updateTransaction = (transaction: Transaction) => request<Transaction>('put', `/transaction/${transaction._id}`, transaction)

export const deleteTransactionMerchant = (id: string) => request('delete', `/transaction/merchant/${id}`)

export const updateTransactionMerchant = (merchant: TransactionMerchant) =>
    request<TransactionMerchant>('put', `/transaction/merchant/${merchant._id}`, merchant)

export const deleteTransaction = (id: string) => request('delete', `/transaction/${id}`)

export const duplicateTransaction = (id: string) => request<Transaction>('post', `/transaction/${id}/duplicate`)

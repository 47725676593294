import dayjs from 'dayjs'

import { type InvoiceRecurringFrequency } from '@/shared'

export const getInvoiceNextRecurringDate = (frequency: InvoiceRecurringFrequency) => {
    switch (frequency) {
        case 'QUARTERLY':
            return dayjs().add(3, 'month').format('DD MMMM, YYYY')
        case 'YEARLY':
            return dayjs().add(1, 'year').format('DD MMMM, YYYY')
        default:
            return dayjs().add(1, 'month').format('DD MMMM, YYYY')
    }
}

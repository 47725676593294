import { Tab, Tabs } from '@nextui-org/react'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'

import { SettingsAccountForm, SettingsBillingForm, SettingsBusinessForm, SettingsInvoiceForm } from '@/features'
import { captureEvent, Page, Typography } from '@/shared'

const tabs = [
    { title: 'Business details', param: 'business' },
    { title: 'Invoice options', param: 'invoice' },
    // { title: 'Billing', param: 'billing' },
    { title: 'Account', param: 'account' },
]

export const SettingsPage = () => {
    const navigate = useNavigate()
    const params = useParams<{ options: string }>()

    const [activeTab, setActiveTab] = useState<string>(tabs[0].param)

    useEffect(() => {
        const tab = tabs.find((tab) => tab.param === params?.options)
        if (tab) {
            setActiveTab(tab.param)
        } else {
            navigate(`/settings/${tabs[0].param}`)
        }
    }, [params?.options])

    const pageTitle = useMemo(() => {
        switch (activeTab) {
            case 'business':
                return 'Business Settings - Insomnee'
            case 'invoice':
                return 'Invoice Settings - Insomnee'
            // case 'billing':
            //     return 'Invoice Settings - Billing'
            case 'account':
                return 'Account Settings - Insomnee'
            default:
                return 'Settings - Insomnee'
        }
    }, [activeTab])

    const handleTabChange = (value: string) => {
        const tab = tabs.find((tab) => tab.param === value)
        if (tab) {
            navigate(`/settings/${tab.param}`)
            setActiveTab(tab.param)

            captureEvent('settings_tab_change', { tab: tab.param })
        }
    }

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>

            <Page className="space-y-6 max-w-xl">
                <Typography variant="h3">Settings</Typography>

                <Tabs
                    selectedKey={activeTab}
                    variant="light"
                    aria-label="Active"
                    radius="sm"
                    onSelectionChange={(value) => handleTabChange(value as string)}
                >
                    {tabs.map((tab) => (
                        <Tab key={tab.param} title={tab.title} value={tab.param} className="text-xs md:text-sm">
                            {tab.param === 'business' && <SettingsBusinessForm />}
                            {tab.param === 'invoice' && <SettingsInvoiceForm />}
                            {tab.param === 'billing' && <SettingsBillingForm />}
                            {tab.param === 'account' && <SettingsAccountForm />}
                        </Tab>
                    ))}
                </Tabs>
            </Page>
        </>
    )
}

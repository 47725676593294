import _ from 'lodash'
import { useState } from 'react'
import { Helmet } from 'react-helmet'

import { InvoicesHeader } from '@/entities'
import { InvoiceSheet, InvoicesTable } from '@/features'
import { Button, captureEvent, Page, Separator, Skeleton, useInvoice } from '@/shared'

export const Invoices = () => {
    const { isLoading, count, invoices } = useInvoice()
    const [openAddSheet, setOpenAddSheet] = useState(false)

    const handleAddInvoiceBtnClick = () => {
        captureEvent('Add invoice button')
        setOpenAddSheet(true)
    }

    return (
        <>
            <Helmet>
                <title>Invoices - Insomnee</title>
            </Helmet>

            <Page className="space-y-5">
                <div className="flex justify-between items-center">
                    {isLoading ? <Skeleton className="w-40 h-12" /> : <InvoicesHeader count={count ?? 0} />}

                    {isLoading ? (
                        <Skeleton className="w-52 h-12" />
                    ) : (
                        <Button data-umami-event="Add invoice button" className="text-xs md:text-base" onClick={handleAddInvoiceBtnClick}>
                            Add invoice
                        </Button>
                    )}
                </div>

                <Separator />

                {isLoading ? (
                    <div className="space-y-6">
                        <div className="flex items-center gap-2">
                            {Array.from({ length: 2 }).map((_, index) => (
                                <Skeleton key={index} className="w-24 h-8" />
                            ))}
                        </div>

                        <Skeleton className="w-full h-96" />
                    </div>
                ) : (
                    <InvoicesTable invoices={_.unionBy(invoices, '_id') || []} />
                )}

                <InvoiceSheet open={openAddSheet} onClose={setOpenAddSheet} />
            </Page>
        </>
    )
}

import { useClerk, useUser } from '@clerk/clerk-react'
import { Avatar, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, User } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'

import { captureEvent } from '@/shared'

interface Props {
    display?: 'desktop' | 'mobile'
}

export const SidebarProfile = ({ display = 'desktop' }: Props) => {
    const navigate = useNavigate()
    const { signOut } = useClerk()
    const { user } = useUser()

    const handleLogout = () => {
        signOut().then(() => {
            captureEvent('logout')
            navigate('/login')
        })
    }

    const handleOpenChangelog = () => {
        captureEvent('open_changelog')
        window.open('https://insomnee.com/updates')
    }

    return (
        <div>
            <Dropdown
                showArrow
                radius="sm"
                classNames={{
                    base: 'before:bg-default-200', // change arrow background
                    content: 'p-0 border-small border-divider bg-background max-w-64',
                }}
            >
                <DropdownTrigger>
                    <div className="flex items-center justify-center gap-4 cursor-pointer w-fit m-auto">
                        {display === 'desktop' ? (
                            <Avatar
                                showFallback
                                size="sm"
                                name={user?.fullName || undefined}
                                src={user?.imageUrl || undefined}
                                classNames={{
                                    base: 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white cursor-pointer',
                                    icon: 'text-white/80 w-7',
                                }}
                            />
                        ) : (
                            <User
                                avatarProps={{
                                    src: user?.imageUrl || undefined,
                                    size: 'sm',
                                    classNames: {
                                        base: 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white cursor-pointer',
                                        icon: 'text-white/80 w-7',
                                    },
                                }}
                                name={user?.fullName || '-'}
                                description={user?.emailAddresses[0].emailAddress || '-'}
                            />
                        )}
                    </div>
                </DropdownTrigger>
                <DropdownMenu
                    aria-label="Custom item styles"
                    className="p-3"
                    itemClasses={{
                        base: [
                            'rounded-md',
                            'text-default-500',
                            'transition-opacity',
                            'data-[hover=true]:text-foreground',
                            'data-[hover=true]:bg-default-100',
                            'dark:data-[hover=true]:bg-default-50',
                            'data-[selectable=true]:focus:bg-default-50',
                            'data-[pressed=true]:opacity-70',
                            'data-[focus-visible=true]:ring-default-500',
                        ],
                    }}
                >
                    <DropdownSection showDivider aria-label="Profile & Actions">
                        <DropdownItem key="profile" className="h-14 gap-2">
                            <User
                                avatarProps={{
                                    src: user?.imageUrl || undefined,
                                    size: 'sm',
                                    classNames: {
                                        base: 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white cursor-pointer',
                                        icon: 'text-white/80 w-7',
                                    },
                                }}
                                name={user?.fullName || '-'}
                                description={user?.emailAddresses[0].emailAddress || '-'}
                                onClick={() => {
                                    captureEvent('open_profile')
                                }}
                            />
                        </DropdownItem>
                        <DropdownItem
                            key="settings"
                            onClick={() => {
                                captureEvent('open_settings')
                                navigate('/settings')
                            }}
                        >
                            Settings
                        </DropdownItem>
                        <DropdownItem key="changelog" onClick={handleOpenChangelog}>
                            Changelog
                        </DropdownItem>
                    </DropdownSection>

                    <DropdownSection aria-label="Help & Feedback">
                        <DropdownItem key="logout" className="text-destructive" onClick={handleLogout}>
                            Log Out
                        </DropdownItem>
                    </DropdownSection>
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

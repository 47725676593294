import AuthLogoImg from '@/assets/images/logo.png'

interface Props {
    type: 'hero' | 'form'
}

export const AuthLogo = ({ type }: Props) => {
    const handleLogoClick = () => {
        window.open('https://insomnee.com', '_blank')
    }

    if (type === 'hero') {
        return (
            <div className="p-5 bg-white rounded-3xl w-fit cursor-pointer" onClick={handleLogoClick}>
                <img src={AuthLogoImg} alt="logo" className="w-12 h-12" />
            </div>
        )
    }

    return <img src={AuthLogoImg} alt="logo" className="cursor-pointer w-6 h-6" onClick={handleLogoClick} />
}

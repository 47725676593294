import dayjs from 'dayjs'
import _ from 'lodash'
import { useMemo } from 'react'

import { getInvoiceCalculations, useUserData } from '@/features'
import { type Customer, type Invoice } from '@/shared'

interface WaitlistEmailProps {
    invoice: Invoice
}

export const InvoiceEmailTemplate: React.FC<Readonly<WaitlistEmailProps>> = ({ invoice }) => {
    const user = useUserData()
    const { total } = getInvoiceCalculations(invoice)

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: invoice.currency || user?.business?.currency || 'USD',
    })

    const emailMessage = useMemo(() => {
        const customers = (invoice.customers as Customer[]) || []
        const customer = customers[0] || undefined

        const message =
            user?.invoice?.defaultEmailText ||
            `Hi {{customerName}}, \n\nHere's a link to view and pay the invoice we discussed. Please email me at ${user?.email} with any questions.\n\nBest regards, \n${user?.fullName}`

        // Need to replace {{customerName}} in user.invoice.defaultEmailMessage to the actual customer name
        return message.replace(/{{customerName}}/g, customer?.name || '')
    }, [user?.invoice?.defaultEmailText])

    return (
        <div className="border border-[#eaeaea] rounded-md bg-white shadow-sm px-6 py-12 space-y-6 mt-3">
            <h3 className="text-black text-2xl font-semibold">Invoice #{invoice.prefix}</h3>

            <div className="flex items-center gap-1">
                <p className="text-[#666666] text-[15px] font-semibold">From:</p>
                <p className="text-[#666666] text-[15px]">
                    {user?.fullName} ({_.truncate(user?.email, { length: 25 })})
                </p>
            </div>

            <hr className="border border-solid border-[#eaeaea] my-[26px] mx-0 w-full" />

            <p className="text-black text-[15px] leading-normal whitespace-pre-line">{emailMessage}</p>

            <hr className="border border-solid border-[#eaeaea] my-[26px] mx-0 w-full" />

            <div className="flex items-center justify-between">
                <p className="text-black text-[15px] text-left">Issue date</p>
                <p className="text-black text-[15px] text-right">{dayjs(invoice.issuedDate).format('DD MMM, YYYY')}</p>
            </div>

            {invoice.dueDate && (
                <div className="flex items-center justify-between">
                    <p className="text-black text-[15px] text-left">Due date</p>
                    <p className="text-black text-[15px] text-right">{dayjs(invoice.dueDate).format('DD MMM, YYYY')}</p>
                </div>
            )}

            <hr className="border border-solid border-[#eaeaea] my-[26px] mx-0 w-full" />

            <div className="flex items-center justify-between">
                <p className="text-black text-[15px] text-left font-semibold">Total due</p>
                <p className="text-black text-[15px] text-right font-semibold">{formatter.format(total)}</p>
            </div>

            <hr className="border border-solid border-[#eaeaea] my-[26px] mx-0 w-full" />

            <div>
                <a
                    href={`https://insomnee.com/invoice/${invoice._id}`}
                    className="w-fit cursor-pointer text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-sm px-5 py-2.5 focus:outline-none"
                >
                    View invoice details
                </a>
            </div>
        </div>
    )
}

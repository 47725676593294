import { ArrowLongRightIcon, CheckIcon } from '@heroicons/react/20/solid'
import { captureException } from '@sentry/react'
import { useState } from 'react'

import {
    Badge,
    Button,
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
    getBillingSession,
    type Pricing,
    Typography,
    useToast,
} from '@/shared'

interface Props {
    pricing: Pricing
}

export const PricingCard = ({ pricing }: Props) => {
    const { toast } = useToast()
    const [isLoading, setIsLoading] = useState(false)

    const handleGetSession = async (plan: 'monthly' | 'yearly') => {
        try {
            setIsLoading(true)

            const resp = await getBillingSession(plan)
            if ('error' in resp) throw new Error(resp.error)

            if (resp.data?.url) {
                window.location.href = resp.data.url
            }
        } catch (error) {
            console.error(error)
            const err = error as Error

            toast({ variant: 'destructive', title: 'Failed to get billing session', description: err.message })

            captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Card className="w-full">
            <CardHeader className="space-y-3">
                <CardTitle className="text-3xl">${pricing.pricePerMonth}/mo</CardTitle>
                <CardDescription className="text-gray-400 space-x-2">
                    {pricing.billingCycle === 'monthly' ? 'Billed monthly' : <span>${pricing.pricePerYear} billed yearly</span>}
                    {pricing.billingCycle === 'yearly' && (
                        <Badge variant="outline" className="bg-emerald-300 text-emerald-700">
                            -{pricing.discountPercentage}%
                        </Badge>
                    )}
                </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
                <div className="space-y-1">
                    {pricing.features.map((feature, index) => (
                        <Typography key={index} variant="subtitle" className="flex items-center space-x-2">
                            <CheckIcon className="w-5 h-5 text-green-500" />
                            <span>{feature}</span>
                        </Typography>
                    ))}
                </div>

                <Button isLoading={isLoading} className="w-full" onClick={() => handleGetSession(pricing.billingCycle)}>
                    <ArrowLongRightIcon className="w-5 h-5 mr-2" />
                    <span>Subscribe {pricing.billingCycle}</span>
                </Button>
            </CardContent>
        </Card>
    )
}

import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import { type RootState } from '@/app/middleware/reducers'
import { type User } from '@/shared'

interface State {
    user: User | null
}

const initialState: State = {
    user: null,
}

export const userModel = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<User>) {
            state.user = action.payload
        },
        updateUser: (state, action: PayloadAction<Partial<User>>) => {
            if (state.user) {
                state.user = { ...state.user, ...action.payload }
            }
        },
    },
})

export const useUserData = () => useSelector((state: RootState) => state.user.user)

export const userReducer = userModel.reducer

import { CalendarIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'
import { type Control } from 'react-hook-form'

import {
    Button,
    Calendar,
    cn,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/shared'

import { type InvoiceFormValues } from './invoice-form'

interface Props {
    control: Control<InvoiceFormValues>
}

export const InvoiceFormDueDate = ({ control }: Props) => {
    return (
        <FormField
            control={control}
            name="dueDate"
            render={({ field }) => (
                <FormItem className="flex flex-col w-full">
                    <FormLabel>Due date</FormLabel>
                    <Popover>
                        <PopoverTrigger asChild>
                            <FormControl>
                                <Button
                                    variant={'outline'}
                                    className={cn('pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                                >
                                    {field.value ? <span>{dayjs(field.value).format('MMM DD, YYYY')}</span> : <span>Pick a date</span>}
                                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                            </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                                mode="single"
                                selected={field.value}
                                disabled={(date) =>
                                    dayjs(date).isBefore(dayjs().subtract(1, 'day')) || dayjs(date).isBefore(dayjs('1900-01-01'))
                                }
                                onSelect={field.onChange}
                            />
                        </PopoverContent>
                    </Popover>
                    <FormMessage />
                </FormItem>
            )}
        />
    )
}

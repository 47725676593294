import { DashboardCard } from '@/entities'
import { cn, type DashboardOverview, Skeleton } from '@/shared'

interface Props {
    overview: DashboardOverview | undefined
    isLoading: boolean
}

export const DashboardTotalCards = ({ overview, isLoading }: Props) => {
    const cards = [
        {
            title: 'Profit forecast',
            value: (overview?.profitForecast ?? 0).toFixed(2),
            description: 'At the end of the year',
        },
        {
            title: 'Expenses forecast',
            value: (overview?.expensesPending ?? 0).toFixed(2),
            description: 'At the end of the year',
        },
        {
            title: 'Expenses discounts',
            value: (overview?.expensesDiscount ?? 0).toFixed(2),
            description: '-',
        },
        {
            title: 'Expenses pending discounts',
            value: (overview?.expensesDiscountPending ?? 0).toFixed(2),
            description: '-',
        },
    ]

    return (
        <div
            className={cn('grid gap-4 grid-cols-2 lg:grid-cols-4 mt-4 md:mt-6', {
                'border-b border-t border-default-100 py-4 md:py-0': !isLoading && overview,
            })}
        >
            {isLoading || !overview
                ? Array.from({ length: 4 }, (_, i) => i).map((i) => <Skeleton key={i} className="w-full h-32" />)
                : cards.map((card) => (
                      <DashboardCard key={card.title} title={card.title} description={card.description} value={card.value} />
                  ))}
        </div>
    )
}

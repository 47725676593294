import { useSignUp } from '@clerk/clerk-react'
import { ArrowLongRightIcon } from '@heroicons/react/20/solid'
import { zodResolver } from '@hookform/resolvers/zod'
import { captureException } from '@sentry/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { PatternFormat } from 'react-number-format'
import { Link } from 'react-router-dom'
import { z } from 'zod'

import GoogleLogoImg from '@/assets/images/google-logo.svg'
import {
    Button,
    captureEvent,
    Form,
    formatClerkError,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    Input,
    Separator,
    Typography,
    useToast,
} from '@/shared'

export const AuthSignupForm = () => {
    const { signUp, setActive } = useSignUp()
    const { toast } = useToast()

    const [isVerifyOtp, setIsVerifyOtp] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const formSchema = z.object({
        firstName: z.string().min(3, {
            message: 'First name must be at least 3 characters long',
        }),

        lastName: z.string().min(3, {
            message: 'Last name must be at least 3 characters long',
        }),

        email: z.string().email({
            message: 'Invalid email address',
        }),

        password: z.string().min(8, {
            message: 'Password must be at least 8 characters long',
        }),

        otp: z
            .string()
            .length(6, {
                message: 'OTP must be 6 characters long',
            })
            .optional()
            .or(z.literal('')),
    })

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: { firstName: '', lastName: '', email: '', password: '' },
    })

    const onFormSubmit = async (values: z.infer<typeof formSchema>) => {
        try {
            setIsLoading(true)

            const isOtpValid = form.getValues('otp')?.length === 6

            if (!isVerifyOtp && !isOtpValid) {
                return await handleRegister(values)
            }

            if (isVerifyOtp && isOtpValid) {
                return await handleVerifyOtp(values.otp!)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleRegister = async (values: z.infer<typeof formSchema>) => {
        try {
            const { email, firstName, lastName, password } = values

            await signUp?.create({
                emailAddress: email,
                firstName,
                lastName,
                password,
            })

            await signUp?.prepareEmailAddressVerification({ strategy: 'email_code' })

            setIsVerifyOtp(true)
        } catch (error) {
            toast({
                variant: 'destructive',
                title: 'Failed to create an account',
                description: formatClerkError(error),
            })

            captureException(formatClerkError(error))
        }
    }

    const handleVerifyOtp = async (otp: string) => {
        try {
            const completeSignUp = await signUp?.attemptEmailAddressVerification({
                code: otp,
            })

            if (completeSignUp?.status === 'complete') {
                if (setActive !== undefined) {
                    await setActive({ session: completeSignUp.createdSessionId })
                    captureEvent('signup_verified_otp', { method: 'email' })

                    window.location.reload()
                }
            }
        } catch (error) {
            toast({
                variant: 'destructive',
                title: 'Failed to verify email',
                description: formatClerkError(error),
            })

            captureException(formatClerkError(error))
        }
    }

    const handleGoogleSignup = async () => {
        try {
            setIsLoading(true)

            await signUp?.authenticateWithRedirect({
                strategy: 'oauth_google',
                redirectUrl: '/sso-callback',
                redirectUrlComplete: '/dashboard',
            })
        } catch (error) {
            toast({
                variant: 'destructive',
                title: 'Failed to create an account',
                description: formatClerkError(error),
            })

            captureException(formatClerkError(error))
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Form {...form}>
                <form className="space-y-5" onSubmit={form.handleSubmit(onFormSubmit)}>
                    <Button
                        type="button"
                        disabled={isVerifyOtp}
                        variant="outline"
                        size="lg"
                        className="flex items-center gap-3 w-full"
                        onClick={handleGoogleSignup}
                    >
                        <img src={GoogleLogoImg} alt="google logo" className="w-6 h-6" />
                        <Typography variant="subtitle" className="!text-sm font-semibold text-gray-600">
                            Signup with Google
                        </Typography>
                    </Button>

                    <div className="flex flex-col sm:flex-row gap-4">
                        <FormField
                            control={form.control}
                            name="firstName"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>First name</FormLabel>
                                    <FormControl>
                                        <Input disabled={isVerifyOtp} placeholder="Jack" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="lastName"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>Last name</FormLabel>
                                    <FormControl>
                                        <Input disabled={isVerifyOtp} placeholder="Billy" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>

                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Email</FormLabel>
                                <FormControl>
                                    <Input disabled={isVerifyOtp} placeholder="your@email.com" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Password</FormLabel>
                                <FormControl>
                                    <Input disabled={isVerifyOtp} placeholder="Password..." type="password" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    {isVerifyOtp && (
                        <FormField
                            control={form.control}
                            name="otp"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>One-Time Password</FormLabel>
                                    <FormControl>
                                        <PatternFormat
                                            valueIsNumericString
                                            format="### ###"
                                            value={field.value}
                                            customInput={Input}
                                            onValueChange={(value) => field.onChange(value.value)}
                                        />
                                    </FormControl>
                                    <FormDescription>Please enter the one-time password sent to your email.</FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    )}

                    <Button isLoading={isLoading} className="space-x-2 w-full" type="submit" size="lg">
                        <ArrowLongRightIcon className="w-3 h-3 text-white" />
                        <span className="text-base">Get Started</span>
                    </Button>

                    <Separator />

                    <div className="text-center">
                        <Link to="/login" className="text-primary text-sm font-semibold">
                            You already have an account? Login
                        </Link>
                    </div>
                </form>
            </Form>
        </>
    )
}

import { useMemo } from 'react'
import { useQueries, useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { transactionModel, useTransactionMerchants, useTransactions, useTransactionsCount } from '@/features'
import {
    captureEvent,
    getTransactionMerchants,
    getTransactions,
    getTransactionsCount,
    type Transaction,
    type TypedResponse,
} from '@/shared'

export const useTransaction = () => {
    const dispatch = useDispatch()
    const transactionsData = useTransactions()
    const countData = useTransactionsCount()
    const merchantsData = useTransactionMerchants()

    const data = useQueries([
        {
            queryKey: ['transactions'],
            queryFn: getTransactions,
            onSuccess: (resp: TypedResponse<Transaction[]>) => {
                dispatch(transactionModel.actions.setTransactions(resp.data || []))
                captureEvent('transactions_data_fetch', { count: resp.data?.length })
            },
        },
        {
            queryKey: ['transactions-count'],
            queryFn: getTransactionsCount,
            onSuccess: (resp: TypedResponse<number>) => {
                dispatch(transactionModel.actions.setCount(resp.data || 0))
                captureEvent('transactions_count_fetch', { count: resp.data })
            },
        },
    ])

    const { isLoading: isQueryLoading } = useQuery({
        queryKey: ['transaction-merchants'],
        queryFn: getTransactionMerchants,
        staleTime: 1000 * 60 * 60, // 1 hour
        cacheTime: 1000 * 60 * 60, // 1 hour
        onSuccess: (resp: TypedResponse<Transaction[]>) => {
            dispatch(transactionModel.actions.setMerchants(resp.data || []))
            captureEvent('transaction_merchants_fetch', { count: resp.data?.length })
        },
    })

    const transactions = useMemo(() => transactionsData, [transactionsData, countData])
    const count = useMemo(() => countData, [transactionsData, countData])
    const merchants = useMemo(() => merchantsData, [merchantsData])

    return {
        transactions,
        count,
        merchants,
        isLoading: data.some((query) => query.isLoading) || isQueryLoading,
    }
}

import { type ReactNode } from 'react'

import { cn } from '@/shared'

interface Props {
    children: ReactNode
    className?: string
}

export const Page = ({ children, className }: Props) => <div className={cn(className)}>{children}</div>

import { useState } from 'react'

import { Button, type TransactionType } from '@/shared'

import { TransactionSheet } from './transactions-sheet'

export const TransactionsHeaderManipulate = () => {
    const [activeSheet, setActiveSheet] = useState<TransactionType | null>(null)

    const handleActiveSheet = (type: TransactionType) => {
        setActiveSheet(type)
    }

    return (
        <>
            <div className="flex items-center space-x-3">
                <Button variant="outline" onClick={() => handleActiveSheet('income')}>
                    Add revenue
                </Button>
                <Button onClick={() => handleActiveSheet('expense')}>Add expense</Button>
            </div>

            <TransactionSheet type={activeSheet!} open={!!activeSheet} onClose={() => setActiveSheet(null)} />
        </>
    )
}

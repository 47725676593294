import pluralize from 'pluralize'

import { Typography } from '@/shared'

interface Props {
    count: number
}

export const TransactionsHeader = ({ count }: Props) => {
    return (
        <div className="space-y-1">
            <Typography variant="h3">Transactions</Typography>

            <Typography variant="subtitle">
                {count ? `${count} new ${pluralize('transaction', count)} in this year` : 'No transactions in this year'}
            </Typography>
        </div>
    )
}

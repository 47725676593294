import { type ReactNode } from 'react'
import { QueryClientProvider } from 'react-query'

import { useQueryContext } from '@/app/context'

export const QueryProvider = ({ children }: { children: ReactNode }) => {
    const { queryClient } = useQueryContext()

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

import { zodResolver } from '@hookform/resolvers/zod'
import { captureException } from '@sentry/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { z } from 'zod'

import {
    TransactionFormAmount,
    TransactionFormBillDate,
    TransactionFormCategory,
    TransactionFormCurrency,
    TransactionFormMerchant,
    TransactionFormName,
    TransactionFormNotes,
    TransactionFormRecurring,
    TransactionFormTax,
    transactionModel,
    useUserData,
} from '@/features'
import {
    Button,
    captureEvent,
    createTransaction,
    Form,
    Separator,
    type Transaction,
    type TransactionType,
    updateTransaction,
    useToast,
} from '@/shared'

interface Props {
    transaction?: Transaction
    type: TransactionType
    onClose: (open?: boolean) => void
}

const formSchema = z.object(
    {
        name: z
            .string({
                required_error: 'Name is required',
                invalid_type_error: 'Name must be a string',
            })
            .min(1, { message: 'Name is too short' })
            .max(50, { message: 'Name should have maximum 50 characters' }),

        amount: z
            .number({
                required_error: 'Amount is required',
                invalid_type_error: 'Amount must be a number',
            })
            .min(0, { message: 'Amount must be greater than 0' })
            .max(1000000, { message: 'Amount must be less than 1000000' }),

        currency: z
            .string({
                required_error: 'Currency is required',
                invalid_type_error: 'Currency must be a string',
            })
            .min(3, { message: 'Currency is too short' })
            .max(3, { message: 'Currency should have maximum 3 characters' }),

        tax: z
            .number({ invalid_type_error: 'Tax must be a number' })
            .min(0, { message: 'Tax must be greater than 0' })
            .max(100, { message: 'Tax must be less than 100' })
            .nonnegative({ message: 'Tax must be non-negative' })
            .optional()
            .or(z.literal(0)),

        category: z
            .string({ invalid_type_error: 'Category must be a string' })
            .max(50, { message: 'Category should have maximum 50 characters' })
            .or(z.literal('other'))
            .optional(),

        billDate: z.date({
            required_error: 'Due date is required',
            invalid_type_error: 'Invalid date format',
        }),

        recurring: z
            .enum(['month', 'year', 'disabled'], { message: 'Recurring must be either month, year or disabled' })
            .or(z.literal('disabled'))
            .optional(),

        merchant: z.string({ invalid_type_error: 'Merchant must be a string' }).optional(),

        notes: z
            .string({ invalid_type_error: 'Notes must be a string' })
            .max(255, { message: 'Notes should have maximum 255 characters' })
            .optional(),

        type: z.enum(['income', 'expense'], { message: 'Type must be either income or expense' }),
    },
    {
        required_error: 'Transaction data is required',
        invalid_type_error: 'Transaction data must be an object',
    },
)

export type TransactionFormValues = z.infer<typeof formSchema>

export const TransactionForm = ({ transaction, type, onClose }: Props) => {
    const { toast } = useToast()
    const dispatch = useDispatch()
    const user = useUserData()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const form = useForm<TransactionFormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            type,
            name: transaction?.name || '',
            amount: transaction?.amount || 0,
            tax: transaction?.tax || 0,
            category: transaction?.category || 'other',
            merchant: transaction?.merchant?._id || '',
            notes: transaction?.notes || '',
            currency: transaction?.currency || user?.business?.currency || 'USD',
            billDate: dayjs(transaction?.billDate || new Date()).toDate(),
            recurring: transaction?.recurring || 'disabled',
        },
    })

    const onFormSubmit = async (data: TransactionFormValues) => {
        try {
            setIsLoading(true)

            const resp = transaction?._id
                ? await updateTransaction({ ...data, _id: transaction._id } as Transaction)
                : await createTransaction(data as Transaction)

            if ('error' in resp) throw new Error(resp.error)

            if (transaction?._id) {
                dispatch(transactionModel.actions.updateTransaction(resp.data!))
            } else {
                dispatch(transactionModel.actions.addTransaction(resp.data!))
            }

            captureEvent('transaction_form_submit', { type, transactionId: resp.data!._id })

            onClose()
        } catch (error) {
            console.error(error)
            const err = error as Error

            toast({
                variant: 'destructive',
                title: `Error in ${type} transaction`,
                description: err.message || 'An error occurred while making request',
            })

            captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Form {...form}>
            <form className="space-y-5 pb-6" onSubmit={form.handleSubmit(onFormSubmit)}>
                <TransactionFormName control={form.control} />

                <div className="flex space-x-3">
                    <TransactionFormAmount control={form.control} />
                    <TransactionFormTax control={form.control} />
                    <TransactionFormCurrency control={form.control} />
                </div>

                <TransactionFormCategory control={form.control} />

                <div className="flex space-x-3">
                    <TransactionFormBillDate control={form.control} />
                    <TransactionFormRecurring control={form.control} />
                </div>

                <TransactionFormMerchant control={form.control} setValue={form.setValue} />

                <Separator />

                <TransactionFormNotes control={form.control} />

                <Button isLoading={isLoading} className="w-full">
                    {transaction ? 'Update' : 'Add'} {type}
                </Button>
            </form>
        </Form>
    )
}

import { DocumentArrowDownIcon, DocumentArrowUpIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import { createElement } from 'react'
import { CSVLink } from 'react-csv'

type supportedActions = 'export' | 'import'

interface Props {
    actions: supportedActions[]
    data?: string[][]
    onCsvImport?: (data: string) => void
    onCsvExport?: () => void
    fileName?: string
}

export const CsvManipulate = ({ actions, data = [], fileName }: Props) => {
    const icon = {
        export: DocumentArrowDownIcon,
        import: DocumentArrowUpIcon,
    }

    return (
        <div className="flex items-center space-x-3">
            {actions.map((action) => (
                <CSVLink
                    key={action}
                    filename={fileName ?? 'insomnee-export.csv'}
                    className="font-medium text-sm text-default-500 cursor-pointer hover:opacity-80 flex gap-1 items-center"
                    data={data}
                >
                    {createElement(icon[action], { className: 'w-4 h-4' })}

                    <span>{_.upperFirst(action) + ` CSV`}</span>
                </CSVLink>
            ))}
        </div>
    )
}

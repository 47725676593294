import { AuthenticateWithRedirectCallback } from '@clerk/clerk-react'
import { useEffect } from 'react'

import { LoaderScreen } from '@/features'
import { captureEvent, Page } from '@/shared'

export const SSOCallback = () => {
    useEffect(() => {
        captureEvent('sso_callback')
    }, [])

    return (
        <Page>
            <AuthenticateWithRedirectCallback
                signInUrl="/login"
                signUpUrl="/signup"
                signInForceRedirectUrl="/dashboard"
                signUpFallbackRedirectUrl="/dashboard"
                signInFallbackRedirectUrl="/dashboard"
            />

            <LoaderScreen />
        </Page>
    )
}

import dayjs from 'dayjs'
import { Outlet } from 'react-router-dom'

import { LoaderScreen, Sidebar } from '@/features'
import { TrialEndPage } from '@/pages'
import { useUser } from '@/shared'
import { Feedback, TrialBanner } from '@/widgets'

export const AppLayout = () => {
    const { isLoading, user, isError } = useUser()

    const isUserBillingExpired = dayjs().subtract(1, 'day').isAfter(dayjs(user?.billing?.nextBillingAt))

    if (isLoading) {
        return <LoaderScreen />
    }

    if (isUserBillingExpired || isError) {
        return <TrialEndPage />
    }

    return (
        <main id="app-layout" className="min-h-screen h-full w-full md:bg-slate-50 pt-12 md:pb-12">
            <div className="container">
                <Sidebar />

                <div id="outlet" className="md:ml-20 space-y-6">
                    <TrialBanner />
                    <div className="md:min-h-[600px] bg-white py-8 md:rounded-xl md:shadow-md md:container">
                        <Outlet />

                        <Feedback />
                    </div>
                </div>
            </div>
        </main>
    )
}

import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import { type RootState } from '@/app/middleware/reducers'
import { type Transaction, type TransactionMerchant } from '@/shared'

interface State {
    count: number
    transactions: Transaction[]
    merchants: TransactionMerchant[]
}

const initialState: State = {
    count: 0,
    transactions: [],
    merchants: [],
}

export const transactionModel = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        setTransactions(state, action: PayloadAction<Transaction[]>) {
            state.transactions = action.payload
        },
        setCount(state, action: PayloadAction<number>) {
            state.count = action.payload
        },
        addTransaction(state, action: PayloadAction<Transaction>) {
            state.transactions = [action.payload, ...state.transactions]
            state.count++
        },
        updateTransaction(state, action: PayloadAction<Transaction>) {
            state.transactions = state.transactions.map((t) => (t._id === action.payload._id ? action.payload : t))
        },
        deleteTransactionById(state, action: PayloadAction<string>) {
            state.transactions = state.transactions.filter((t) => t._id !== action.payload)
            state.count--
        },
        setMerchants(state, action: PayloadAction<TransactionMerchant[]>) {
            state.merchants = action.payload
        },
        addMerchant(state, action: PayloadAction<TransactionMerchant>) {
            state.merchants = [action.payload, ...state.merchants]
        },
        deleteMerchantById(state, action: PayloadAction<string>) {
            state.merchants = state.merchants.filter((m) => m._id !== action.payload)
        },
        updateMerchant(state, action: PayloadAction<TransactionMerchant>) {
            state.merchants = state.merchants.map((m) => (m._id === action.payload._id ? action.payload : m))
        },
    },
})

export const useTransactions = () => useSelector((state: RootState) => state.transaction.transactions)
export const useTransactionsCount = () => useSelector((state: RootState) => state.transaction.count)
export const useTransactionMerchants = () => useSelector((state: RootState) => state.transaction.merchants)

export const transactionReducer = transactionModel.reducer

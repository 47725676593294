import { ClerkProvider } from '@clerk/clerk-react'
import { type ReactNode } from 'react'

interface Props {
    children: ReactNode
}

export const AuthProvider = ({ children }: Props) => {
    const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY

    if (!PUBLISHABLE_KEY) {
        throw new Error('Missing Clerk Publishable Key')
    }

    return <ClerkProvider publishableKey={PUBLISHABLE_KEY}>{children}</ClerkProvider>
}

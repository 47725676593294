import { type Control } from 'react-hook-form'

import { FormControl, FormField, FormItem, FormLabel, FormMessage, Textarea } from '@/shared'

import { type InvoiceFormValues } from './invoice-form'

interface Props {
    control: Control<InvoiceFormValues>
}

export const InvoiceFormNotes = ({ control }: Props) => {
    return (
        <FormField
            control={control}
            name="notes"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>Notes</FormLabel>
                    <FormControl>
                        <Textarea {...field} className="resize-none" placeholder="Add notes here..." rows={7} />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    )
}

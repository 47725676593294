import { useAuth } from '@clerk/clerk-react'
import { captureException } from '@sentry/react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { userModel } from '@/features'
import { getMe } from '@/shared'

export const useUser = () => {
    const dispatch = useDispatch()
    const { isSignedIn, isLoaded } = useAuth()

    const handleGetUser = async () => {
        try {
            const resp = await getMe()
            if ('error' in resp) throw new Error(resp.error)

            return resp.data
        } catch (error) {
            console.error(error)
            captureException(error)

            throw error
        }
    }

    const { data, isLoading, isError } = useQuery({
        queryKey: 'me',
        queryFn: handleGetUser,
        refetchOnWindowFocus: true,
        enabled: isSignedIn && isLoaded,
        onSuccess: (data) => data && dispatch(userModel.actions.setUser(data)),
    })

    return {
        user: data,
        isLoading,
        isError,
    }
}

import { Dialog, DialogContent, type Invoice, InvoiceEmailTemplate, ScrollArea } from '@/shared'

interface Props {
    open: boolean
    invoice: Invoice
    onClose: (open: boolean) => void
}

export const InvoiceRecurringDialog = ({ open, onClose, invoice }: Props) => {
    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[475px] bg-slate-100 my-auto mx-auto">
                <ScrollArea className="max-h-[80vh]">
                    <InvoiceEmailTemplate invoice={invoice} />
                </ScrollArea>
            </DialogContent>
        </Dialog>
    )
}

import { CalendarIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'
import { type Control } from 'react-hook-form'

import {
    Button,
    Calendar,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/shared'

import { type TransactionFormValues } from './transaction-form'

interface Props {
    control: Control<TransactionFormValues>
}

export const TransactionFormBillDate = ({ control }: Props) => {
    return (
        <FormField
            control={control}
            name="billDate"
            render={({ field }) => (
                <FormItem className="flex flex-col space-y-3 w-full">
                    <FormLabel>Bill date</FormLabel>
                    <Popover>
                        <PopoverTrigger asChild>
                            <FormControl>
                                <Button variant="outline" className="h-10 hover:bg-white">
                                    {field.value ? <span>{dayjs(field.value).format('MMM DD, YYYY')}</span> : <span>Pick a date</span>}
                                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                            </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                                mode="single"
                                selected={field.value}
                                disabled={(date) => dayjs(date).isBefore(dayjs('1900-01-01'))}
                                onSelect={field.onChange}
                            />
                        </PopoverContent>
                    </Popover>
                    <FormMessage />
                </FormItem>
            )}
        />
    )
}

import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

import { useUserData } from '@/features/user'
import { cn, Typography } from '@/shared'

export const TrialBanner = () => {
    const navigate = useNavigate()
    const user = useUserData()

    const expirationInDays = dayjs(user?.billing?.trialEndsAt).diff(dayjs(), 'days') + 1
    const isTrial = user?.billing?.plan === 'TRIAL'

    const getBannerGradient = () => {
        // Banner gradient based on the expiration days left
        if (expirationInDays < 2) {
            return 'from-red-700 to-red-900'
        }

        if (expirationInDays < 5) {
            return 'from-red-500 to-orange-500'
        }

        return 'from-sky-800 to-blue-900'
    }

    if (!isTrial) {
        return null
    }

    return (
        <div
            className={cn(
                'flex justify-center items-center h-14 bg-gradient-to-r rounded-xl shadow-md cursor-pointer hover:opacity-95 mt-6 md:mt-0',
                getBannerGradient(),
            )}
            onClick={() => navigate('/settings/account')}
        >
            <Typography variant="p" className="text-white font-normal">
                Your Trial plan will expire in <span className="font-semibold">{expirationInDays}</span> days
            </Typography>
        </div>
    )
}

import { type Control } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

import { FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@/shared'

import { type TransactionFormValues } from './transaction-form'

interface Props {
    control: Control<TransactionFormValues>
}

export const TransactionFormAmount = ({ control }: Props) => {
    return (
        <FormField
            control={control}
            name="amount"
            render={({ field }) => (
                <FormItem className="w-full">
                    <FormLabel className="required">Amount</FormLabel>
                    <FormControl>
                        <NumericFormat
                            fixedDecimalScale
                            allowNegative={false}
                            defaultValue="0.00"
                            thousandSeparator=","
                            customInput={Input}
                            decimalScale={2}
                            value={field.value}
                            onValueChange={(values) => {
                                const { floatValue } = values
                                field.onChange(floatValue)
                            }}
                        />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    )
}

import dayjs from 'dayjs'

import { captureEvent, Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/shared'

interface Props {
    year: string
    setYear: (year: string) => void
}

export const DashboardYearSelect = ({ year, setYear }: Props) => {
    const selectYears = Array.from({ length: 4 }, (_, i) => dayjs().year() - i)

    const handleYearChange = (year: number) => {
        setYear(String(year))
        captureEvent('dashboard_year_select', { year })
    }

    return (
        <Select value={year} onValueChange={setYear}>
            <SelectTrigger className="w-24 h-8 text-xs md:text-sm">
                <SelectValue placeholder="Choose year" />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {selectYears.map((selectYear) => (
                        <SelectItem
                            key={selectYear}
                            className="text-xs md:text-sm"
                            value={String(selectYear)}
                            onClick={() => handleYearChange(selectYear)}
                        >
                            {selectYear}
                        </SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>
    )
}

import { useUser } from '@clerk/clerk-react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Avatar } from '@nextui-org/react'
import { captureException } from '@sentry/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { z } from 'zod'

import { userModel, useUserData } from '@/features/user'
import {
    Button,
    captureEvent,
    ColorPicker,
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    Input,
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
    Separator,
    updateUserBusiness,
    useCurrency,
    useToast,
} from '@/shared'

export const SettingsBusinessForm = () => {
    const dispatch = useDispatch()
    const { toast } = useToast()
    const { currency } = useCurrency()
    const { user: clerkUser } = useUser()
    const user = useUserData()

    const [isLoading, setIsLoading] = useState(false)

    const formSchema = z.object({
        name: z
            .string({
                required_error: 'Name is required',
                invalid_type_error: 'Name must be a string',
            })
            .min(1, { message: 'Name must be at least 1 character long' })
            .max(50, { message: 'Name must be at most 50 characters long' })
            .optional()
            .or(z.literal('')),

        website: z
            .string({
                required_error: 'Website is required',
                invalid_type_error: 'Website must be a string',
            })
            .max(100, { message: 'Website must be at most 100 characters long' })
            .optional()
            .or(z.literal('')),

        address: z
            .string({
                required_error: 'Address is required',
                invalid_type_error: 'Address must be a string',
            })
            .min(1, { message: 'Address must be at least 1 character long' })
            .max(100, { message: 'Address must be at most 100 characters long' })
            .optional()
            .or(z.literal('')),

        phoneNumber: z
            .string({
                required_error: 'Phone number is required',
                invalid_type_error: 'Phone number must be a string',
            })
            .min(1, { message: 'Phone number must be at least 1 character long' })
            .max(20, { message: 'Phone number must be at most 20 characters long' })
            .optional()
            .or(z.literal('')),

        numberId: z
            .string({
                required_error: 'Number ID is required',
                invalid_type_error: 'Number ID must be a string',
            })
            .min(1, { message: 'Number ID must be at least 1 character long' })
            .max(20, { message: 'Number ID must be at most 20 characters long' })
            .optional()
            .or(z.literal('')),

        brandColorHex: z
            .string({
                required_error: 'Brand color hex is required',
                invalid_type_error: 'Brand color hex must be a string',
            })
            .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, {
                message: 'Brand color hex must be a valid hex color',
            })
            .optional()
            .or(z.literal('')),

        currency: z
            .string({
                required_error: 'Currency is required',
                invalid_type_error: 'Currency must be a string',
            })
            .optional()
            .or(z.literal('USD')),

        image: z
            .string({
                invalid_type_error: 'Image must be a string',
            })
            .optional()
            .or(z.literal('')),
    })

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: user?.business?.name || '',
            website: user?.business?.website || '',
            address: user?.business?.address || '',
            phoneNumber: user?.business?.phoneNumber || '',
            numberId: user?.business?.numberId || '',
            brandColorHex: user?.business?.brandColorHex || '#1D6EFF',
            currency: user?.business?.currency || 'USD',
            image: user?.business?.image || '',
        },
    })

    const handleOpenSelectAvatar = () => {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = '.png, .jpg, .jpeg'
        input.click()

        input.onchange = (e) => {
            const file = (e.target as HTMLInputElement).files?.[0]

            if (file) {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                    form.setValue('image', reader.result as string)
                }
            }
        }
    }

    const handleFormSubmit = async (data: z.infer<typeof formSchema>) => {
        try {
            setIsLoading(true)

            const resp = await updateUserBusiness(data)
            if ('error' in resp) throw new Error(resp.error)

            dispatch(userModel.actions.updateUser(resp.data!))

            toast({
                title: 'Business details updated',
                description: 'Your business details have been successfully updated',
            })

            captureEvent('settings_business_updated')
        } catch (error) {
            const err = error as Error

            toast({
                variant: 'destructive',
                title: 'Failed to update business details',
                description: err.message || 'An error occurred',
            })

            captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Form {...form}>
            <form className="space-y-5" onSubmit={form.handleSubmit(handleFormSubmit)}>
                <div className="flex flex-col md:flex-row gap-6">
                    <FormField
                        control={form.control}
                        name="name"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Business legal name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Lucid LTD" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="website"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Website URL</FormLabel>
                                <FormControl>
                                    <Input placeholder="www.example.com" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <FormField
                    control={form.control}
                    name="address"
                    render={({ field }) => (
                        <FormItem className="w-full">
                            <FormLabel>Address</FormLabel>
                            <FormControl>
                                <Input placeholder="5 Washington Square S, New York, NY 10012, USA" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div className="flex flex-col md:flex-row gap-6">
                    <FormField
                        control={form.control}
                        name="phoneNumber"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Phone number</FormLabel>
                                <FormControl>
                                    <Input placeholder="(704) 333-9876" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="numberId"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Business number</FormLabel>
                                <FormControl>
                                    <Input placeholder="LC4134DE" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <FormField
                    control={form.control}
                    name="currency"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Currency</FormLabel>
                            <FormControl>
                                <Select defaultValue={field.value} onValueChange={field.onChange}>
                                    <SelectTrigger>
                                        <SelectValue placeholder={field.value} />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {currency?.data?.map((currency, index) => (
                                                <SelectItem key={index} value={currency.name}>
                                                    {currency.symbol} {currency.name}
                                                </SelectItem>
                                            ))}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                        </FormItem>
                    )}
                />

                <Separator />

                <FormField
                    name="image"
                    render={({ field }) => (
                        <FormItem className="flex flex-col space-y-3 w-full">
                            <FormLabel>Business image</FormLabel>
                            <FormControl>
                                <div className="flex gap-3 items-center">
                                    <div>
                                        <Avatar
                                            showFallback
                                            isBordered
                                            size="lg"
                                            radius="sm"
                                            name={form.getValues('name') || undefined}
                                            src={field.value || user?.business?.image || clerkUser?.imageUrl || undefined}
                                            classNames={{
                                                base: 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white cursor-pointer',
                                            }}
                                            onClick={handleOpenSelectAvatar}
                                        />
                                    </div>

                                    <FormDescription>
                                        You can upload a JPG or PNG file. The size of the image must be between 512 x 512 and 2048 x 2048
                                        pixels.
                                    </FormDescription>
                                </div>
                            </FormControl>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="brandColorHex"
                    render={({ field }) => (
                        <FormItem className="flex flex-col space-y-3 w-full">
                            <FormLabel>Brand color</FormLabel>
                            <FormControl>
                                <ColorPicker background={field.value || '#1D6EFF'} setBackground={field.onChange} />
                            </FormControl>
                        </FormItem>
                    )}
                />

                <Button isLoading={isLoading} type="submit">
                    Save
                </Button>
            </form>
        </Form>
    )
}

import { useState } from 'react'
import { Helmet } from 'react-helmet'

import { CustomersHeader, SkeletonMask } from '@/entities'
import { CustomerSheet, CustomersTable, useCustomersCount, useCustomersData } from '@/features'
import { Button, captureEvent, Page, Separator, useCustomer } from '@/shared'

export const Customers = () => {
    const { isLoading } = useCustomer()
    const customers = useCustomersData()
    const count = useCustomersCount()

    const [openCreateSheet, setOpenCreateSheet] = useState(false)

    const handleAddCustomerBtnClick = () => {
        setOpenCreateSheet(true)

        captureEvent('customer_add')
    }

    return (
        <>
            <Helmet>
                <title>Customers - Insomnee</title>
            </Helmet>

            <Page className="space-y-5">
                <div className="flex justify-between">
                    {isLoading ? <SkeletonMask type="heading" /> : <CustomersHeader count={count} />}
                    {isLoading ? <SkeletonMask type="heading" /> : <Button onClick={handleAddCustomerBtnClick}>Add customer</Button>}
                </div>

                <Separator />

                {isLoading ? <SkeletonMask type="table-with-tabs" /> : <CustomersTable customers={customers} />}

                <CustomerSheet open={openCreateSheet} onClose={setOpenCreateSheet} />
            </Page>
        </>
    )
}

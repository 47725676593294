import { zodResolver } from '@hookform/resolvers/zod'
import { Avatar } from '@nextui-org/react'
import { captureException } from '@sentry/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { z } from 'zod'

import { customerModel } from '@/features'
import {
    addCustomer,
    Button,
    captureEvent,
    type Customer,
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    Input,
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
    Textarea,
    updateCustomer,
    useCurrency,
    useToast,
} from '@/shared'

interface Props {
    customer?: Customer
    onSubmit?: () => void
}

export const CustomerForm = ({ customer, onSubmit }: Props) => {
    const dispatch = useDispatch()
    const { currency } = useCurrency()
    const { toast } = useToast()

    const [imageBase64, setImageBase64] = useState<string | undefined>(customer?.avatar ?? undefined)
    const [isLoading, setIsLoading] = useState(false)

    const formSchema = z.object({
        name: z
            .string()
            .min(3, { message: 'Name must be at least 3 characters long' })
            .max(50, { message: 'Name must be at most 50 characters long' }),
        email: z.string().email({ message: 'Invalid email address' }),
        avatar: z.string().optional(),
        phoneNumber: z.string().optional(),
        country: z.string().optional(),
        city: z.string().optional(),
        address: z.string().optional(),
        businessName: z.string().optional(),
        vatId: z.string().optional(),
        currency: z.string().optional().or(z.literal('USD')),
        notes: z.string().max(250, { message: 'Notes must be at most 250 characters long' }).optional(),
    })

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: customer?.name ?? '',
            email: customer?.email ?? '',
            avatar: customer?.avatar ?? '',
            phoneNumber: customer?.phoneNumber ?? '',
            country: customer?.country ?? '',
            city: customer?.city ?? '',
            address: customer?.address ?? '',
            businessName: customer?.businessName ?? '',
            vatId: customer?.vatId ?? '',
            currency: customer?.currency ?? 'USD',
            notes: customer?.notes ?? '',
        },
    })

    const handleOpenSelectAvatar = () => {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = '.png, .jpg, .jpeg'
        input.click()

        input.onchange = (e) => {
            const file = (e.target as HTMLInputElement).files?.[0]

            if (file) {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                    setImageBase64(reader.result as string)
                }
            }
        }
    }

    const onFormSubmit = async (values: z.infer<typeof formSchema>) => {
        try {
            setIsLoading(true)

            const customerObj: Partial<Customer> = { ...values }
            if (imageBase64) {
                customerObj.avatar = imageBase64
            }

            if (customer) {
                customerObj._id = customer._id
            }

            const resp = customer?._id ? await updateCustomer(customer._id, customerObj) : await addCustomer(customerObj)
            if ('error' in resp) throw new Error(resp.error)

            if (resp.data) {
                form.reset()
                setImageBase64(undefined)
                dispatch(customerModel.actions[customer ? 'updateCustomer' : 'addCustomer'](resp.data))
                onSubmit && onSubmit()
            }

            captureEvent(`${customer ? 'update' : 'create'}_customer`, { customerId: resp.data?._id })
        } catch (error) {
            console.error(error)
            const err = error as Error

            toast({ variant: 'destructive', title: `Failed to ${customer?._id ? 'update' : 'create'} customer`, description: err.message })

            captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Form {...form}>
            <form className="space-y-5 pb-6" onSubmit={form.handleSubmit(onFormSubmit)}>
                <Avatar
                    showFallback
                    size="lg"
                    name={form.getValues('name') ?? '-'}
                    src={imageBase64}
                    classNames={{
                        base: 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white cursor-pointer',
                        icon: 'text-white/80 w-7',
                    }}
                    onClick={handleOpenSelectAvatar}
                />

                <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className="required">Contact name</FormLabel>
                            <FormControl>
                                <Input placeholder="Jacky Chan" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className="required">Email</FormLabel>
                            <FormControl>
                                <Input placeholder="customer@email.com" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div className="flex w-full flex-col md:flex-row gap-4">
                    <FormField
                        control={form.control}
                        name="phoneNumber"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Phone number</FormLabel>
                                <FormControl>
                                    <Input placeholder="(+123) 456 7890" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="businessName"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Company Name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Big Company LTD" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <div className="flex w-full flex-col md:flex-row gap-4">
                    <FormField
                        control={form.control}
                        name="country"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Country</FormLabel>
                                <FormControl>
                                    <Input placeholder="United States" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="city"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>City</FormLabel>
                                <FormControl>
                                    <Input placeholder="New York" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <FormField
                    control={form.control}
                    name="address"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Address</FormLabel>
                            <FormControl>
                                <Input placeholder="7023 Rockledge Ave. Jackson Heights, NY 11372" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="vatId"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Vat ID</FormLabel>
                            <FormControl>
                                <Input placeholder="V-123456789" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="currency"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className="required">Currency</FormLabel>
                            <FormControl>
                                <Select defaultValue={field.value} onValueChange={field.onChange}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Currency" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {currency?.data?.map((currency, index) => (
                                                <SelectItem key={index} value={currency.name}>
                                                    {currency.name}
                                                </SelectItem>
                                            ))}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="notes"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Notes</FormLabel>
                            <FormControl>
                                <Textarea placeholder="Notes" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <Button
                    data-umami-event={`${customer ? 'Edit' : 'Add'} customer`}
                    isLoading={isLoading}
                    type="button"
                    className="w-full"
                    size="lg"
                    onClick={form.handleSubmit(onFormSubmit)}
                >
                    {customer ? 'Edit' : 'Add'} customer
                </Button>
            </form>
        </Form>
    )
}

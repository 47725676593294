import { useSignIn } from '@clerk/clerk-react'
import { ArrowLongRightIcon } from '@heroicons/react/20/solid'
import { zodResolver } from '@hookform/resolvers/zod'
import { captureException } from '@sentry/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import GoogleLogoImg from '@/assets/images/google-logo.svg'
import {
    Button,
    captureEvent,
    Form,
    formatClerkError,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    Input,
    Separator,
    Typography,
    useToast,
} from '@/shared'

export const AuthLoginForm = () => {
    const navigate = useNavigate()
    const { signIn } = useSignIn()
    const { toast } = useToast()

    const [isLoading, setIsLoading] = useState(false)

    const formSchema = z.object({
        email: z.string().email({
            message: 'Invalid email address',
        }),
        password: z.string().min(8, {
            message: 'Password must be at least 8 characters long',
        }),
    })

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: { email: '', password: '' },
    })

    const onFormSubmit = async (values: z.infer<typeof formSchema>) => {
        const { email, password } = values

        try {
            setIsLoading(true)

            await signIn?.create({
                identifier: email,
                password,
                actionCompleteRedirectUrl: '/dashboard',
            })

            captureEvent('user_logged_in', { email })
            window.location.reload()
        } catch (error) {
            toast({
                variant: 'destructive',
                title: 'Failed to create an account',
                description: formatClerkError(error),
            })

            captureException(formatClerkError(error))
        } finally {
            setIsLoading(false)
        }
    }

    const handleGoogleLogin = async () => {
        try {
            setIsLoading(true)

            await signIn?.authenticateWithRedirect({
                strategy: 'oauth_google',
                redirectUrl: '/sso-callback',
                redirectUrlComplete: '/dashboard',
            })
        } catch (error) {
            toast({
                variant: 'destructive',
                title: 'Failed to login',
                description: formatClerkError(error),
            })

            captureException(formatClerkError(error))
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Form {...form}>
            <form className="space-y-5" onSubmit={form.handleSubmit(onFormSubmit)}>
                <Button
                    type="button"
                    disabled={isLoading}
                    variant="outline"
                    size="lg"
                    className="flex items-center gap-3 w-full"
                    onClick={handleGoogleLogin}
                >
                    <img src={GoogleLogoImg} alt="google logo" className="w-4 h-4" />
                    <Typography variant="subtitle" className="!text-sm font-semibold text-gray-600">
                        Login with Google
                    </Typography>
                </Button>

                <Separator />

                <FormField
                    disabled={isLoading}
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                                <Input placeholder="your@email.com" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    disabled={isLoading}
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                        <FormItem>
                            <div className="flex justify-between items-center">
                                <FormLabel>Password</FormLabel>
                                <FormLabel className="text-xs text-gray-400 cursor-pointer" onClick={() => navigate('/forgot-password')}>
                                    Forgot password?
                                </FormLabel>
                            </div>
                            <FormControl>
                                <Input placeholder="Password..." type="password" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <Button isLoading={isLoading} className="space-x-2 w-full" type="submit" size="lg">
                    <ArrowLongRightIcon className="w-3 h-3 text-white" />
                    <span className="text-base">Get Started</span>
                </Button>
            </form>

            <Separator />

            <div className="text-center">
                <Link to="/signup" className="text-primary text-sm font-semibold">
                    Don&apos;t have an account? Create a new one
                </Link>
            </div>
        </Form>
    )
}

import { useQuery } from 'react-query'

import { getCurrency } from '@/shared'

export const useCurrency = (currency: string = 'USD') => {
    const { data, isLoading } = useQuery({
        queryKey: 'currency',
        queryFn: getCurrency,
        cacheTime: 1000 * 60 * 60 * 12,
        staleTime: 1000 * 60 * 60 * 12,
    })

    const findCurrency = data?.data?.find((c) => c.name === currency)

    const getCurrencySymbol = (currency: string) => {
        return data?.data?.find((c) => c.name === currency)?.symbol ?? '$'
    }

    return {
        currency: data,
        currencySymbol: findCurrency?.symbol ?? '$',
        isLoading,
        getCurrencySymbol,
    }
}

import { useAuth } from '@clerk/clerk-react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { createBrowserRouter, Navigate, RouterProvider as Provider } from 'react-router-dom'

import { LoaderScreen } from '@/features'
import {
    Customers,
    Dashboard,
    ForgotPasswordPage,
    InvoicePage,
    InvoicePortalPage,
    Invoices,
    LoginPage,
    SettingsPage,
    SignUpPage,
    SSOCallback,
    TransactionsPage,
} from '@/pages'
import { AppLayout, AuthLayout } from '@/widgets'

export const RouterProvider = () => {
    const { isSignedIn, isLoaded, getToken } = useAuth()
    const [isTokenSet, setIsTokenSet] = useState(false)

    useEffect(() => {
        if (isSignedIn && isLoaded) {
            getToken().then((token) => {
                axios.defaults.headers.common.Authorization = `Bearer ${token}`
                setIsTokenSet(true)
            })
        }
    }, [isSignedIn, isLoaded])

    const router = createBrowserRouter([
        { path: '/sso-callback', element: <SSOCallback /> },

        isSignedIn
            ? {
                  element: <AppLayout />,
                  children: [
                      { path: '/dashboard', element: <Dashboard /> },
                      { path: '/invoices', element: <Invoices /> },
                      { path: '/invoices/:id', element: <InvoicePage /> },
                      { path: '/invoice/:id', element: <InvoicePortalPage /> }, // invoice portal page
                      { path: '/customers', element: <Customers /> },
                      { path: '/settings/:options?', element: <SettingsPage /> },
                      { path: '/transactions', element: <TransactionsPage /> },
                      { path: '*', element: <Navigate to="/dashboard" /> },
                  ],
              }
            : {
                  element: <AuthLayout />,
                  children: [
                      { path: '/login', element: <LoginPage /> },
                      { path: '/signup', element: <SignUpPage /> },
                      { path: '/forgot-password', element: <ForgotPasswordPage /> },
                      { path: '*', element: <Navigate to="/login" /> },
                  ],
              },
    ])

    if (!isLoaded) {
        return <LoaderScreen />
    }

    if (isLoaded && isSignedIn && !isTokenSet) {
        return <LoaderScreen />
    }

    return <Provider router={router} />
}

import { captureException } from '@sentry/react'
import { useState } from 'react'

import { Button, deleteUser, Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, useToast } from '@/shared'

interface Props {
    isOpen: boolean
    onClose: (isOpen: boolean) => void
}

export const UserDeleteDialog = ({ isOpen, onClose }: Props) => {
    const { toast } = useToast()
    const [isLoading, setIsLoading] = useState(false)

    const handleDeleteAccount = async () => {
        try {
            setIsLoading(true)

            const resp = await deleteUser()
            if (resp.error) throw new Error(resp.error)

            if (resp.data) {
                window.location.reload()
            }
        } catch (error) {
            console.error(error)
            const err = error as Error

            toast({
                variant: 'destructive',
                title: 'Failed to delete account',
                description: err.message || 'An error occurred while trying to delete your account. Please try again later.',
            })

            captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader className="space-y-3">
                    <DialogTitle>Are you absolutely sure?</DialogTitle>
                    <DialogDescription>
                        This action cannot be undone. This will permanently delete your account and remove your data from our servers. Once
                        you delete your account,{' '}
                        <span className="font-semibold text-black">
                            your subscription will be canceled and you will lose access to your account
                        </span>
                        .
                    </DialogDescription>
                </DialogHeader>

                <Button isLoading={isLoading} variant="destructive" onClick={handleDeleteAccount}>
                    Delete my account and data
                </Button>
            </DialogContent>
        </Dialog>
    )
}

import { Helmet } from 'react-helmet'

import { AuthLogo } from '@/entities'
import { AuthLoginForm } from '@/features'
import { Page, Typography } from '@/shared'

export const LoginPage = () => {
    return (
        <>
            <Helmet>
                <title>Login - Insomnee</title>
            </Helmet>

            <Page className="flex flex-col space-y-6 max-w-md w-full mx-auto">
                <AuthLogo type="form" />
                <Typography variant="h3">Welcome Back 👋</Typography>
                <AuthLoginForm />
            </Page>
        </>
    )
}

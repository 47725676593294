import { useAuth } from '@clerk/clerk-react'

import LogoImg from '@/assets/images/logo-full.svg'
import { PricingCard } from '@/features'
import { Button, Typography, useStripe } from '@/shared'

export const TrialEndPage = () => {
    const { pricingArr } = useStripe()
    const { signOut } = useAuth()

    return (
        <div className="min-h-screen container py-12">
            <div className="flex flex-row-reverse">
                <Button variant="outline" onClick={() => signOut()}>
                    Logout
                </Button>
            </div>

            <div className="flex justify-center items-center flex-col gap-12">
                <img src={LogoImg} alt="logo" className="w-[140px]" />

                <div className="space-y-1 text-center">
                    <Typography variant="h1" className="font-medium">
                        Your trial has ended 😢
                    </Typography>
                    <Typography variant="h3" className="font-light">
                        We hope you enjoyed your trial. If you want to continue using Insomnee, please subscribe to a plan
                    </Typography>
                </div>

                <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6 w-full md:w-7/12">
                    {pricingArr?.map((pricing, index) => <PricingCard key={index} pricing={pricing} />)}
                </div>
            </div>
        </div>
    )
}

import { useEffect } from 'react'

import { captureEvent, type Customer, ScrollArea, Separator, Sheet, SheetContent, SheetHeader, Typography } from '@/shared'

import { CustomerForm } from './customer-form'

interface Props {
    customer?: Customer
    open: boolean
    onClose: (open: boolean) => void
}

export const CustomerSheet = ({ customer, open, onClose }: Props) => {
    useEffect(() => {
        captureEvent('customer_sheet_opened', { customer_id: customer?._id })
    }, [])

    return (
        <Sheet open={open} onOpenChange={onClose}>
            <SheetContent className="w-full sm:max-w-[450px] md:rounded-tl-xl md:top-3 md:bottom-3 h-fit">
                <ScrollArea className="h-screen pb-24 md:pb-12">
                    <div className="space-y-5">
                        <SheetHeader className="text-left">
                            <Typography variant="h3">{customer ? 'Edit customer' : 'Add customer'}</Typography>
                        </SheetHeader>

                        <Separator />

                        <CustomerForm customer={customer} onSubmit={() => onClose(false)} />
                    </div>
                </ScrollArea>
            </SheetContent>
        </Sheet>
    )
}

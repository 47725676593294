import { Typography } from '@/shared'

import { AuthLogo } from './auth-logo'

export const AuthHero = () => {
    return (
        <div className="w-full min-h-screen h-auto bg-gradient-to-r from-indigo-700 to-indigo-900 p-24 space-y-6 hidden xl:block">
            <AuthLogo type="hero" />

            <Typography variant="h2" className="text-white">
                Manage your freelance business with ease and efficiency with Insomnee. Get started today.
            </Typography>
        </div>
    )
}

import { useQuery } from 'react-query'

import { getTransactionCategories } from '@/shared'

export const useCategory = () => {
    const { data, isLoading } = useQuery({
        queryKey: ['transaction-categories'],
        queryFn: getTransactionCategories,
        staleTime: Infinity,
        cacheTime: Infinity,
        select: (data) => data.data,
    })

    return { categories: data || [], isLoading }
}

import { useUserData } from '@/features'
import { Card, CardContent, CardHeader, CardTitle } from '@/shared'

interface Props {
    title: string
    value: string
    description: string
}

export const DashboardCard = ({ title, value, description }: Props) => {
    const user = useUserData()

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: user?.business?.currency || 'USD',
    })

    return (
        <Card className="border-none shadow-none rounded-none">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1 md:pb-2">
                <CardTitle className="text-xs md:text-base font-light">{title}</CardTitle>
            </CardHeader>
            <CardContent className="space-y-2">
                <div className="text-lg md:text-2xl font-normal">{formatter.format(Number(value))}</div>
                <p className="text-xs text-muted-foreground py-3 md:py-0 hidden md:flex">{description}</p>
            </CardContent>
        </Card>
    )
}

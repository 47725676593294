import { ChevronUpIcon, PlusIcon, TrashIcon } from '@heroicons/react/20/solid'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@nextui-org/react'
import { useCallback, useEffect, useState } from 'react'
import {
    type Control,
    type FieldArrayWithId,
    type UseFieldArrayAppend,
    type UseFieldArrayRemove,
    type UseFormRegister,
    type UseFormSetValue,
    type UseFormWatch,
} from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

import {
    cn,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    Input,
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
    Separator,
    Typography,
    useCurrency,
} from '@/shared'

import { type InvoiceFormValues } from './invoice-form'

interface Props {
    control: Control<InvoiceFormValues>
    fields: Array<FieldArrayWithId<InvoiceFormValues, 'items'>>
    append: UseFieldArrayAppend<InvoiceFormValues, 'items'>
    remove: UseFieldArrayRemove
    register: UseFormRegister<InvoiceFormValues>
    watch: UseFormWatch<InvoiceFormValues>
    setValue: UseFormSetValue<InvoiceFormValues>
}

export const InvoiceFormItems = ({ control, fields, append, remove, register, watch, setValue }: Props) => {
    const { getCurrencySymbol } = useCurrency()

    const [isTaxOpen, setIsTaxOpen] = useState(false)
    const [isDiscountOpen, setIsDiscountOpen] = useState(false)

    const tax = watch('tax')
    const discount = watch('discount')
    const items = watch('items')

    useEffect(() => {
        if (tax?.percentage && tax.percentage > 0) {
            setIsTaxOpen(true)
        }

        if (discount?.percentage && discount.percentage > 0) {
            setIsDiscountOpen(true)
        }
    }, [tax?.percentage, discount?.percentage])

    const getRowTotal = useCallback(
        (index: number) => {
            const quantity = watch(`items.${index}.quantity`)
            const amount = watch(`items.${index}.amount`)
            return (quantity ?? 1) * (amount ?? 0)
        },
        [items],
    )

    const getRowsSubtotal = useCallback(() => {
        return items?.reduce((acc, item) => acc + (item.amount ?? 0) * (item.quantity ?? 0), 0) || 0
    }, [items])

    const handleToggleTax = () => {
        if (isTaxOpen) {
            setValue('tax', { name: 'Tax', percentage: 0, mode: 'EXCLUSIVE' })
            setIsTaxOpen(false)
        } else {
            setIsTaxOpen(true)
        }
    }

    const handleToggleDiscount = () => {
        if (isDiscountOpen) {
            setValue('discount', { name: 'Discount', percentage: 0 })
            setIsDiscountOpen(false)
        } else {
            setIsDiscountOpen(true)
        }
    }

    const getTaxTotal = useCallback(() => {
        const subtotal = getRowsSubtotal()

        const taxAmount = subtotal * ((tax?.percentage ?? 0) / 100) || 0
        return taxAmount
    }, [tax?.percentage, getRowsSubtotal()])

    const getDiscountTotal = useCallback(() => {
        const subtotal = getRowsSubtotal()

        const discountAmount = subtotal * ((watch('discount')?.percentage || 0) / 100) || 0
        return discountAmount
    }, [discount?.percentage, getRowsSubtotal()])

    const getTotal = useCallback(() => {
        let taxTotal = getTaxTotal()
        const discountTotal = getDiscountTotal()

        if (tax?.mode === 'INCLUSIVE') {
            taxTotal = 0
        }

        return getRowsSubtotal() - taxTotal - discountTotal
    }, [getRowsSubtotal(), getTaxTotal(), getDiscountTotal(), watch('tax.percentage'), watch('discount.percentage')])

    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: watch('currency') || 'USD' })

    return (
        <div className="space-y-3">
            {fields.map((field, index) => (
                <div key={field.id} className="flex space-x-3">
                    <FormField
                        name={`items.${index}.description`}
                        control={control}
                        render={() => (
                            <FormItem className="w-full">
                                {index === 0 && <FormLabel>Description</FormLabel>}
                                <FormControl>
                                    <Input {...register(`items.${index}.description`)} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        name={`items.${index}.quantity`}
                        control={control}
                        render={() => (
                            <FormItem className="w-30">
                                {index === 0 && <FormLabel>Quantity</FormLabel>}
                                <FormControl>
                                    <NumericFormat
                                        decimalScale={0}
                                        allowNegative={false}
                                        allowLeadingZeros={false}
                                        customInput={Input}
                                        value={watch(`items.${index}.quantity`)}
                                        onValueChange={(values) => {
                                            const { floatValue } = values
                                            setValue(`items.${index}.quantity`, floatValue || 0)
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        name={`items.${index}.amount`}
                        control={control}
                        render={() => (
                            <FormItem className="w-full">
                                {index === 0 && <FormLabel>Amount</FormLabel>}
                                <FormControl>
                                    <NumericFormat
                                        fixedDecimalScale
                                        allowNegative={false}
                                        defaultValue="0.00"
                                        thousandSeparator=","
                                        customInput={Input}
                                        decimalScale={2}
                                        value={watch(`items.${index}.amount`)}
                                        onValueChange={(values) => {
                                            const { floatValue } = values
                                            setValue(`items.${index}.amount`, floatValue || 0)
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <div className="flex gap-3">
                        <FormItem className="w-28 hidden md:block">
                            {index === 0 && <FormLabel>Total</FormLabel>}
                            <FormControl>
                                <NumericFormat
                                    disabled
                                    fixedDecimalScale
                                    value={getRowTotal(index)}
                                    prefix={getCurrencySymbol(watch('currency') || 'USD')}
                                    defaultValue="0.00"
                                    thousandSeparator=","
                                    customInput={Input}
                                    decimalScale={2}
                                    className="bg-slate-100"
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>

                        <FormItem
                            className={cn('w-fit h-fit', {
                                'mt-9': index === 0,
                                'mt-3': index > 0,
                            })}
                        >
                            <Tooltip isDisabled={fields.length === 1} content="Delete item">
                                <TrashIcon
                                    className={cn('w-4 h-4 text-default-400 cursor-pointer', {
                                        'cursor-default': fields.length === 1,
                                    })}
                                    onClick={() => fields.length > 1 && remove(index)}
                                />
                            </Tooltip>
                        </FormItem>
                    </div>
                </div>
            ))}

            <div className="flex items-center gap-3">
                <Typography
                    variant="subtitle"
                    className="flex items-center gap-1 cursor-pointer hover:underline w-fit"
                    onClick={() => append({ description: '', quantity: 1, amount: 0 })}
                >
                    <PlusIcon className="w-4 h-4" />
                    Add item
                </Typography>

                <Typography
                    variant="subtitle"
                    className="flex items-center gap-1 cursor-pointer hover:underline w-fit"
                    onClick={handleToggleTax}
                >
                    {isTaxOpen ? <ChevronUpIcon className="w-4 h-4" /> : <ChevronDownIcon className="w-4 h-4" />}
                    {isTaxOpen ? 'Remove taxes' : 'Add taxes'}
                </Typography>

                <Typography
                    variant="subtitle"
                    className="flex items-center gap-1 cursor-pointer hover:underline w-fit"
                    onClick={handleToggleDiscount}
                >
                    {isDiscountOpen ? <ChevronUpIcon className="w-4 h-4" /> : <ChevronDownIcon className="w-4 h-4" />}
                    {isDiscountOpen ? 'Remove discount' : 'Add discount'}
                </Typography>
            </div>

            {/* Subtotal */}
            <Typography variant="p" className="text-right !text-lg">
                Subtotal: {formatter.format(getRowsSubtotal())}
            </Typography>

            <Separator />

            {/* Taxes */}
            {isTaxOpen ? (
                <div className="space-y-3">
                    <div className="flex space-x-3">
                        <FormField
                            control={control}
                            name="tax.name"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>Tax name</FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder="Taxes" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={control}
                            name="tax.percentage"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>Percentage</FormLabel>
                                    <FormControl>
                                        <NumericFormat
                                            fixedDecimalScale
                                            allowNegative={false}
                                            defaultValue="0.00"
                                            thousandSeparator=","
                                            customInput={Input}
                                            decimalScale={2}
                                            suffix="%"
                                            value={field.value}
                                            onValueChange={(values) => {
                                                const { floatValue } = values
                                                setValue(`tax.percentage`, floatValue || 0)
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={control}
                            name="tax.mode"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>Mode</FormLabel>
                                    <FormControl>
                                        <Select value={field.value} onValueChange={field.onChange}>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Tax mode" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    <SelectItem value="EXCLUSIVE">Exclusive</SelectItem>
                                                    <SelectItem value="INCLUSIVE">Inclusive</SelectItem>
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormItem className="w-full hidden md:block">
                            <FormLabel>Tax total</FormLabel>
                            <FormControl>
                                <NumericFormat
                                    disabled
                                    fixedDecimalScale
                                    defaultValue="0.00"
                                    thousandSeparator=","
                                    customInput={Input}
                                    decimalScale={2}
                                    prefix={getCurrencySymbol(watch('currency') || 'USD')}
                                    value={getTaxTotal()}
                                    className="bg-slate-100"
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    </div>

                    <FormDescription>Inclusive taxes - you pay the tax. Exclusive taxes - your customer pays the tax.</FormDescription>
                </div>
            ) : (
                <Typography variant="subtitle" className="text-right">
                    No taxes applied
                </Typography>
            )}

            {/* Discount */}
            {isDiscountOpen ? (
                <div className="flex space-x-3">
                    <FormField
                        control={control}
                        name="discount.name"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Discount name</FormLabel>
                                <FormControl>
                                    <Input {...field} placeholder="Taxes" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={control}
                        name="discount.percentage"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Percentage</FormLabel>
                                <FormControl>
                                    <NumericFormat
                                        fixedDecimalScale
                                        allowNegative={false}
                                        defaultValue="0.00"
                                        thousandSeparator=","
                                        customInput={Input}
                                        decimalScale={2}
                                        suffix="%"
                                        value={field.value}
                                        onValueChange={(values) => {
                                            const { floatValue } = values
                                            setValue(`discount.percentage`, floatValue || 0)
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormItem className="w-full hidden md:block">
                        <FormLabel>Discount total</FormLabel>
                        <FormControl>
                            <NumericFormat
                                disabled
                                fixedDecimalScale
                                defaultValue="0.00"
                                thousandSeparator=","
                                customInput={Input}
                                decimalScale={2}
                                prefix={getCurrencySymbol(watch('currency') || 'USD')}
                                value={getDiscountTotal()}
                                className="bg-slate-100"
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                </div>
            ) : (
                <Typography variant="subtitle" className="text-right">
                    No discount applied
                </Typography>
            )}

            <Separator />

            {/* Total */}
            <Typography variant="h3" className="text-right">
                Total due {formatter.format(getTotal())}
            </Typography>
        </div>
    )
}

import { PaintBrushIcon } from '@heroicons/react/20/solid'

import { Button, cn, Input, Popover, PopoverContent, PopoverTrigger, ScrollArea } from '@/shared'

export function ColorPicker({
    background,
    setBackground,
    className,
}: {
    background: string
    setBackground: (background: string) => void
    className?: string
}) {
    const solids = [
        '#000000',
        '#333333',
        '#666666',
        '#999999',
        '#CCCCCC',
        '#333300',
        '#663300',
        '#993300',
        '#CC3300',
        '#330000',
        '#660000',
        '#990000',
        '#CC0000',
        '#330033',
        '#660033',
        '#990033',
        '#CC0033',
        '#330066',
        '#660066',
        '#990066',
        '#CC0066',
        '#330099',
        '#660099',
        '#990099',
        '#CC0099',
        '#3300CC',
        '#6600CC',
        '#9900CC',
        '#CC00CC',
        '#003333',
        '#336666',
        '#669999',
        '#99CCCC',
        '#CCFFFF',
        '#003300',
        '#336600',
        '#669900',
        '#99CC00',
        '#CCFF00',
        '#006633',
        '#339966',
        '#66CC99',
        '#99FFCC',
        '#003366',
        '#336699',
        '#6699CC',
        '#99CCFF',
        '#0066CC',
        '#3399FF',
        '#66CCFF',
        '#00FFFF',
        '#00CCFF',
        '#0099FF',
        '#0066FF',
        '#0033FF',
        '#3300FF',
        '#6600FF',
        '#9900FF',
        '#CC00FF',
        '#FF00FF',
        '#FF00CC',
        '#FF0099',
        '#FF0066',
        '#FF0033',
        '#FF3300',
        '#FF6600',
        '#FF9900',
        '#FFCC00',
        '#FFFF00',
        '#CCFF00',
        '#99FF00',
        '#66FF00',
        '#33FF00',
        '#00FF00',
        '#00FF33',
        '#00FF66',
        '#00FF99',
        '#00FFCC',
        '#00FFFF',
        '#33FFFF',
        '#66FFFF',
        '#99FFFF',
        '#CCFFFF',
        '#FFFFFF',
        '#FFCCFF',
        '#FF99FF',
        '#FF66FF',
        '#FF33FF',
        '#FF00FF',
        '#CC00CC',
        '#990099',
        '#660066',
        '#330033',
    ]

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={'outline'}
                    className={cn('justify-start text-left font-normal', !background && 'text-muted-foreground', className)}
                >
                    <div className="w-full flex items-center gap-2">
                        {background ? (
                            <div className="h-4 w-4 rounded !bg-center !bg-cover transition-all" style={{ background }}></div>
                        ) : (
                            <PaintBrushIcon className="h-4 w-4" />
                        )}
                        <div className="truncate flex-1">{background && 'Pick a color'}</div>
                    </div>
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-64">
                <ScrollArea className="h-52">
                    <div className="flex flex-wrap gap-1 mt-0 mr-1">
                        {solids.map((s) => (
                            <div
                                key={s}
                                style={{ background: s }}
                                className="rounded-md h-6 w-6 cursor-pointer active:scale-105"
                                onClick={() => setBackground(s)}
                            />
                        ))}
                    </div>
                </ScrollArea>

                <Input
                    id="custom"
                    value={background}
                    className="col-span-2 h-8 mt-4"
                    onChange={(e) => setBackground(e.currentTarget.value)}
                />
            </PopoverContent>
        </Popover>
    )
}
